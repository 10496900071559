import { DocumentsTableData } from 'components/DocumentsTable/types';
import { mapDocumentStatusToStatus } from 'components/DocumentViewer/AttachmentsTable/utils';
import {
  Booking,
  DocumentEdge,
  PaymentMethod,
  PurchaseOrder,
  Maybe,
  PurchaseOrderIntegrationType,
} from 'generated-types/graphql.types';
import { TFunction } from 'react-i18next';

type MappedBooking = {
  costCenter: string | undefined;
  costObject: string | undefined;
  extraCostInfo: string | undefined;
  artistSocialInsuranceCode: string | undefined;
  generalLedgerAccount: string | undefined;
  dueDate: Date | undefined;
  note: string | undefined;
  postingText: string | undefined;
  discountAmount: number | undefined;
  discountPercentage: number | undefined;
  discountPaymentDate: Date | undefined;
};

type AllBookingDetails = {
  costCenters: (string | undefined)[];
  costObjects: (string | undefined)[];
  extraCostInfos: (string | undefined)[];
  artistSocialInsuranceCodes: (string | undefined)[];
  generalLedgerAccounts: (string | undefined)[];
  dueDates: (Date | undefined)[];
  notes: (string | undefined)[];
  postingTexts: (string | undefined)[];
  discountAmounts: (number | undefined)[];
  discountPercentages: (number | undefined)[];
  discountPaymentDates: (Date | undefined)[];
};

const accumulatorDetails: AllBookingDetails = {
  costCenters: [],
  costObjects: [],
  extraCostInfos: [],
  artistSocialInsuranceCodes: [],
  discountAmounts: [],
  discountPaymentDates: [],
  discountPercentages: [],
  dueDates: [],
  generalLedgerAccounts: [],
  notes: [],
  postingTexts: [],
};

const mapToBookings =
  (t: TFunction) =>
  (booking: Booking): MappedBooking => {
    const artistSocialInsuranceCode = booking.artistSocialInsuranceCode
      ? // eslint-disable-next-line candis/no-template-strings-inside-translation
        t(
          `document.requestApproval.inputs.artistSocialInsuranceCode.codes.${booking.artistSocialInsuranceCode}`
        )
      : undefined;

    return {
      costCenter: booking.costCenter?.value.readableName,
      costObject: booking.costObject?.value.readableName,
      extraCostInfo: booking.extraCostInfo?.value.readableName,
      artistSocialInsuranceCode,
      generalLedgerAccount: booking.generalLedgerAccount?.value.readableName,
      dueDate: booking.dueDate?.value
        ? new Date(booking.dueDate?.value)
        : undefined,
      note: booking.note?.value,
      postingText: booking.postingText ?? undefined,
      discountAmount: booking.discountAmount?.value ?? undefined,
      discountPercentage: booking.discountPercentage?.value ?? undefined,
      discountPaymentDate: booking.discountPaymentDate?.value
        ? new Date(booking.discountPaymentDate?.value)
        : undefined,
    };
  };

const reduceAllDetails = (
  allBookingDetails: AllBookingDetails,
  booking: MappedBooking
): AllBookingDetails => {
  const {
    costCenters,
    costObjects,
    extraCostInfos,
    artistSocialInsuranceCodes,
    discountAmounts,
    discountPaymentDates,
    discountPercentages,
    dueDates,
    generalLedgerAccounts,
    notes,
    postingTexts,
  } = allBookingDetails;

  return {
    costCenters: [...costCenters, booking.costCenter],
    costObjects: [...costObjects, booking.costObject],
    extraCostInfos: [...extraCostInfos, booking.extraCostInfo],
    artistSocialInsuranceCodes: [
      ...artistSocialInsuranceCodes,
      booking.artistSocialInsuranceCode,
    ],
    discountAmounts: [...discountAmounts, booking.discountAmount],
    discountPaymentDates: [
      ...discountPaymentDates,
      booking.discountPaymentDate,
    ],
    discountPercentages: [...discountPercentages, booking.discountPercentage],
    dueDates: [...dueDates, booking.dueDate],
    generalLedgerAccounts: [
      ...generalLedgerAccounts,
      booking.generalLedgerAccount,
    ],
    notes: [...notes, booking.note],
    postingTexts: [...postingTexts, booking.postingText],
  };
};

const calculateGoodsReceiptsCount = (
  purchaseOrders: Maybe<PurchaseOrder>[] | null
): string | undefined => {
  if (!purchaseOrders) return undefined;
  const count = purchaseOrders.reduce((acc, po) => {
    return acc + (po?.goodsReceipts?.length ?? 0);
  }, 0);

  return count.toString();
};

export const mapToDocumentsTableData = (
  edges: DocumentEdge[],
  t: TFunction,
  previewDocumentId?: string | null
) => {
  const documentsTableData: DocumentsTableData[] = edges.map(edge => {
    const amount = edge.node.amount?.value;
    const currency = edge.node.currency?.value;
    const grossAmount = amount && currency ? { amount, currency } : undefined;

    const bookings: MappedBooking[] = (edge.node.bookings ?? []).map(
      mapToBookings(t)
    );

    const allBookingDetails = bookings.reduce(
      reduceAllDetails,
      accumulatorDetails
    );

    const isSapPurchaseOrder =
      edge.node.purchaseOrderData?.integrationType ===
      PurchaseOrderIntegrationType.Sap;

    return {
      id: edge.node.id,
      documentType: edge.node.category?.documentType?.value,
      status: {
        status: mapDocumentStatusToStatus(edge.node.status),
        isOverdue: !!edge.node.isOverdue,
        isDuplicate: !!edge.node.isDuplicate,
        isWaitingForClarification: !!edge.node.isWaitingForClarification,
        isEInvoice: !!edge.node.isEInvoice,
      },
      contact:
        edge.node.contact?.value.name.value ??
        edge.node.extractedContact?.name?.value,
      createdAt: new Date(edge.node.createdAt),
      requestedAt: edge.node.requestedAt?.value
        ? new Date(edge.node.requestedAt.value)
        : undefined,
      grossAmount,
      invoiceNumber: edge.node.invoiceNumber?.value,
      invoiceDate: edge.node.invoiceDate?.value
        ? new Date(edge.node.invoiceDate?.value)
        : undefined,
      requester: edge.node.requester,
      approvers: edge.node.approvers,
      iban: edge.node.iban?.value,
      paymentInfo: {
        bankAccountNumber: undefined,
        iban: edge.node.iban?.value,
        swiftCode: edge.node.swiftCode?.value ?? undefined,
      },
      dueDate: allBookingDetails['dueDates'],
      costCenter: allBookingDetails['costCenters'],
      costObject: allBookingDetails['costObjects'],
      extraCostInfo: allBookingDetails['extraCostInfos'],
      artistSocialInsuranceCode:
        allBookingDetails['artistSocialInsuranceCodes'],
      generalLedgerAccount: allBookingDetails['generalLedgerAccounts'],
      note: allBookingDetails['notes'],
      postingText: allBookingDetails['postingTexts'],
      isInvoiceCorrection: edge.node.amount?.value
        ? Boolean(edge.node.amount.value < 0)
        : false,
      isPayable: edge.node.isPayable ?? false,
      paymentStatus: edge.node.paymentState.__typename ?? 'UnpaidDocumentState',
      cursor: edge.cursor,
      deliveryDate: edge.node.invoiceDeliveryDate
        ? new Date(edge.node.invoiceDeliveryDate.value)
        : undefined,
      paidAt:
        edge.node.paymentState.__typename === 'PaidDocumentState'
          ? new Date(edge.node.paymentState.paidAt)
          : undefined,
      discountDateWPercentage: edge.node.bookings?.[0]
        ? {
            discountPaymentDate: bookings[0].discountPaymentDate,
            discountPercentage: bookings[0].discountPercentage,
          }
        : undefined,
      creditCardPayment:
        edge.node.paymentState.__typename === 'PaidDocumentState' &&
        edge.node.paymentState.method === PaymentMethod.CardTransaction
          ? true
          : undefined,
      purchaseOrderNumber: !isSapPurchaseOrder
        ? edge.node.purchaseOrderData?.orderNumber // talk with BE: Sap still populates purchaseOrderData?
        : undefined,
      tags: edge.node.tags,
      accountingArea: edge.node.accountingArea?.value.name,
      sapPurchaseOrderNumber: (edge.node.purchaseOrders ?? []).map(
        po => po?.orderNumber ?? ''
      ),
      goodsReceiptsCount: isSapPurchaseOrder
        ? calculateGoodsReceiptsCount(edge.node?.purchaseOrders ?? [])
        : undefined,
      selected: previewDocumentId === edge.node.id,
    };
  });

  return documentsTableData;
};
