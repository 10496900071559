import { ReimbursementDashboardLayout } from './components/ReimbursementDashboardLayout';
import { CompletedReimbursementListContainer } from './Lists/CompletedReImbursmentListContainer';
import { CreateReimbursementCardContainer } from './Lists/CreateReimbursementCardContainer';
import { OpenReimbursementListContainer } from './Lists/OpenReimbursementListContainer';

export const ReimbursementDashboard = () => {
  return (
    <ReimbursementDashboardLayout>
      <CreateReimbursementCardContainer />
      <OpenReimbursementListContainer />
      <CompletedReimbursementListContainer />
    </ReimbursementDashboardLayout>
  );
};
