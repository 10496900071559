import {
  Grid,
  Tooltip,
  defaultTheme,
  useTooltip,
} from '@candisio/design-system';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MotionNavigation,
  navigationTransition,
  navigationVariants,
} from './animations';
import { useIconOverflow } from './components/hooks/useIconOverflow';
import { useNavigationIcons } from './components/hooks/useNavigationIcons';
import { MenuIntercom } from './components/MenuItems/MenuIntercom/MenuIntercom';
import { MenuNotifications } from './components/MenuItems/MenuNotifications/MenuNotifications';
import { MenuSettings } from './components/MenuItems/MenuSettings/MenuSettings';
import { UserProfile } from './components/MenuItems/UserProfile/UserProfile';
import { NavigationIcons } from './components/NavigationIcons';
import { StyledButton } from './NavigationSidebar.styled';
import { OrganizationSwitcher } from './OrganizationSwitcher/OrganizationSwitcher';

const SMALL_SCREEN_WIDTH = 1280;
export const MAIN_NAVIGATION_OPEN_ONLOAD = 'main-navigation-open-onload';

export const NavigationSidebar = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);

  // TODO: define windowWidth or windowHeight as default size
  const windowWidth = useRef(window.innerWidth);
  const isLargeDesktop = windowWidth.current > SMALL_SCREEN_WIDTH;

  const [isOpen, setIsOpen] = useLocalStorage(
    MAIN_NAVIGATION_OPEN_ONLOAD,
    isLargeDesktop
  );

  const toggleIsOpen = useCallback(() => {
    setIsOpen(prev => !prev);
  }, [setIsOpen]);

  const [isNavigationOpen, setIsNavigationOpen] = useState(isOpen);
  const [isSwitcherHovered, setIsSwitcherHovered] = useState(false);

  const toggleSidebar = () => {
    setIsNavigationOpen(!isNavigationOpen);
    toggleIsOpen();
  };

  const toggleSwitcher = () => {
    setIsSwitcherHovered(!isSwitcherHovered);
  };

  const {
    isOpen: isTooltipOpen,
    tooltipProps,
    tooltipRef,
    triggerProps,
    triggerRef,
  } = useTooltip({
    placement: 'right',
    passiveTrigger: true,
    delay: 2000,
  });

  const navigationSidebarRef = useRef<HTMLDivElement>(null);
  const bottomNavigationItemsRef = useRef<HTMLDivElement>(null);

  const {
    icons: initialIcons,
    intercomIcon,
    notificationsIcon,
  } = useNavigationIcons();

  const { visibleIcons, hiddenIcons, sidebarRefCallback } = useIconOverflow({
    initialIcons,
    navigationSidebarRef,
    bottomNavigationItemsRef,
  });

  return (
    <MotionNavigation
      ref={sidebarRefCallback}
      id="menu"
      role="menu"
      aria-labelledby="menuButton"
      initial={false}
      variants={navigationVariants}
      animate={isNavigationOpen ? 'open' : 'closed'}
      transition={navigationTransition}
      as="aside"
      alignContent="space-between"
      gridArea="sidebar"
      padding="space24 space16 space24"
      position="relative"
      width="92px"
      gap="auto"
      height="100vh"
      style={{ marginRight: defaultTheme.space.space20 }}>
      <StyledButton
        id="menuButton"
        aria-controls="menu"
        aria-haspopup="true"
        aria-label={
          isNavigationOpen ? t('toggleLabelClosed') : t('toggleLabelOpen')
        }
        aria-pressed={isNavigationOpen}
        color="gray"
        size="small"
        icon={isNavigationOpen ? 'menuExpanded' : 'menuCollapsed'}
        variant="tertiary"
        onClick={toggleSidebar}
        position="absolute"
        right={isNavigationOpen ? '8px' : '-20px'}
        top="39px"
        zIndex={1}
        ref={triggerRef}
        {...triggerProps}
      />
      {isTooltipOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          {isNavigationOpen ? t('toggleLabelClosed') : t('toggleLabelOpen')}
        </Tooltip>
      )}
      <Grid as="nav" gap="space32">
        <OrganizationSwitcher
          isNavigationOpen={isNavigationOpen}
          toggleSwitcher={toggleSwitcher}
        />
        <NavigationIcons
          icons={visibleIcons}
          isNavigationOpen={isNavigationOpen}
          hiddenIcons={hiddenIcons}
        />
      </Grid>
      <Grid paddingLeft="space8" gap="space8" ref={bottomNavigationItemsRef}>
        <MenuIntercom
          intercomIcon={intercomIcon}
          isNavigationOpen={isNavigationOpen}
        />
        <MenuNotifications {...notificationsIcon} expanded={isNavigationOpen} />
        <MenuSettings expanded={isNavigationOpen} />
        <UserProfile expanded={isNavigationOpen} />
      </Grid>
    </MotionNavigation>
  );
};
