import {
  DocumentStatus,
  EcmDocumentStatus,
  EcmDocumentType,
  EcmStorageFormInitialDataQuery,
  User,
  useEcmStorageFormInitialDataQuery,
  useSmartFieldSuggestionsForRequesterQuery,
  useStorageFormInitialDataQuery,
} from 'generated-types/graphql.types';
import { useDateConverter } from 'hooks/useDateConverter';
import { formatIntegerAmountToDecimal } from 'hooks/useMoneyFormatter';
import { useMemo } from 'react';
import { DefaultValues } from 'react-hook-form';
import { toExtractedContactFieldItem } from '../../toContactFieldItem';
import { toFieldMetadata } from '../../toFieldMetadata';
import { calculateRelativeDateInitialValue } from './calculateRelativeDateInitialValue';
import { removePdfFileExtension } from './removePdfFileExtension';
import { StorageFormExtractedContactFieldItem } from './StorageFormContactField';
import { StorageFormMetadata } from './StorageFormMetadataContext';
import { StorageFormValues } from './storageFormSchema';
import { toEcmExtractedContactFieldItem } from './toEcmExtractedContactFieldItem';

interface UseStorageFormInitialDataOptions {
  /** Document to fetch */
  documentId: string;
  /** Determines which document data should be fetched/updated, invoice or ECM */
  isInvoice?: boolean;
}

export interface UseStorageFormInitialDataReturn {
  defaultMetadata: StorageFormMetadata;
  defaultValues: DefaultValues<StorageFormValues>;
  extractedContact?: StorageFormExtractedContactFieldItem;
  createdBy?: Pick<User, 'avatarUrl' | 'id' | 'name'>;
  documentStatus?: EcmDocumentStatus;
  loading: boolean;
  isDocumentReadOnly: boolean;
  globalDocumentId: string;
  /** Tags associated with the document. */
  tags?: EcmStorageFormInitialDataQuery['getAggregatedEcmDocument']['tags'];
}

export const useStorageFormInitialData = ({
  documentId,
  isInvoice,
}: UseStorageFormInitialDataOptions): UseStorageFormInitialDataReturn => {
  const { dateTimeStringToDateString } = useDateConverter();

  const { data: invoiceData, loading: invoiceLoading } =
    useStorageFormInitialDataQuery({
      variables: { documentId },
      // we need to cache the data so we can insert an optimistic response eg comments
      skip: !isInvoice,
    });

  const {
    bookings,
    contact,
    documentFile,
    extractedContact,
    status,
    invoiceDate,
    invoiceNumber,
    isReadOnly: isInvoiceReadOnly,
    amount: invoiceAmount,
    currency: invoiceCurrency,
    globalDocumentId: invoiceGlobalDocumentId,
    tags: invoiceTags,
  } = invoiceData?.getDocument ?? {};

  const isNewDocument = status === DocumentStatus.New;

  const { data: ecmDocumentData, loading: ecmDocumentLoading } =
    useEcmStorageFormInitialDataQuery({
      variables: { id: documentId },
      // we need to cache the data so we can insert an optimistic response eg comments
      skip: isInvoice,
    });

  const {
    contactId,
    contactName,
    createdBy,
    documentDate,
    documentName,
    documentNumber,
    documentStatus,
    documentType,
    fileSource,
    isSensitive,
    isReadOnly: isEcmReadOnly,
    notes,
    notifyPerson,
    documentSubCategory,
    amount: documentAmount,
    costCenter: documentCostCenter,
    startDate,
    endDate,
    terminationDate,
    terminationReminderDate,
    responsiblePerson,
    extractedContact: ecmExtractedContact,
    globalDocumentId: ecmGlobalDocumentId,
    tags,
  } = ecmDocumentData?.getAggregatedEcmDocument ?? {};

  // We allow amount to be "0 / 0,00", so we need to explicitly check
  // that `documentAmount.amount` is not `undefined`
  const formattedDocumentAmount =
    documentAmount?.amount !== undefined
      ? formatIntegerAmountToDecimal(
          documentAmount.amount,
          documentAmount.precision
        )
      : undefined;

  const combinedContact = useMemo(() => {
    // Contact from ECM document
    if (contactId) {
      return {
        value: {
          id: contactId,
          name: { value: contactName },
        },
      };
    }

    // Contact from invoice
    if (contact) {
      return contact;
    }

    // Suggested contact from invoice
    if (
      extractedContact &&
      isNewDocument // Only apply suggestions for “new” docs
    ) {
      return {
        value: extractedContact,
        confidence: extractedContact?.name?.confidence,
        source: extractedContact?.name?.source,
      };
    }

    //Suggested contact from ECM
    if (ecmExtractedContact) {
      return {
        value: {
          name: { value: ecmExtractedContact.name },
          id: ecmExtractedContact.id,
          email: ecmExtractedContact.email,
          phoneNumber: ecmExtractedContact.phoneNumber,
          iban: ecmExtractedContact.iban,
          bankAccountNumber: ecmExtractedContact.bankAccountNumber,
          swiftCode: ecmExtractedContact.swiftCode,
          taxNumber: ecmExtractedContact.taxNumber,
          vatId: ecmExtractedContact.vatId,
          street: ecmExtractedContact.street,
          city: ecmExtractedContact.city,
          postalCode: ecmExtractedContact.postalCode,
          postOfficeBox: ecmExtractedContact.postOfficeBox,
          countryISOCode: ecmExtractedContact.country,
          customerNumber: ecmExtractedContact.customerNumber,
          createTransfer: ecmExtractedContact.automaticPayment
            ? !ecmExtractedContact.automaticPayment
            : null,
        },
      };
    }

    return {};
  }, [
    contact,
    contactId,
    contactName,
    extractedContact,
    isNewDocument,
    ecmExtractedContact,
  ]);

  const documentContactId = combinedContact?.value?.id;
  const isDocumentReadOnly =
    (isInvoice ? isInvoiceReadOnly : isEcmReadOnly) ?? true;

  const {
    data: smartFieldSuggestionsData,
    loading: loadingSmartFieldSuggestions,
  } = useSmartFieldSuggestionsForRequesterQuery({
    variables: {
      contactId: documentContactId as string,
      documentId: documentId as string,
    },
    // only apply SFS when document has status "New",
    // i.e. it's not yet stored
    skip:
      !isNewDocument ||
      typeof documentContactId !== 'string' ||
      isDocumentReadOnly,
  });

  const sfsSuggestionForRequester =
    smartFieldSuggestionsData?.sfsSuggestionForRequester;

  const documentFileName = isInvoice ? documentFile?.name : fileSource?.name;

  const invoiceCostCenter =
    bookings?.[0]?.costCenter?.value ??
    sfsSuggestionForRequester?.costCenter1?.value;

  return {
    defaultMetadata: isInvoice
      ? {
          amount: toFieldMetadata(invoiceAmount),
          contact: { value: toFieldMetadata(combinedContact) },
          costCenter: {
            value: toFieldMetadata(
              bookings?.[0]?.costCenter,
              sfsSuggestionForRequester?.costCenter1
            ),
          },
          currency: toFieldMetadata(invoiceCurrency),
          documentDate: toFieldMetadata(invoiceDate),
          documentNumber: toFieldMetadata(invoiceNumber),
        }
      : {},
    defaultValues: {
      amount: isInvoice ? invoiceAmount?.value : formattedDocumentAmount,
      currency: isInvoice ? invoiceCurrency?.value : documentAmount?.currency,
      contact: {
        value: combinedContact.value?.id ?? null,
        inputValue: combinedContact.value?.name?.value ?? '',
      },
      costCenter: {
        value: isInvoice ? invoiceCostCenter?.id : documentCostCenter?.id,
        inputValue: isInvoice
          ? invoiceCostCenter?.readableName
          : documentCostCenter?.readableName,
      },
      documentDate: documentDate
        ? dateTimeStringToDateString(documentDate)
        : invoiceDate?.value
        ? dateTimeStringToDateString(invoiceDate.value)
        : undefined,
      documentName:
        documentName ?? removePdfFileExtension(documentFileName ?? ''),
      documentType: documentType
        ? (documentType as EcmDocumentType)
        : undefined,
      documentNumber: documentNumber ?? invoiceNumber?.value,
      documentSubCategory: documentSubCategory?.id,
      startDate: startDate ? dateTimeStringToDateString(startDate) : undefined,
      endDate: endDate ? dateTimeStringToDateString(endDate) : undefined,
      terminationDate: terminationDate
        ? dateTimeStringToDateString(terminationDate)
        : undefined,
      terminationReminderDate: terminationReminderDate
        ? dateTimeStringToDateString(terminationReminderDate)
        : undefined,
      relativeDate: calculateRelativeDateInitialValue(
        terminationDate,
        terminationReminderDate
      ),
      // The backend is already storing multiple responsible persons.
      // Because our field only supports one responsible person for now, we need
      // to grab the first item.
      responsiblePerson: responsiblePerson?.primary.map(person => person.id),
      notifyPerson: notifyPerson?.primary.map(person => person.id),
      notes,
      isSensitive: isSensitive ?? false,
    },
    extractedContact: extractedContact
      ? toExtractedContactFieldItem(extractedContact)
      : ecmExtractedContact
      ? toEcmExtractedContactFieldItem(ecmExtractedContact)
      : undefined,
    createdBy: createdBy ?? undefined,
    documentStatus: documentStatus ?? undefined,
    loading:
      invoiceLoading || ecmDocumentLoading || loadingSmartFieldSuggestions,
    globalDocumentId: ecmGlobalDocumentId ?? invoiceGlobalDocumentId ?? '',
    tags: isInvoice ? invoiceTags : tags,
    isDocumentReadOnly,
  };
};
