import {
  Grid,
  Link,
  MenuItem,
  Text,
  TruncatedText,
  useModal,
  usePopover,
} from '@candisio/design-system';
import { AddTransactionsModal } from 'components/Transactions/AddTransactions/AddTransactionsModal';
import { isVisibleInWallet } from 'components/Transactions/TransactionDetailsCard/CreditCardLinksTooltip/CreditCardLinksTooltip';
import { useTransactionsLinks } from 'components/Transactions/TransactionDetailsCard/CreditCardLinksTooltip/useTransactionsLinks';
import { UnlinkActionConfirm as TransactionUnlinkActionConfirm } from 'components/Transactions/TransactionDetailsCard/TransactionDetailsCardContextMenu';
import {
  PopoverDescription,
  popoverDescription,
} from 'components/Transactions/TransactionDetailsCard/utils';
import { DocumentRelationshipsModal } from 'containers/document-relationships/DocumentRelationshipsModal';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import {
  DocumentStatus,
  EcmDocumentStatus,
  useGetCardStatusByIdQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import {
  ComponentProps,
  ElementRef,
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { useDocumentNavigation } from 'views/Inbox/hooks/useDocumentNavigation';
import { useUnlinkTransaction } from 'views/Inbox/hooks/useUnlinkTransaction';
import { ActionConfirm } from './ActionConfirm';
import { ActionsMenu } from './ActionsMenu';
import {
  DocumentSummaryCard,
  DocumentSummaryCardList,
  DocumentSummaryCardListContainer,
  EmptyDocumentSummaryCardList,
  ErrorDocumentSummaryCardList,
  LoadingDocumentSummaryCard,
  PromoDocumentSummaryCardList,
} from './DocumentSummaryCard';
import {
  DocumentSummaryCardActionMenu,
  DocumentSummaryCardActionMenuPromo,
} from './DocumentSummaryCardActionMenu';
import {
  RelatedDocument,
  useDocumentRelations,
} from './hooks/useDocumentRelations';
import { useDocumentTransactions } from './hooks/useDocumentTransactions';
import { Transaction, TransactionSummaryCard } from './TransactionSummaryCard';

export type DocumentRelationsProps = {
  documentId: string;
  documentName: string;
  documentStatus?: EcmDocumentStatus | DocumentStatus;
  canAddTransaction?: boolean;
  readOnly?: boolean;
};

export const DocumentRelationsPromo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  return (
    <DocumentSummaryCardListContainer key="relationships">
      <DocumentSummaryCardList>
        <PromoDocumentSummaryCardList />
      </DocumentSummaryCardList>
      <UpsellPromo feature={FEATURE.DOCUMENT_RELATIONS}>
        <DocumentSummaryCardActionMenuPromo>
          {t('promo.link')}
        </DocumentSummaryCardActionMenuPromo>
      </UpsellPromo>
    </DocumentSummaryCardListContainer>
  );
};

export const DocumentRelations = ({
  documentId,
  documentName,
  documentStatus,
  canAddTransaction = false,
  readOnly = false,
}: DocumentRelationsProps) => {
  const [t] = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [manageDocumentRelationsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.manageDocumentRelations,
  ]);

  const {
    open: openTransactions,
    close: closeTransactions,
    isOpen: isOpenTransactions,
  } = useModal();

  const {
    open: openRelationships,
    close: closeRelationships,
    isOpen: isOpenRelationships,
  } = useModal();

  const goToManageRelationships = useCallback(() => {
    const currentPath = location.pathname;

    navigate(`${currentPath}${Routes.RELATIONSHIPS}`);
  }, [location.pathname, navigate]);

  const menuItems = useMemo<MenuItem[]>(
    () => [
      ...(canAddTransaction
        ? [
            {
              id: '1',
              label: t('document.tabs.relationships.addTransaction'),
              onAction: openTransactions,
              disabled: readOnly,
            },
          ]
        : []),
      {
        id: '2',
        label: manageDocumentRelationsFF
          ? t('document.tabs.relationships.manageRelationships')
          : t('document.tabs.relationships.addDocument'),
        disabled: readOnly,
        onAction: manageDocumentRelationsFF
          ? goToManageRelationships
          : openRelationships,
      },
    ],
    [
      canAddTransaction,
      goToManageRelationships,
      manageDocumentRelationsFF,
      openRelationships,
      openTransactions,
      readOnly,
      t,
    ]
  );

  const {
    isLoading: isLoadingRelations,
    isEmpty: hasNoRelations,
    hasError,
    relatedDocuments,
    isLinkingDocumentsPending,
    isUnlinkingDocumentsPending,
    handleLinkDocuments,
    handleUnlinkDocuments,
  } = useDocumentRelations(documentId);

  const { transaction, isLoading: isLoadingTransaction } =
    useDocumentTransactions(documentId);

  const onClose = useCallback(() => {
    closeTransactions();
    closeRelationships();
  }, [closeTransactions, closeRelationships]);

  const isEmpty = hasNoRelations && !transaction;
  const isLoading = isLoadingRelations || isLoadingTransaction;

  const showError = !isLoading && hasError;
  const showEmptyList = !isLoading && !hasError && isEmpty;
  const showTransaction = !isLoading && !hasError && transaction;
  const showLoadingItem = isLoadingRelations || isLinkingDocumentsPending;

  const existingRelationshipsDocIds = useMemo(
    () => relatedDocuments.map(d => d.id),
    [relatedDocuments]
  );

  return (
    <>
      <DocumentSummaryCardListContainer key="relationships">
        <DocumentSummaryCardList>
          {showError && <ErrorDocumentSummaryCardList key="error-item" />}
          {showEmptyList && (
            <EmptyDocumentSummaryCardList
              key="empty-item"
              canAddTransaction={canAddTransaction}
            />
          )}
          {showTransaction && (
            <TransactionRelatedItem
              transaction={transaction}
              documentId={documentId}
              documentStatus={documentStatus}
              key="transaction-item"
            />
          )}
          {relatedDocuments.map(relatedDocument => (
            <DocumentRelationItem
              readOnly={readOnly}
              key={relatedDocument.relationId}
              documentName={documentName}
              handleUnlinkDocuments={handleUnlinkDocuments}
              isUnlinkingDocumentsPending={isUnlinkingDocumentsPending}
              {...relatedDocument}
            />
          ))}
          {showLoadingItem && <LoadingDocumentSummaryCard key="loading-item" />}
        </DocumentSummaryCardList>
        {menuItems.length > 0 && (
          <DocumentSummaryCardActionMenu disabled={readOnly} items={menuItems}>
            {t('document.tabs.relationships.newRelation')}
          </DocumentSummaryCardActionMenu>
        )}
      </DocumentSummaryCardListContainer>

      <AddTransactionsModal
        onClose={closeTransactions}
        isOpen={isOpenTransactions}
        documentId={documentId}
      />
      {isOpenRelationships && (
        <DocumentRelationshipsModal
          onClose={onClose}
          documentId={documentId}
          documentName={documentName}
          handleLinkDocuments={handleLinkDocuments}
          isLinkingDocumentsPending={isLinkingDocumentsPending}
          existingRelationshipsDocIds={existingRelationshipsDocIds}
        />
      )}
    </>
  );
};

type DocumentRelationItemProps = RelatedDocument & {
  documentName: string;
  isUnlinkingDocumentsPending: boolean;
  handleUnlinkDocuments: (...args: any) => void;
  readOnly?: boolean;
};

export const DocumentRelationItem = forwardRef<
  ForwardedRef<typeof DocumentSummaryCard>,
  DocumentRelationItemProps
>(
  (
    {
      documentName,
      handleUnlinkDocuments,
      isUnlinkingDocumentsPending = false,
      readOnly,
      ...relatedDocument
    },
    ref
  ) => {
    const [t] = useTranslation();

    const [isUnlinkPending, setUnlinkPending] = useState(
      () => isUnlinkingDocumentsPending
    );

    const { id, relationId, type } = relatedDocument;
    const unlinkDocument = useCallback(() => {
      if (!documentName || !relationId) return;

      setUnlinkPending(true);

      void handleUnlinkDocuments({
        documentName: documentName,
        relationshipId: relationId,
      });
    }, [handleUnlinkDocuments, documentName, relationId]);

    const { getOpenDocumentLink, navigate } = useDocumentNavigation();
    const documentHref = getOpenDocumentLink(id, type);
    const navigateDocumentAction = () => navigate(documentHref);

    const openDocumentAction = useCallback(
      () => window.open(documentHref, '_blank'),
      [documentHref]
    );

    const {
      open: openPopover,
      close: closePopover,
      popoverProps,
      popoverRef,
      triggerRef,
    } = usePopover({ placement: 'right top' });

    const menuItems = useMemo<MenuItem[]>(
      () => [
        {
          id: '1',
          label: t('document.tabs.relationships.openDocument'),
          onAction: openDocumentAction,
          renderItem: item => (
            <Link href={documentHref} external color="gray">
              {item.label}
            </Link>
          ),
        },
        {
          id: '2',
          label: t('document.tabs.relationships.removeDocument'),
          onAction: openPopover,
          disabled: readOnly,
        },
      ],
      [documentHref, openDocumentAction, openPopover, readOnly, t]
    );

    const actionsMenuLabel = t('documentContextMenu.openMenu');

    return (
      <>
        <DocumentSummaryCard
          ref={ref}
          onAction={navigateDocumentAction}
          isLoading={isUnlinkPending}
          document={relatedDocument}
          action={
            <ActionsMenu
              label={actionsMenuLabel}
              isActionPending={isUnlinkPending}
              items={menuItems}
              ref={triggerRef}
            />
          }
        />
        <UnlinkActionConfirm
          ref={popoverRef}
          popoverProps={popoverProps}
          isActionPending={isUnlinkPending}
          closePopover={closePopover}
          handleConfirm={unlinkDocument}
        />
      </>
    );
  }
);

DocumentRelationItem.displayName = 'DocumentRelationItem';

const UnlinkActionConfirm = forwardRef<
  ElementRef<typeof ActionConfirm>,
  Omit<ComponentProps<typeof ActionConfirm>, 'informationText'>
>(({ popoverProps, isActionPending, closePopover, handleConfirm }, ref) => {
  const [t] = useTranslation();

  const informationText = (
    <Grid gap="space16">
      <Text fontWeight="semibold">
        {t('document.tabs.relationships.removeConfirmation.title')}
      </Text>
      <Text>{t('document.tabs.relationships.removeConfirmation.text')}</Text>
    </Grid>
  );

  const cancelLabel = t(
    'document.tabs.relationships.removeConfirmation.cancelLabel'
  );

  const confirmLabel = (
    <TruncatedText>
      {t('document.tabs.relationships.removeConfirmation.confirmLabel')}
    </TruncatedText>
  );

  return (
    <ActionConfirm
      ref={ref}
      popoverProps={popoverProps}
      isActionPending={isActionPending}
      closePopover={closePopover}
      handleConfirm={handleConfirm}
      informationText={informationText}
      cancelLabel={cancelLabel}
      confirmLabel={confirmLabel}
      maxWidth="450px"
    />
  );
});

UnlinkActionConfirm.displayName = 'UnlinkActionConfirm';

const unlinkableStatuses: EcmDocumentStatus[] = [
  EcmDocumentStatus.New,
  EcmDocumentStatus.Open,
  EcmDocumentStatus.Approved,
  EcmDocumentStatus.Rejected,
] as const;

const isDocumentUnlinkable = (
  documentStatus: DocumentStatus | EcmDocumentStatus
) =>
  documentStatus ? unlinkableStatuses.includes(documentStatus as any) : false;

const openNewTab = (to: { pathname: string; search: string }) =>
  void window.open(`${to.pathname}?${to.search}`, '_blank');

type TransactionRelatedItemProps = {
  transaction: ComponentProps<typeof TransactionSummaryCard>['transaction'];
  documentId?: string;
  documentStatus?: EcmDocumentStatus | DocumentStatus;
};

export const TransactionRelatedItem = forwardRef<
  ElementRef<typeof TransactionSummaryCard>,
  TransactionRelatedItemProps
>(({ transaction, documentId, documentStatus }, ref) => {
  const [t] = useTranslation();

  const {
    open: openPopover,
    close: closePopover,
    popoverProps,
    popoverRef,
    triggerRef,
  } = usePopover({ placement: 'right top' });

  const { isUnlinkPending, unlinkTransaction } = useUnlinkTransaction(
    transaction.id,
    documentId
  );

  const canUnlinkTransaction =
    !!documentStatus && isDocumentUnlinkable(documentStatus);

  const menuItems: MenuItem[] = useTransactionMenuItems(
    transaction,
    canUnlinkTransaction,
    openPopover
  );

  const actionsMenuLabel = t('documentContextMenu.openMenu');

  const fallbackDescription =
    documentStatus &&
    popoverDescription[documentStatus as keyof PopoverDescription];

  return (
    <>
      <TransactionSummaryCard
        ref={ref}
        transaction={transaction}
        menu={
          <ActionsMenu
            label={actionsMenuLabel}
            isActionPending={isUnlinkPending}
            items={menuItems}
            ref={triggerRef}
          />
        }
      />
      <TransactionUnlinkActionConfirm
        ref={popoverRef}
        popoverProps={popoverProps}
        isActionPending={isUnlinkPending}
        closePopover={closePopover}
        handleConfirm={unlinkTransaction}
        fallbackDescription={fallbackDescription}
      />
    </>
  );
});

TransactionRelatedItem.displayName = 'TransactionRelatedItem';

const renderLink = (item: MenuItem) => (
  <Link external color="gray">
    {item.label}
  </Link>
);

const useTransactionMenuItems = (
  transaction: Transaction,
  canUnlinkTransaction: boolean,
  openPopover: () => void
): MenuItem[] => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.TRANSACTIONS);
  const links = useTransactionsLinks(transaction.cardId);

  const user = useCurrentUser();
  const { isCardManager } = useUserRoles();
  const { showAllExtraFeatures } = useCreditCardsSetup();

  const { data } = useGetCardStatusByIdQuery({
    variables: { id: transaction.cardId },
    skip: !transaction.cardId,
  });

  const cardStatus = data?.getCardIssuerCardById.status;
  const membershipId = transaction.member?.membershipId ?? undefined;

  const isTransactionOwner = membershipId === user?.id;
  const isCardVisibleInWallet = !!cardStatus && isVisibleInWallet(cardStatus);
  const isAllowedToSeeCardSettings =
    showAllExtraFeatures &&
    ((isTransactionOwner && isCardVisibleInWallet) || isCardManager);

  interface MenuItemProps extends MenuItem {
    showMenuItem?: boolean;
  }

  const menuItems: MenuItemProps[] = useMemo(
    () =>
      [
        {
          id: '1',
          label: t(
            'transactionDetailsCard.creditCardLinks.archiveTransactions'
          ),
          onAction: () => {
            if (!links) return;
            openNewTab(links.archiveTransactions);
          },
          renderItem: renderLink,
        },
        {
          id: '2',
          label: t('transactionDetailsCard.creditCardLinks.cardSettings'),
          onAction: () => {
            if (!links) return;

            const to = isCardManager
              ? links.cardManagerView
              : links.dashboardWallet;

            openNewTab(to);
          },
          renderItem: renderLink,
          showMenuItem: isAllowedToSeeCardSettings,
        },
        {
          id: '3',
          label: t('common:document.tabs.relationships.removeTransaction'),
          onAction: openPopover,
          showMenuItem: canUnlinkTransaction,
        },
      ].filter(item => item.showMenuItem !== false),
    [
      canUnlinkTransaction,
      isAllowedToSeeCardSettings,
      isCardManager,
      links,
      openPopover,
      t,
    ]
  );

  return menuItems;
};
