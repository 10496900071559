import {
  ReimbursementItemType,
  useSubmitReimbursementCaseForReviewMutation,
} from 'generated-types/graphql.types';
import { reimbursementCaseByIdQuery } from '../toolkit/queries';

export interface ReimbursementError {
  id: string;
  errors: number;
  title: string | undefined;
  type: ReimbursementItemType;
}

interface UseSubmitReimbursementCaseForReviewParams {
  reimbursementItemsCount: number;
}

export const useSubmitReimbursementCaseForReview = ({
  reimbursementItemsCount,
}: UseSubmitReimbursementCaseForReviewParams) => {
  const [submitReimbursmentCaseForReview, { loading }] =
    useSubmitReimbursementCaseForReviewMutation();

  return {
    submitReimbursmentCaseForReview: async (reimbursementId: string) => {
      const response = await submitReimbursmentCaseForReview({
        variables: { id: reimbursementId },
        refetchQueries: [
          {
            query: reimbursementCaseByIdQuery,
            variables: { id: reimbursementId },
          },
        ],
      });

      if (response.data?.submitReimbursementCaseForReview.success === true) {
        return {
          status: 'success',
          reimbursementItemsErrors: [],
        };
      }

      const reimbursementItemsErrors = (
        response.data?.submitReimbursementCaseForReview.errors
          .reimbursementItemsErrors ?? []
      ).map(reimbursementItem => ({
        id: reimbursementItem.id,
        errors: reimbursementItem.errors.length,
        title: reimbursementItem.title ?? undefined,
        type: reimbursementItem.type,
      }));

      return {
        status: 'error',
        reimbursementItemsErrors: reimbursementItemsErrors,
        hasNoReimbursements: reimbursementItemsCount === 0,
      };
    },
    isSubmitReimbursmentCaseForReviewPending: loading,
  };
};
