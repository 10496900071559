import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useMemo } from 'react';
import { ReimbursementFormValues } from '../toolkit/reimbursementFormSchema';
import { Reimbursement } from './useFormattedReimbursement';
const fallbackDefaultValues = {
  title: '',
  note: undefined,
  targetMembership: undefined,
  suggestedCostCenter: undefined,
};

const getSuggestedCostCenter = (
  suggestedCostCenter?: Reimbursement['suggestedCostCenter']
) => {
  if (suggestedCostCenter?.id && suggestedCostCenter?.readableName) {
    return {
      value: suggestedCostCenter.id,
      inputValue: suggestedCostCenter.readableName,
    };
  }

  return null;
};

interface UseInitialReimbursementFormValuesReturn {
  values: ReimbursementFormValues;
  defaultValues: ReimbursementFormValues;
}
export const useInitialReimbursementFormValues = (
  reimbursement?: Reimbursement
): UseInitialReimbursementFormValuesReturn => {
  const currentUserId = useCurrentUser()?.id ?? '';

  // default values for initializing the reimbursement form
  const defaultValues: ReimbursementFormValues = useMemo(() => {
    return {
      ...fallbackDefaultValues,
      targetMembership: [currentUserId],
    };
  }, [currentUserId]);

  // values for the reimbursement form, based on fetched reimbursement data
  const values: ReimbursementFormValues = useMemo(() => {
    const { note, title, targetMembership, suggestedCostCenter } =
      reimbursement ?? {};

    return {
      title: title ?? '',
      note: note ?? null,
      targetMembership: targetMembership?.id
        ? [targetMembership?.id]
        : [currentUserId],
      suggestedCostCenter: getSuggestedCostCenter(suggestedCostCenter),
    };
  }, [reimbursement, currentUserId]);

  return {
    values,
    defaultValues,
  };
};
