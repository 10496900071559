import { Drawer, Heading, Flex } from '@candisio/design-system';
import { ApproversAccessCardContainer } from 'components/DocumentAccess/ApproversAccessCardContainer';
import { BuiltInRolesAccessCardContainer } from 'components/DocumentAccess/BuiltInRolesAccessCardContainer';
import { ViewersAccessCardContainer } from 'components/DocumentAccess/ViewersAccessCardContainer';
import {
  DrawerLayout,
  DrawerLayoutProps,
} from 'components/DrawerLayout/DrawerLayout';
import { useTranslation } from 'react-i18next';

export interface DocumentAccessDrawerProps
  extends Pick<DrawerLayoutProps, 'compact'> {
  isOpen: boolean;
  onCloseDrawer: () => void;
  globalDocumentId?: string;
}

export const DocumentAccessDrawer = ({
  isOpen,
  onCloseDrawer,
  compact,
  globalDocumentId,
}: DocumentAccessDrawerProps) => {
  const [t] = useTranslation();

  return (
    <Drawer isOpen={isOpen} onClose={onCloseDrawer}>
      <DrawerLayout
        compact={compact}
        onClose={onCloseDrawer}
        header={
          <Heading as="h3">{t('common:documentAccess.drawer.title')}</Heading>
        }>
        <Flex direction="column" gap="space16">
          <ViewersAccessCardContainer globalDocumentId={globalDocumentId} />
          <ApproversAccessCardContainer globalDocumentId={globalDocumentId} />
          <BuiltInRolesAccessCardContainer
            globalDocumentId={globalDocumentId}
          />
        </Flex>
      </DrawerLayout>
    </Drawer>
  );
};
