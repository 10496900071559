import { ProcessingFormExtractedContactFieldItem } from 'components/Form/ProcessingForm/ProcessingFormContactField';
import {
  ProcessingFormFieldMetadata,
  ProcessingFormMetadata,
} from 'components/Form/ProcessingForm/ProcessingFormMetadataContext';
import {
  EXTRACTED_CONTACT_ID,
  ProcessingFormValues,
} from 'components/Form/ProcessingForm/processingFormSchema';
import { useCalculatedRoundingAmountResult } from 'components/Form/utils';
import {
  grossToNet,
  sumNetAmounts,
  sumTaxAmounts,
} from 'containers/SplitBookings/toolkit/utils';
import {
  DocumentCurrency,
  DocumentStatus,
  GetDocumentForDraftQuery,
  useGetDocumentForDraftQuery,
  useOrganizationQuery,
  useSmartFieldSuggestionsForRequesterQuery,
  useWorkflowTemplatesQuery,
} from 'generated-types/graphql.types';
import { useDateConverter } from 'hooks/useDateConverter';
import { useGetBookingAssociation } from 'hooks/useGetBookingAssociation';
import { useGetSuggestedTaxCodeIfMatched } from 'hooks/useGetSuggestedTaxCodeIfMatched';
import { formatIntegerAmountToDecimal } from 'hooks/useMoneyFormatter';
import { useSap } from 'orgConfig/sap';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useMemo } from 'react';
import { DeepPartial } from 'utility-types';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';
import { toExtractedContactFieldItem } from './toContactFieldItem';
import { toFieldMetadata } from './toFieldMetadata';

const FALLBACK_BOOKING_ID = 'fallback';

export interface useProcessingFormInitialDataOptions {
  documentId?: string;
  readOnly?: boolean;
}
export interface ProcessingFormInitialData {
  defaultMetadata: ProcessingFormMetadata;
  defaultValues: DeepPartial<ProcessingFormValues>;
  isDocumentReadOnly: boolean;
  loading: boolean;
  extractedContact?: ProcessingFormExtractedContactFieldItem;
  status?: DocumentStatus;
  onContactChange: (newContactId: string | null) => void;
  /** Id that will replace documentId in the future */
  globalDocumentId?: string | null;
  /** Existing document tags */
  tags?: NonNullable<GetDocumentForDraftQuery['getDocument']>['tags'];
}

/** Fetches initial values and metadata for processing form */
export const useProcessingFormInitialData = ({
  documentId,
  readOnly = false,
}: useProcessingFormInitialDataOptions = {}): ProcessingFormInitialData => {
  const { dateTimeStringToDateString } = useDateConverter();

  const { isActive: isSapActive, shouldUsePackageFreight } = useSap();

  const organizationId = useOrganizationId();
  const { data: organizationData, loading: loadingOrganization } =
    useOrganizationQuery({ variables: { id: organizationId } });

  const hasWorkflows =
    organizationData?.organization?.hasActiveWorkflowTemplates ?? false;

  const { data: workflowTemplatesData, loading: loadingWorkflowTemplates } =
    useWorkflowTemplatesQuery({
      variables: { input: { isArchived: false } },
      skip: !hasWorkflows,
    });

  const workflowTemplates = workflowTemplatesData?.workflowTemplates;

  const { data: documentData, loading: loadingDocument } =
    useGetDocumentForDraftQuery({
      variables: { id: documentId as string },
      skip: typeof documentId !== 'string',
    });

  const { isQuantityRequired, getBookingAssociationData } =
    useGetBookingAssociation(documentId);

  const getSuggestedTaxCodeIfMatched = useGetSuggestedTaxCodeIfMatched();

  // For transaction-derived fields we force the metadata
  const transactionMetadata: ProcessingFormFieldMetadata = {
    source: 'transaction',
    confidence: 1,
  };

  // For credit-card-derived fields we force the metadata
  const creditCardMetadata: ProcessingFormFieldMetadata = {
    source: 'creditCard',
    confidence: 1,
  };

  const {
    amount,
    bookings,
    category,
    contact,
    createTransfer,
    currency,
    extractedContact,
    hasTransactionLinked,
    iban,
    swiftCode,
    invoiceDate,
    invoiceDeliveryDate,
    invoicePostingDate,
    invoiceNumber,
    isReadOnly: isDocumentReadOnly,
    purchaseOrderData,
    status,
    accountingArea,
    suggestedApprovers,
    workflow,
    transactions,
    globalDocumentId,
    tags,
    roundingAmount,
  } = documentData?.getDocument ?? {};

  const transaction =
    transactions && transactions.length ? transactions[0] : null;

  // Add a fallback to bookings in case the API returns none
  const bookingsWithFallback =
    bookings && bookings.length > 0
      ? bookings
      : [{ id: FALLBACK_BOOKING_ID, amount }];

  const shouldUseSuggestions =
    !readOnly &&
    !isDocumentReadOnly &&
    // We only apply suggestions for “new” documents
    status === DocumentStatus.New;

  /**
   * The API returns the assigned contact and suggested contact separately. We
   * combine them here for convenience.
   */
  const combinedContact = useMemo(() => {
    if (contact) {
      return contact;
    }

    if (shouldUseSuggestions && extractedContact?.name) {
      return {
        value: {
          id: extractedContact.id ?? EXTRACTED_CONTACT_ID,
          name: extractedContact.name,
        },
        confidence: extractedContact?.name?.confidence,
        source: extractedContact?.name?.source,
      };
    }

    return null;
  }, [contact, extractedContact, shouldUseSuggestions]);

  /**
   * The API returns approvers, transaction approvers and suggested (1st)
   * approvers separately. We combine them here for convenience.
   */
  const combinedApprovers = useMemo(() => {
    // Document has manual approvers
    if (workflow?.steps && workflow.steps.length > 0) {
      return { value: workflow.steps.map(step => step.approvers) };
    }

    // Document has a transaction level approvers
    if (
      transaction?.accountingData?.approvers &&
      transaction.accountingData.approvers.length
    ) {
      return {
        value: transaction.accountingData.approvers,
        source: 'creditCard',
        confidence: 1,
      };
    }

    if (shouldUseSuggestions) {
      // Document has suggested (1st) approvers
      if (suggestedApprovers?.value && suggestedApprovers.value.length > 0) {
        return {
          value: [suggestedApprovers.value],
          confidence: suggestedApprovers.confidence,
          source: suggestedApprovers.source,
        };
      }
    }

    return {};
  }, [
    shouldUseSuggestions,
    suggestedApprovers,
    workflow?.steps,
    transaction?.accountingData?.approvers,
  ]);

  // We’re only interested in the document *type*
  const type = category?.documentType;

  // The following fields are *stored* for each booking on the document but, in
  // practice, we don’t allow editing these fields on a per-booking basis.
  // Therefore they are treated as root-level fields in the processing form:
  const paymentCondition = bookingsWithFallback[0]?.paymentConditionId;
  const discountDate = bookingsWithFallback[0]?.discountPaymentDate;
  const discountPercentage = bookingsWithFallback[0]?.discountPercentage;
  const discountAmount = bookingsWithFallback[0]?.discountAmount;

  const workflowTemplate = workflow?.workflowTemplate ?? undefined;

  const contactId = combinedContact?.value?.id;
  const {
    data: smartFieldSuggestionsData,
    loading: loadingSmartFieldSuggestions,
    refetch: refetchSmartFieldSuggestions,
  } = useSmartFieldSuggestionsForRequesterQuery({
    variables: {
      contactId: contactId as string,
      documentId: documentId as string,
    },
    skip: !shouldUseSuggestions || typeof contactId !== 'string',
  });

  const sfsSuggestionForRequester =
    smartFieldSuggestionsData?.sfsSuggestionForRequester;

  const suggestions = {
    approverUserIds: sfsSuggestionForRequester?.approverUserIds,
    workflowTemplate: sfsSuggestionForRequester?.workflowTemplate,
    // We only apply the following smart field suggestions when we have a single
    // booking, otherwise the suggestions wouldn’t be visible to the user:
    // - general ledger account
    // - tax code
    // - cost center
    // - cost object
    ...(bookingsWithFallback.length === 1 && {
      generalLedgerAccount: sfsSuggestionForRequester?.generalLedgerAccount,
      taxCode: sfsSuggestionForRequester?.bookingKey,
      costCenter: sfsSuggestionForRequester?.costCenter1,
      costObject: sfsSuggestionForRequester?.costCenter2,
    }),
  };

  // The API should return a full WorkflowTemplate object as the workflow
  // template suggestion, but it only returns the id and name of the template.
  // For now, we need to look it up in the list of workflow templates.
  const suggestedWorkflowTemplate = suggestions.workflowTemplate?.value
    ? workflowTemplates?.find(
        template => template.id === suggestions.workflowTemplate?.value.id
      )
    : undefined;

  const defaultWorkflow =
    workflowTemplate?.id ??
    transaction?.accountingData?.approvalWorkflowTemplateId ??
    suggestions.workflowTemplate?.value.id;

  const defaultApprovers =
    (workflowTemplate ?? suggestedWorkflowTemplate)?.steps?.map(step => ({
      approvers: step.resolvers?.reduce<string[]>((result, approver) => {
        if (approver?.id) {
          result.push(approver.id);
        }

        return result;
      }, []),
    })) ??
    combinedApprovers.value?.map(step => ({
      approvers: step?.map(approver => approver.id),
    })) ??
    suggestions.approverUserIds?.value?.map(step => ({ approvers: step }));

  const mappedBookings = bookingsWithFallback.map(booking => {
    const netAmount = readOnly
      ? booking.netAmount
      : grossToNet(booking.amount?.value ?? 0, booking.vatRate?.value ?? 0);

    const taxAmount =
      readOnly || !netAmount
        ? booking.taxAmount
        : roundToCurrencyPrecision((booking.amount?.value ?? 0) - netAmount);

    const suggestedTaxCodeId = getSuggestedTaxCodeIfMatched(
      booking.vatRate?.value,
      suggestions.taxCode?.value.id
    );

    const {
      quantity: associatedBookingQuantity,
      sapExpenseType,
      unitPrice,
    } = getBookingAssociationData(booking.id);

    const bookingUnitPrice = formatIntegerAmountToDecimal(
      unitPrice?.amount ?? 0,
      unitPrice?.precision ?? 0
    );

    return {
      bookingId: booking.id !== FALLBACK_BOOKING_ID ? booking.id : undefined,
      amount: booking.amount?.value ?? undefined,
      taxAmount: booking.taxAmount ?? taxAmount,
      netAmount: booking.netAmount ?? netAmount,
      vatRate: booking.vatRate?.value ?? undefined,
      dueDate: booking.dueDate?.value
        ? dateTimeStringToDateString(booking.dueDate?.value)
        : undefined,
      postingText: booking.postingText ?? undefined,
      note: booking.note?.value,
      generalLedgerAccount: {
        value:
          booking.generalLedgerAccount?.value.id ??
          suggestions.generalLedgerAccount?.value.id,
        inputValue:
          booking.generalLedgerAccount?.value.readableName ??
          suggestions.generalLedgerAccount?.value.readableName ??
          '',
      },
      taxCode: booking.bookingKey?.value.id ?? suggestedTaxCodeId,
      costCenter: {
        value: booking.costCenter?.value.id ?? suggestions.costCenter?.value.id,
        inputValue:
          booking.costCenter?.value.readableName ??
          suggestions.costCenter?.value.readableName ??
          '',
      },
      costObject: {
        value: booking.costObject?.value.id ?? suggestions.costObject?.value.id,
        inputValue:
          booking.costObject?.value.readableName ??
          suggestions.costObject?.value.readableName ??
          '',
      },
      artistSocialInsuranceCode: booking.artistSocialInsuranceCode ?? undefined,
      extraCostInfo: {
        value: booking.extraCostInfo?.value.id,
        inputValue: booking.extraCostInfo?.value.readableName ?? '',
      },
      ...(isQuantityRequired
        ? { quantity: associatedBookingQuantity, unitPrice: bookingUnitPrice }
        : {}),
      ...(shouldUsePackageFreight && sapExpenseType ? { sapExpenseType } : {}),
    };
  });

  const updatedRoundingAmount = useCalculatedRoundingAmountResult({
    currency: currency?.value as DocumentCurrency,
    grossAmount: amount?.value as number,
    bookings: mappedBookings,
  });

  const defaultValues: DeepPartial<ProcessingFormValues> = {
    type: type?.value,
    contact: {
      value: combinedContact?.value.id,
      inputValue: combinedContact?.value.name.value ?? '',
    },
    invoiceDate: invoiceDate?.value
      ? dateTimeStringToDateString(invoiceDate.value)
      : undefined,
    deliveryDate:
      !isSapActive && invoiceDeliveryDate?.value
        ? dateTimeStringToDateString(invoiceDeliveryDate.value)
        : undefined,
    postingDate: invoicePostingDate?.value
      ? dateTimeStringToDateString(invoicePostingDate.value)
      : undefined,
    invoiceNumber: invoiceNumber?.value,
    purchaseOrderNumber: purchaseOrderData?.orderNumber,
    grossAmount: amount?.value ?? undefined,
    currency: currency?.value,
    bookings: mappedBookings,
    iban: iban?.value,
    swiftCode: swiftCode?.value,
    createTransfer: createTransfer?.value ?? false,
    paymentCondition: paymentCondition?.value,
    accountingArea: {
      value: accountingArea?.value.id,
      inputValue: accountingArea?.value.name,
    },
    discountDate: discountDate?.value
      ? dateTimeStringToDateString(discountDate.value)
      : undefined,
    discountPercentage: discountPercentage?.value ?? undefined,
    discountAmount: discountAmount?.value ?? undefined,
    approvalMode:
      hasWorkflows || defaultWorkflow
        ? !defaultWorkflow && defaultApprovers
          ? 'approvers'
          : 'workflow'
        : 'approvers',
    workflow: defaultWorkflow,
    approvers: defaultApprovers,
    roundingAmount: readOnly ? roundingAmount : updatedRoundingAmount,
    netAmount: sumNetAmounts(mappedBookings),
    taxAmount: sumTaxAmounts(mappedBookings),
  };

  const defaultMetadata: ProcessingFormMetadata = {
    type: hasTransactionLinked ? transactionMetadata : toFieldMetadata(type),
    contact: { value: toFieldMetadata(combinedContact) },
    invoiceDate: hasTransactionLinked
      ? transactionMetadata
      : toFieldMetadata(invoiceDate),
    deliveryDate: hasTransactionLinked
      ? transactionMetadata
      : toFieldMetadata(invoiceDeliveryDate),
    invoiceNumber: toFieldMetadata(invoiceNumber),
    grossAmount: hasTransactionLinked
      ? transactionMetadata
      : toFieldMetadata(amount),
    currency: hasTransactionLinked
      ? transactionMetadata
      : toFieldMetadata(currency),
    bookings: bookingsWithFallback.map(booking => {
      const suggestedTaxCodeId = getSuggestedTaxCodeIfMatched(
        booking.vatRate?.value,
        suggestions.taxCode?.value.id
      );

      return {
        vatRate: toFieldMetadata(booking.vatRate),
        note: toFieldMetadata(booking.note),
        dueDate: hasTransactionLinked
          ? transactionMetadata
          : toFieldMetadata(booking.dueDate),
        generalLedgerAccount: {
          value: toFieldMetadata(
            booking.generalLedgerAccount,
            suggestions.generalLedgerAccount
          ),
        },
        taxCode: suggestedTaxCodeId
          ? toFieldMetadata(booking.bookingKey, suggestions.taxCode)
          : undefined,
        costCenter: {
          value: toFieldMetadata(booking.costCenter, suggestions.costCenter),
        },
        costObject: {
          value: toFieldMetadata(booking.costObject, suggestions.costObject),
        },
        extraCostInfo: { value: toFieldMetadata(booking.extraCostInfo) },
      };
    }),
    iban: toFieldMetadata(iban),
    swiftCode: toFieldMetadata(swiftCode),
    createTransfer: toFieldMetadata(createTransfer),
    paymentCondition: toFieldMetadata(paymentCondition),
    discountDate: toFieldMetadata(discountDate),
    discountPercentage: toFieldMetadata(discountPercentage),
    discountAmount: toFieldMetadata(discountAmount),
    workflow:
      !workflowTemplate &&
      hasTransactionLinked &&
      transaction?.accountingData?.approvalWorkflowTemplateId
        ? toFieldMetadata(creditCardMetadata)
        : toFieldMetadata(null, suggestions.workflowTemplate),
    approvers:
      (workflowTemplate ?? suggestedWorkflowTemplate)?.steps?.map(() => ({
        approvers: toFieldMetadata(null, suggestions.workflowTemplate),
      })) ??
      combinedApprovers?.value?.map(() => ({
        approvers: toFieldMetadata(combinedApprovers),
      })) ??
      suggestions.approverUserIds?.value?.map(() => ({
        approvers: toFieldMetadata(null, suggestions.approverUserIds),
      })),
  };

  return {
    extractedContact: extractedContact
      ? toExtractedContactFieldItem(extractedContact)
      : undefined,
    defaultValues,
    defaultMetadata,
    isDocumentReadOnly: isDocumentReadOnly ?? true,
    loading:
      loadingDocument ||
      loadingOrganization ||
      loadingSmartFieldSuggestions ||
      loadingWorkflowTemplates,
    onContactChange: newContactId => {
      if (!newContactId) {
        return;
      }

      void refetchSmartFieldSuggestions({
        documentId,
        contactId: newContactId,
      });
    },
    globalDocumentId,
    tags,
  };
};
