import { useCreateCommentOnEcmDocument } from 'components/Comment/useCreateCommentOnECMDocument';
import { useCreateCommentOnInvoice } from 'components/Comment/useCreateCommentOnInvoice';
import { useCreateDocumentComment } from 'components/Comment/useCreateDocumentComment';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { CommentFieldDocumentContainer } from './CommentFieldDocumentContainer';

interface CommentContainerProps {
  /**
   * This is required to cover the use case when we're in transaction route
   * and transaction has a linked invoice to it, in which case `documentId`
   * is not present in URL params, and we need to pass `documentId` to
   * `createDocumentComment` mutation.
   */
  documentId: string;
  isEcmDocument?: boolean;
  restrictMentions?: boolean;
}

export const CommentContainer = ({
  documentId,
  isEcmDocument,
  restrictMentions = false,
}: CommentContainerProps) => {
  const [allowCommentsInDMSFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.allowCommentsInDMS,
  ]);

  const { onCreateComment: onCreateCommentDeprecated, isSubmitting } =
    useCreateDocumentComment({
      documentId,
    });

  const { onCreateCommentOnInvoice, isSubmitting: isSubmittingOnInvoice } =
    useCreateCommentOnInvoice({
      documentId,
    });

  const {
    onCreateCommentOnEcmDocument,
    isSubmitting: isSubmittingOnEcmDocument,
  } = useCreateCommentOnEcmDocument({
    documentId,
  });

  const isSubmittingComment =
    isSubmitting || isSubmittingOnInvoice || isSubmittingOnEcmDocument;

  const onCreateComment = allowCommentsInDMSFF
    ? isEcmDocument
      ? onCreateCommentOnEcmDocument
      : onCreateCommentOnInvoice
    : onCreateCommentDeprecated;

  return (
    <CommentFieldDocumentContainer
      isSubmitting={isSubmittingComment}
      onCreateComment={onCreateComment}
      restrictMentions={restrictMentions}
    />
  );
};
