import { useExportableEntitiesCounter } from 'hooks/useExportableEntitiesCounter';
import { useUserRoles } from 'hooks/useUserRoles';
import { NavigationIcon } from '../../NavigationIcon';

export const MenuExport = ({ ...iconProps }) => {
  const { isAdmin, isAccountant } = useUserRoles();

  const {
    readyToExportEntitiesCount,
    loading: loadingExportableEntitiesCount,
  } = useExportableEntitiesCounter({
    fetchPolicy: 'cache-first',
    skip: !(isAdmin || isAccountant),
  });

  return (
    <NavigationIcon
      icon="menuExport"
      count={readyToExportEntitiesCount}
      countLoading={loadingExportableEntitiesCount}
      {...iconProps}
    />
  );
};
