import {
  Link as LinkDS,
  LinkProps,
  Flex,
  Box,
  Text,
} from '@candisio/design-system';
import { colors } from '@candisio/design-system/src/Theme/themeValues';
import {
  IndicatorStatus,
  PulseIndicator,
} from 'components/PulseIndicator/PulseIndicator';
import { createClickHandler } from 'components/RouterLink/createClickHandler';
import { motion } from 'framer-motion';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { forwardRef, ReactNode, Ref } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom-v5-compat';

export interface NavLinkProps
  extends Pick<
      RouterNavLinkProps,
      'exact' | 'isActive' | 'location' | 'replace' | 'strict' | 'to'
    >,
    Omit<LinkProps, 'href' | 'ref'> {
  showIndicator?: boolean;
  requiresUpgrade?: boolean;
  source?: string;
  indicatorStatus?: IndicatorStatus;
  tag?: ReactNode;
  iconComponent?: ReactNode;
}

const MotionFlex = motion(Flex);

const motionFlexVariants = {
  initial: {
    background: 'none',
    color: colors.gray600,
    transition: { color: { duration: 0 } },
  },
  hover: {
    background: colors.bluebg,
    color: colors.blue600,
    transition: { color: { duration: 0 } },
  },
};

/**
 * React Router NavLink that uses the Link component from the Candis design
 * system
 * - Styled to indicate when linked page is the current page
 * - Supports all design system Link props except `href` (use `to` instead)
 * - Supports a subset of the React Router NavLink props: `exact`, 'isActive'
 *   'location', 'replace', 'strict' and 'to'
 *
 * @example <NavLink to="/the-past">A (Nav)Link to the Past</Link>
 */
export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (
    {
      showIndicator,
      indicatorStatus,
      source,
      children,
      tag,
      iconComponent,
      to: route,
      ...restProps
    }: NavLinkProps,
    ref
  ) => {
    const mainNavigationRefactorFF = useCandisFeatureFlags(
      FEATURE_FLAGS.mainNavigationRefactor
    );

    const isActive = location.pathname === route;

    return mainNavigationRefactorFF ? (
      <MotionFlex
        as={ReactRouterLink}
        alignItems="center"
        background="transparent"
        padding="space8"
        gap="space8"
        borderRadius="medium"
        variants={motionFlexVariants}
        initial={isActive ? 'hover' : 'initial'}
        animate={isActive ? 'hover' : 'initial'}
        whileHover="hover"
        whileFocus="hover"
        cursor="pointer"
        color="gray600"
        ref={ref}
        to={route}
        {...restProps}>
        <Text fontSize="small">{children}</Text>
        {showIndicator && (
          <Box bottom={0.5}>
            <PulseIndicator status={indicatorStatus} source={source} />
          </Box>
        )}
        {tag}
        {iconComponent}
      </MotionFlex>
    ) : (
      <Flex alignItems="center" gap="space2">
        <RouterNavLink
          component={NavLinkAdaptor}
          innerRef={ref}
          to={route}
          {...restProps}>
          {children}
        </RouterNavLink>
        {showIndicator && (
          <Box bottom={0.5}>
            <PulseIndicator status={indicatorStatus} source={source} />
          </Box>
        )}
      </Flex>
    );
  }
);

interface NavLinkAdaptorProps extends LinkProps {
  innerRef: Ref<HTMLAnchorElement>;
  navigate: () => void;
  requiresUpgrade?: boolean;
}

const NavLinkAdaptor = ({
  'aria-current': ariaCurrent,
  children,
  fontSize = 'small',
  innerRef,
  navigate,
  onClick,
  target,
  style,
  requiresUpgrade,
  ...restProps
}: NavLinkAdaptorProps) => {
  const selectedNavigationColor = ariaCurrent
    ? requiresUpgrade
      ? 'purple'
      : 'blue'
    : 'gray';

  return (
    <LinkDS
      aria-current={ariaCurrent}
      fontSize={fontSize}
      onClick={createClickHandler({ target, onClick, navigate })}
      target={target}
      color={selectedNavigationColor}
      {...restProps}
      ref={innerRef}>
      {children}
    </LinkDS>
  );
};
