import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Tag,
  Text,
} from '@candisio/design-system';
import { EcmDocumentsTable } from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { PromotionLink } from 'components/PromotionLink/PromotionLink';
import { UpsellPromo } from 'containers/Entitlements/components/Upsell/UpsellPromo';
import { thumbnails } from 'containers/Entitlements/components/Upsell/utils';
import { appointmentLinks } from 'containers/Entitlements/toolkit/utils';
import { Locale } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { noop } from 'lodash';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { ArchiveContractManagementEvents } from 'providers/AnalyticsProvider/events/ContractManagementEvents';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';
import { ArchiveViewLayout } from 'views/Archive/components/ArchiveViewLayout';
import { useGetEcmContractsTableConfigs } from 'views/Archive/EcmContracts/hooks/useGetEcmContractsTableConfigs';
import { TabView } from 'views/Inbox/models';
import { contractsData } from '../dummyData';
import { FeatureBenefit, PromotionCards } from './PromotionCard';
import { VideoCard } from './VideoCard';

export const ContractsTablePromo = () => {
  const [newSettingsPromotionsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.newSettingsPromotions,
  ]);

  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_ECM_CONTRACTS}>
      {newSettingsPromotionsFF ? <NewPromotions /> : <OldPromotions />}
    </ArchiveViewLayout>
  );
};

export const contractBenefits: FeatureBenefit[] = [
  {
    icon: 'creditCardOutline',
    heading: 'promo.contractTable.modal.benefit1.heading',
    description: 'promo.contractTable.modal.benefit1.description',
    isUpcoming: false,
  },
  {
    icon: 'bell',
    heading: 'promo.contractTable.modal.benefit2.heading',
    description: 'promo.contractTable.modal.benefit2.description',
    isUpcoming: false,
  },
  {
    icon: 'copy',
    heading: 'promo.contractTable.modal.benefit3.heading',
    description: 'promo.contractTable.modal.benefit3.description',
    isUpcoming: true,
  },
  {
    icon: 'linkOutlined',
    heading: 'promo.contractTable.modal.benefit4.heading',
    description: 'promo.contractTable.modal.benefit4.description',
    isUpcoming: false,
  },
] as const;

const { MODAL_DEMO_BTN_CLICKED, MORE_INFO_CLICKED, DEMO_BTN_CLICKED } =
  ArchiveContractManagementEvents;

const OldPromotions = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const thumbnail = thumbnails[FEATURE.CONTRACTS][locale];

  const { track } = useAnalytics();

  const trackModalDemoClick = useCallback(
    () => track(MODAL_DEMO_BTN_CLICKED),
    [track]
  );

  const trackDemoClick = useCallback(
    () => track(DEMO_BTN_CLICKED), // ignoring prettier
    [track]
  );

  const trackMoreInfoClick = useCallback(
    () => track(MORE_INFO_CLICKED),
    [track]
  );

  return (
    <Flex direction="column" gap="space16" paddingX="space12" maxWidth="1300px">
      <Tag color="purple" variant="secondary">
        {t('promo.tag')}
      </Tag>
      <Text as="h2" fontSize="xxlarge">
        <Trans t={t} i18nKey="promo.contractTable.title">
          <Text fontWeight="bold">Contract management</Text>
          Behalte den Überblick über laufende Verträge und Fristen
        </Trans>
      </Text>
      <VideoCard feature={FEATURE.CONTRACTS} withFading>
        <Image src={thumbnail} alt="" width="100%" opacity=".7" />
      </VideoCard>
      <Flex
        position="absolute"
        bottom="-4vmax"
        direction="column"
        alignItems="center"
        gap="space16"
        alignSelf="center"
        width="fit-content">
        <PromotionLink
          trackClick={trackDemoClick}
          href={t(appointmentLinks[FEATURE.CONTRACTS])}
          variant="calendar">
          {t('promo.modal.bookAppointment')}
        </PromotionLink>
        <UpsellPromo
          feature={FEATURE.CONTRACTS}
          CustomPromoMaterial={
            <PromotionCards
              benefits={contractBenefits}
              feature={FEATURE.CONTRACTS}
            />
          }
          trackMoreInfo={trackMoreInfoClick}
          trackDemo={trackModalDemoClick}
          hidePackages>
          <Button variant="tertiary" color="promo" width="100%">
            {t('promo.contractTable.moreInfoCTA')}
          </Button>
        </UpsellPromo>
      </Flex>
    </Flex>
  );
};

const OverlayGrid = styled(Grid)`
  height: 100%;
  align-items: center;
  mask-image: linear-gradient(to top, transparent 10%, #000000 25%);

  & > * {
    grid-row: 1;
    grid-column: 1;
  }
`;

const tableData = Array(10)
  .fill(0)
  .flatMap((_, i) =>
    contractsData.map((el, j) => ({ ...el, id: i + i + j + '' }))
  ) as EcmDocumentsTableData[];

const NewPromotions = () => {
  const { contractManagementPromotion } = usePromotionContent();
  const { availableDocumentColumnIds, configurationsTable } =
    useGetEcmContractsTableConfigs({
      filters: [],
      sortBy: [],
    });

  return (
    <OverlayGrid height="100%">
      <Box
        height="100%"
        style={{
          maskImage: 'linear-gradient(to top, transparent 10%, #000000 25%)',
        }}>
        <EcmDocumentsTable
          context="contracts"
          columns={availableDocumentColumnIds}
          configurationsTable={configurationsTable}
          data={tableData}
          selectedDocumentsCount={0}
          onSearchChange={noop}
          onRowClick={noop}
          onFilter={noop}
          onEndReached={noop}
          onUpdateConfigurations={noop}
          onSort={noop}
          onResetTableConfigurations={noop}
        />
      </Box>
      <Box inset="0" height="100%" />
      <Box justifySelf="center" width="50rem" height="28rem">
        <PromotionsProvider promotions={[contractManagementPromotion]}>
          <PromotionCard
            promotion={contractManagementPromotion}
            closable={false}
          />
        </PromotionsProvider>
      </Box>
    </OverlayGrid>
  );
};
