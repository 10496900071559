import { Button, Card, Grid, Text } from '@candisio/design-system';
import { Locale } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ApproveNowAction } from './ApproveNowAction';
import { RequestApprovalAction } from './RequestApprovalAction';
import {
  MIN_COLUMN_WIDTH,
  MIN_COLUMN_WIDTH_OTHER,
} from './ReviewStatusFooterActionBox';

interface ReviewStatusFooterActionBoxProps {
  createdById?: string;
}

type FooterMode = 'requestApprovalAgain' | 'editMode';
const mainKey = 'reimbursementView.rightSection.reviewfooterAction';

export const ApprovedStatusFooterActionBox = ({
  createdById,
}: ReviewStatusFooterActionBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [mode, setMode] = useState<FooterMode>('requestApprovalAgain');
  const { setReimbursementUIConfig } = useReimbursementFormsContext();
  const { id: currentUserId, locale } = useCurrentUser() ?? {};
  const { isAccountant, isAdmin, isOnlyRequester, isOnlyApprover } =
    useUserRoles();

  const canFastApprove = isAdmin || isAccountant;
  const canRequestApproval = canFastApprove || isOnlyRequester;

  const isReimbursementOwner = createdById === (currentUserId ?? '');

  if (isOnlyApprover) return null;

  const handleClickRequestApprovalAgain = () => {
    setMode('editMode');
    setReimbursementUIConfig(prevConfig => ({
      ...prevConfig,
      isReimbursementFormEditable: true,
    }));
  };

  const backTextKey = isReimbursementOwner
    ? `${mainKey}.backMain`
    : `${mainKey}.back`;

  const minColumnWidth =
    isReimbursementOwner || locale === Locale.En
      ? MIN_COLUMN_WIDTH
      : MIN_COLUMN_WIDTH_OTHER;

  return (
    <Card corners="top" boxShadow="elevatedShadow3" padding="space20">
      {mode === 'requestApprovalAgain' && (
        <Button
          variant="tertiary"
          width="100%"
          onClick={handleClickRequestApprovalAgain}>
          {t('reimbursementView.rightSection.approvedFooterAction.request')}
        </Button>
      )}
      {mode === 'editMode' && (
        <Grid gap="space8">
          <Text color="gray500" fontWeight="semibold" textTransform="uppercase">
            {t('reimbursementView.rightSection.footerActions.title')}
          </Text>
          <Grid gap="space8">
            {canRequestApproval && <RequestApprovalAction />}
            <Grid
              gap="space8"
              templateColumns={`repeat(auto-fit, minmax(${minColumnWidth}px, 1fr))`}>
              {canFastApprove && <ApproveNowAction />}
              <Button color="blue" variant="secondary">
                {t(backTextKey)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};
