import {
  Box,
  Card,
  Grid,
  Link,
  Modal,
  Text,
  useModal,
} from '@candisio/design-system';
import { PurchaseOrderListField } from 'components/PurchaseOrders/SelectPurchaseOrderContainer/PurchaseOrderField/PurchaseOrderListField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { usePurchaseOrderFieldOptions } from '../hooks/usePurchaseOrderNumberFieldOptions';
import { PurchaseOrderList } from './PurchaseOrderList';

export interface SelectPurchaseOrderContainerProps {
  documentId: string;
  documentAmount?: number | null;
  contactName?: string;
  accountsPayableNumber?: string | null;
}
export const SelectPurchaseOrderContainer = ({
  documentId,
  documentAmount,
  contactName,
  accountsPayableNumber,
}: SelectPurchaseOrderContainerProps) => {
  const { triggerProps, modalProps, isOpen, close } = useModal();
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  const fieldOptions = usePurchaseOrderFieldOptions({
    documentId,
    accountsPayableNumber,
    documentAmount,
  });

  const titleText = contactName
    ? t('addPurchaseOrderModal.headerWithContactName', { contactName })
    : t('addPurchaseOrderModal.header');

  return (
    <>
      <Card background="gray0">
        <Grid gap="space16">
          <Box
            width="fit-content"
            background="gray250"
            padding="space4 space10"
            borderRadius="small">
            <Text fontSize="basic" fontWeight="semibold">
              {t('addPurchaseOrderModal.title')}
            </Text>
          </Box>
          <Box>
            <PurchaseOrderListField
              contactName={contactName}
              fieldOptions={fieldOptions}
            />
            <Link
              as="button"
              fontSize="small"
              {...triggerProps}
              position="absolute"
              top="0"
              right="0"
              style={{ lineHeight: 'normal', outline: 'none' }}>
              {t('addPurchaseOrderModal.viewAllButton')}
            </Link>
          </Box>
        </Grid>
      </Card>

      {isOpen && (
        <Modal
          {...modalProps}
          isOpen={isOpen}
          background="gray200"
          padding={0}
          minHeight="70vh"
          minWidth="80vw"
          shouldCloseOnBlur={true}
          closeLabel={t('addPurchaseOrderModal.close')}
          title={titleText}>
          <PurchaseOrderList
            documentId={documentId}
            documentAmount={documentAmount}
            accountsPayableNumber={accountsPayableNumber}
            closeModal={close}
            contactName={contactName}
          />
        </Modal>
      )}
    </>
  );
};
