import {
  ReimbursementCaseStatus,
  useReimbursementCaseByIdQuery,
} from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useReimbursement } from 'orgConfig/reimbursement/useReimbursement';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { ReimbursementRouteParams } from './useReimbursementNavigation';

export interface UIConfigProps {
  isExpenseFormEditable: boolean;
  isReimbursementFormEditable: boolean;
  canManageReimbursementItems: boolean;
  showFooterInfoBox: boolean;
}

export const defaultUIConfig: UIConfigProps = {
  isExpenseFormEditable: false,
  isReimbursementFormEditable: false,
  canManageReimbursementItems: false,
  showFooterInfoBox: false,
};

interface UIConfigMapParams {
  isOwner: boolean;
  canManageReimbursements: boolean;
}

// defines UI config for reimbursement statuses based on ownership and management roles.
export const reimbursementUIConfigMap: Record<
  ReimbursementCaseStatus,
  (params: UIConfigMapParams) => UIConfigProps
> = {
  DRAFT: ({ isOwner }) => ({
    isReimbursementFormEditable: isOwner,
    isExpenseFormEditable: isOwner,
    canManageReimbursementItems: isOwner,
    showFooterInfoBox: !isOwner,
  }),
  REVIEW: ({ canManageReimbursements }) => ({
    isReimbursementFormEditable: canManageReimbursements,
    isExpenseFormEditable: canManageReimbursements,
    canManageReimbursementItems: false,
    showFooterInfoBox: !canManageReimbursements,
  }),
  CHECK: ({ isOwner }) => ({
    isReimbursementFormEditable: isOwner,
    isExpenseFormEditable: isOwner,
    canManageReimbursementItems: isOwner,
    showFooterInfoBox: !isOwner,
  }),
  REJECTED: ({ isOwner }) => ({
    isReimbursementFormEditable: isOwner,
    isExpenseFormEditable: isOwner,
    canManageReimbursementItems: isOwner,
    showFooterInfoBox: !isOwner,
  }),
  APPROVED: () => defaultUIConfig,
  APPROVING: () => defaultUIConfig,
  ARCHIVED: () => defaultUIConfig,
  EXPORTED: () => defaultUIConfig,
};

export const useReimbursementUIConfig = () => {
  const { reimbursementId } = useParams<ReimbursementRouteParams>();
  const { canUseReimbursement } = useReimbursement();
  const currentUserId = useCurrentUser()?.id ?? '';
  const { isAccountant, isAdmin, isOnlyRequester } = useUserRoles();

  const { data, loading } = useReimbursementCaseByIdQuery({
    variables: { id: reimbursementId ?? '' },
    skip: !reimbursementId || !canUseReimbursement,
  });

  const { reimbursementCaseById } = data ?? {};
  const { createdByMembership, status } = reimbursementCaseById ?? {};

  const isOwner = createdByMembership?.id === currentUserId;

  const canManageReimbursements = isAdmin || isAccountant || isOnlyRequester;

  const uiConfig = useMemo(() => {
    if (loading || !status) {
      return defaultUIConfig;
    }

    const config = reimbursementUIConfigMap[status];

    return config({ isOwner, canManageReimbursements });
  }, [loading, status, isOwner, canManageReimbursements]);

  return uiConfig;
};
