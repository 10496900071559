export const availabeFiltersAndSorts = [
  'approver',
  'createdAt',
  'grossAmount',
  'isPaid',
  'paidAt',
  'requesterOfTheReimbursement',
  'status',
  'title',
];
