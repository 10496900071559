import { Button, Card, Grid, Text } from '@candisio/design-system';
import { Locale } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ApproveNowAction } from './ApproveNowAction';
import { RequestApprovalAction } from './RequestApprovalAction';

interface ReviewStatusFooterActionBoxProps {
  createdById?: string;
}

export const MIN_COLUMN_WIDTH = 151;
export const MIN_COLUMN_WIDTH_OTHER = 191;
const mainKey = 'reimbursementView.rightSection.reviewfooterAction';

export const ReviewStatusFooterActionBox = ({
  createdById,
}: ReviewStatusFooterActionBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { id: currentUserId, locale } = useCurrentUser() ?? {};
  const { isAccountant, isAdmin, isOnlyRequester, isOnlyApprover } =
    useUserRoles();

  const isReimbursementOwner = createdById === (currentUserId ?? '');
  const canFastApprove = isAdmin || isAccountant;
  const canRequestApproval = canFastApprove || isOnlyRequester;

  if (isOnlyApprover) return null;

  const backTextKey = isReimbursementOwner
    ? `${mainKey}.backMain`
    : `${mainKey}.back`;

  const minColumnWidth =
    isReimbursementOwner || locale === Locale.En
      ? MIN_COLUMN_WIDTH
      : MIN_COLUMN_WIDTH_OTHER;

  return (
    <Card corners="top" boxShadow="elevatedShadow3" padding="space20">
      <Grid gap="space8">
        <Text color="gray500" fontWeight="semibold" textTransform="uppercase">
          {t('reimbursementView.rightSection.footerActions.title')}
        </Text>
        <Grid gap="space8">
          {canRequestApproval && <RequestApprovalAction />}
          <Grid
            gap="space8"
            templateColumns={`repeat(auto-fit, minmax(${minColumnWidth}px, 1fr))`}>
            {canFastApprove && <ApproveNowAction />}
            <Button color="blue" variant="secondary">
              {t(backTextKey)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
