import {
  AriaTabListProps,
  Popover,
  TabItem,
  TabPanel,
  Tabs,
  usePopover,
  useTabListState,
  Item,
  Flex,
} from '@candisio/design-system';
import { colors } from '@candisio/design-system/src/Theme/themeValues';
import { PulseIndicator } from 'components/PulseIndicator/PulseIndicator';
import { useInAppNotificationsPageBasedData } from 'containers/notifications/hooks/useInAppNotificationsPageBasedData';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components';
import { DocumentUploadModal } from 'views/AppContainer/components/Header/components/DocumentUploadModal/DocumentUploadModal';
import { IconLink } from '../../IconLink/IconLink';
import { NotificationsPopoverContent } from './NotificationsPopoverContent/NotificationsPopoverContent';

enum NotificationTabsKeys {
  ALL_COMPANIES = 'ALL_COMPANIES',
  CURRENT_COMPANIES = 'CURRENT_COMPANIES',
}

interface NotificationTabsProps extends AriaTabListProps<TabItem> {
  'aria-label': string;
}

// TODO: https://candis.atlassian.net/browse/TMI-1117 Add new variant for transparent background to DS
const StyledTabs = styled.div`
  [data-key='CURRENT_COMPANIES'] {
    background: transparent !important;
  }

  [data-key='ALL_COMPANIES'] {
    background: transparent !important;
  }
`;

const NotificationTabsPanel = (props: NotificationTabsProps) => {
  const state = useTabListState(props);

  return (
    <Flex direction="column" height="100%">
      <StyledTabs>
        <Tabs
          {...props}
          state={state}
          style={{
            padding: '0 16px',
            borderBottom: `1px solid ${colors.gray100}`,
          }}
        />
      </StyledTabs>
      <TabPanel
        key={state.selectedItem?.key}
        state={state}
        height="100%"
        flexGrow={1}
        overflow="hidden"
      />
    </Flex>
  );
};

export const MenuNotifications = ({ ...notificationIconProps }) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const [isActive, setIsActive] = useState(false);

  const { notificationDetails } = useInAppNotificationsPageBasedData({
    organizationId: undefined,
    showOnlyUnread: true,
  });

  const { unreadNotifications } = notificationDetails;

  const { popoverProps, popoverRef, triggerProps, triggerRef, isOpen, close } =
    usePopover({
      placement: 'right',
      onOpenChange: isOpen => {
        setIsActive(isOpen);
      },
    });

  const tabItems: TabItem[] = [
    {
      children: (
        <NotificationsPopoverContent onClose={close} currentCompanyOnly />
      ),
      key: NotificationTabsKeys.CURRENT_COMPANIES,
      title: t('notifications.tabs.currentCompany'),
    },
    {
      children: <NotificationsPopoverContent onClose={close} />,
      key: NotificationTabsKeys.ALL_COMPANIES,
      title: t('notifications.tabs.allCompanies'),
    },
  ];

  const { showMenuIcon, route, ...restNotificationIconProps } =
    notificationIconProps;

  if (!showMenuIcon) return null;

  return (
    <>
      <IconLink
        icon="menuNotifications"
        ref={triggerRef}
        as="button"
        {...restNotificationIconProps}
        {...triggerProps}
        isActive={isActive}
        count={
          unreadNotifications.length > 0
            ? unreadNotifications.length
            : undefined
        }
        pulseIndicator={
          unreadNotifications.length > 0 ? (
            <PulseIndicator status="default" source="menuSettings" />
          ) : undefined
        }
        showLabelInTooltip={!isOpen}
      />

      {isOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          width="467px"
          height="60%"
          padding={0}
          shouldCloseOnBlur={false}>
          <NotificationTabsPanel
            aria-label="notificationTabs"
            defaultSelectedKey={NotificationTabsKeys.CURRENT_COMPANIES}
            items={tabItems}
            children={item => (
              <Item key={item.key} title={item.title} textValue={item.title}>
                {item.children}
              </Item>
            )}
          />
        </Popover>
      )}
      <DocumentUploadModal />
    </>
  );
};
