import {
  useCountDocumentsProcessing,
  useCountTransactionsAndDocumentsInbox,
} from 'hooks/useCounters';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { NavigationIcon } from '../../NavigationIcon';

export const MenuInbox = ({ ...iconProps }) => {
  const creditCardsSetup = useCreditCardsSetup();

  const { count: documentsProcessingCount, loading: loadingProcessing } =
    useCountDocumentsProcessing({
      fetchPolicy: 'cache-first',
    });

  const { totalCount: inboxTotalCount, loading: loadingInbox } =
    useCountTransactionsAndDocumentsInbox({
      processingDocuments: documentsProcessingCount,
      loadingProcessing,
      featureFlag: creditCardsSetup.isAddonBought,
    });

  const countLoading = loadingProcessing || loadingInbox;

  return (
    <NavigationIcon
      icon="menuInbox"
      count={inboxTotalCount}
      countLoading={countLoading}
      {...iconProps}
    />
  );
};
