import { Grid, Popover, usePopover } from '@candisio/design-system';
import { Fragment } from 'react';
import { NavIconProps } from './hooks/useNavigationIcons';
import { MenuApprovals } from './MenuItems/MenuApprovals/MenuApprovals';
import { MenuExport } from './MenuItems/MenuExport/MenuExport';
import { MenuInbox } from './MenuItems/MenuInbox/MenuInbox';
import { MenuPayments } from './MenuItems/MenuPayments/MenuPayments';
import { MenuTravel } from './MenuItems/MenuTravel/MenuTravel';
import { MenuUploadDocument } from './MenuItems/MenuUploadDocument/MenuUploadDocument';
import { NavigationIcon } from './NavigationIcon';

// const Divider = ({ isNavigationOpen }: { isNavigationOpen: boolean }) => (
//   <Grid padding="space14 0">
//     <Box
//       width={isNavigationOpen ? '100%' : '44px'}
//       height={1}
//       background="gray250"
//     />
//   </Grid>
// );

// const DIVIDER_POSITIONS = [0, 4, 7];

interface NavigationIconsProps {
  hiddenIcons: NavIconProps[];
  icons: NavIconProps[];
  isNavigationOpen: boolean;
}

const iconComponentsMap: Partial<
  Record<NavIconProps['icon'], (props: NavIconProps) => JSX.Element | null>
> = {
  menuInbox: MenuInbox,
  menuApprovals: MenuApprovals,
  menuPayments: MenuPayments,
  menuExport: MenuExport,
  menuTravel: MenuTravel,
  menuUpload: MenuUploadDocument,
};

const renderIconComponent = (
  icon: NavIconProps,
  isNavigationOpen: boolean,
  onClick?: () => void
) => {
  const IconComponent = iconComponentsMap[icon.icon] || NavigationIcon;

  return (
    <IconComponent {...icon} expanded={isNavigationOpen} onClick={onClick} />
  );
};

export const NavigationIcons = ({
  hiddenIcons,
  icons,
  isNavigationOpen,
}: NavigationIconsProps) => {
  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'right' });

  return (
    <Grid paddingLeft="space8" gap="space8">
      {icons.map((icon, index) => (
        <Fragment key={`${icon.icon}-${index}`}>
          {renderIconComponent(icon, isNavigationOpen)}
          {/* {DIVIDER_POSITIONS.includes(index) && (
            <Divider isNavigationOpen={isNavigationOpen} />
          )} */}
        </Fragment>
      ))}

      {hiddenIcons.length > 0 && (
        <>
          <NavigationIcon
            icon="meatballs"
            {...triggerProps}
            ref={triggerRef}
            expanded={isNavigationOpen}
            aria-label="overflowedMenuButton"
          />
          {isOpen && (
            <Popover {...popoverProps} ref={popoverRef} autoFocus={false}>
              {hiddenIcons.map((icon, index) => (
                <Grid gap="space8" key={index}>
                  {renderIconComponent(icon, true, close)}
                </Grid>
              ))}
            </Popover>
          )}
        </>
      )}
    </Grid>
  );
};
