import { Button, Color, Flex, Grid, Tag, Text } from '@candisio/design-system';
import { AnimatePresence, motion } from 'framer-motion';

type NewsCardProps = {
  imageUrl: string;
  imageAlt: string;
  tag: string;
  tagColor?: Color;
  title: string;
  description?: string;
  buttonText: string;
  onButtonClick: () => void;
  backgroundColor: string;
};

const MotionFlex = motion(Flex);

const animationVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const NewsCard = ({
  imageUrl,
  imageAlt,
  tag,
  tagColor = 'gray',
  title,
  description,
  buttonText,
  onButtonClick,
  backgroundColor,
}: NewsCardProps) => {
  return (
    <Flex
      width="100%"
      height="100%"
      background="gray0"
      boxShadow="elevatedShadow4"
      borderRadius="medium"
      overflow="hidden">
      <Grid
        height="100%"
        width="50%"
        flexGrow="1"
        alignContent="center"
        background={backgroundColor}
        style={{ isolation: 'isolate' }}>
        <AnimatePresence>
          <motion.img
            key={imageUrl}
            src={imageUrl}
            alt={imageAlt}
            height="100%"
            width="100%"
            style={{ objectFit: 'cover', gridColumn: 1, gridRow: 1 }}
            variants={animationVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.75 }}
            draggable={false}
          />
        </AnimatePresence>
      </Grid>
      <MotionFlex
        direction="column"
        height="100%"
        width="60%"
        flexGrow="0"
        padding="space24"
        gap="space4"
        variants={animationVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.75 }}>
        <Tag color={tagColor} variant="primary">
          {tag}
        </Tag>
        <Flex direction="column" height="100%" justifyContent="space-between">
          <Flex direction="column">
            <Text fontSize="medium" color="gray800">
              {title}
            </Text>
            <Text fontSize="basic" color="gray500">
              {description}
            </Text>
          </Flex>
          <Button
            width="fit-content"
            color="gray"
            iconPosition="right"
            icon="arrowRight"
            variant="secondary"
            style={{ marginTop: '10px' }}
            onClick={onButtonClick}>
            {buttonText}
          </Button>
        </Flex>
      </MotionFlex>
    </Flex>
  );
};
