import {
  Button,
  Flex,
  Grid,
  Heading,
  Box,
  FilterOptionsAccessor,
  CustomEmptyStateProps,
} from '@candisio/design-system';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { queryParameter } from 'components/Table/consts';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { OnLoadMore } from 'providers/GraphQLProvider/Pagination/usePagination';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { Filters, SortingRule } from 'react-table';
import { ReimbursementsTable } from 'views/Reimbursement/elements/Table/ReimbursementsTable';
import { ReimbursementsTableEmptyState } from 'views/Reimbursement/elements/Table/ReimbursementsTableEmptyState';
import { REIMBURSEMENT_VIEW_ROUTES } from 'views/Reimbursement/hooks/useReimbursementNavigation';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/Reimbursement';
import {
  ReimbursementTableColumnKeys,
  ReimbursementTableRow,
} from 'views/Reimbursement/toolkit/types';
import { InboxViewContainer } from '../components/InboxViewContainer';
import { TabView } from '../models';

interface InboxReimbursementsProps {
  reimbursementsCount: number;
  reimbursements: ReimbursementTableRow[];
  filters: Filters<ReimbursementTableRow>;
  sortBy: SortingRule<ReimbursementTableRow>[];
  isLoadingReimbursements?: boolean;
  isLoadingConfigurations?: boolean;
  visibleColumnIds?: ReimbursementTableColumnKeys[];
  filterOptions?: FilterOptionsAccessor<ReimbursementTableRow>;
  isTableEmpty: boolean;
  isTableFiltered: boolean;
  configurations: Configuration[];
  onFilter: (filters: Filters<ReimbursementTableRow>) => void;
  onSort: (sortBy: SortingRule<ReimbursementTableRow>[]) => void;
  onDebounceSearch: (search: string) => void;
  onResetTableConfigurations: () => void;
  onUpdateConfigurations: (newConfigurations: Configuration[]) => void;
  onLoadMore: OnLoadMore;
}

export const InboxReimbursements = ({
  reimbursementsCount,
  reimbursements,
  filters,
  sortBy,
  isLoadingReimbursements,
  isLoadingConfigurations,
  filterOptions,
  configurations,
  visibleColumnIds,
  isTableEmpty,
  isTableFiltered,
  onDebounceSearch,
  onFilter,
  onSort,
  onLoadMore,
  onResetTableConfigurations,
  onUpdateConfigurations,
}: InboxReimbursementsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam, searchParams } = useMutateSearchParams();
  const queryStringFilter = searchParams.get(queryParameter) ?? '';
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();

  const handleSearch = (search: string) => {
    onDebounceSearch(search);
    updateSearchParam(queryParameter, search);
  };

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <ReimbursementsTableEmptyState
        isTableFiltered={isTableFiltered}
        resetFilters={resetFilters}
      />
    ) : null;

  const handleRowClick = (id: string, cursor?: string) => {
    if (!cursor) return;

    searchParams.set(REIMBURSEMENT_URL_PARAM.CURSOR, cursor);
    const pathname = generatePath(REIMBURSEMENT_VIEW_ROUTES[Routes.INBOX], {
      organizationSlug,
      reimbursementId: id,
    });

    navigate({
      pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <Grid templateRows="auto 1fr" overflow="hidden" height="100%">
      <Flex
        alignItems="center"
        gap="space16"
        paddingRight="space32"
        paddingLeft="space32"
        paddingTop="space40"
        paddingBottom="space16">
        <Heading as="h1">{t('inbox.heading')}</Heading>
        {reimbursementsCount > 0 && (
          <Button size="small" variant="primary">
            {t('inbox.startWorkkCTA')}
          </Button>
        )}
      </Flex>
      <InboxViewContainer activeTab={TabView.INBOX_REIMBURSEMENTS}>
        <Box
          height="100%"
          overflow="hidden"
          paddingRight="space32"
          paddingLeft="space32">
          <ReimbursementsTable
            data={reimbursements}
            visibleColumns={visibleColumnIds}
            initialFilters={filters}
            initialSortBy={sortBy}
            isLoading={isLoadingReimbursements}
            filterOptions={filterOptions}
            configurations={configurations}
            search={queryStringFilter}
            isLoadingConfigurations={isLoadingConfigurations}
            onEndReached={onLoadMore}
            onFilter={onFilter}
            onRowClick={handleRowClick}
            onResetTableConfigurations={onResetTableConfigurations}
            onSearch={handleSearch}
            onSort={onSort}
            onUpdateTableConfigurations={onUpdateConfigurations}
            customEmptyState={customEmptyState}
          />
        </Box>
      </InboxViewContainer>
    </Grid>
  );
};
