import {
  Button,
  Grid,
  Paragraph,
  Popover,
  Text,
  usePopover,
  useTheme,
} from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { useDisconnectAllPurchaseOrdersFromDocumentMutation } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

import { useThreeWayMatchContext } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchContext';
import {
  documentQueries,
  getSapPurchaseOrdersQuery,
} from 'views/Inbox/DocumentProcessing/queries';
import { documentHistoryQuery } from 'views/queries';
import { AcceptedDocumentStatus, popoverDescription } from './utils';

interface PurchaseOrderUnlinkContextMenuProps {
  disabled?: boolean;
}

export const PurchaseOrderUnlinkContextMenu = ({
  disabled = false,
}: PurchaseOrderUnlinkContextMenuProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);
  const { success, error } = useToastMessage();
  const { isOnlyApprover } = useUserRoles();
  const { documentStatus, documentId } = useThreeWayMatchContext();
  const { close, isOpen, popoverProps, popoverRef, triggerProps, triggerRef } =
    usePopover({ placement: 'bottom' });

  const { colors } = useTheme();

  const description = isOnlyApprover
    ? 'purchaseOrderCardContextMenu.approver.description'
    : popoverDescription[documentStatus as AcceptedDocumentStatus];

  const [unlinkAllPurchaseOrdersFromDocument, { loading: isUnlinkLoading }] =
    useDisconnectAllPurchaseOrdersFromDocumentMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: documentQueries.forDraftForm, variables: { id: documentId } },
        { query: getSapPurchaseOrdersQuery, variables: { id: documentId } },
        { query: documentHistoryQuery, variables: { id: documentId } },
      ],
      onCompleted: () => {
        close();
        success(t('actions.unlinkingSuccess'));
      },
      onError: () => {
        error(t('actions.unlinkingError'));
      },
    });

  const unlinkingDisabled = isUnlinkLoading || disabled || isOnlyApprover;

  return (
    <>
      <Button
        icon="unlinkOutlined"
        variant="tertiary"
        label={
          unlinkingDisabled
            ? t('threeWayMatch.indicator.disabledUnlinkingTooltip')
            : t('purchaseOrderCardContextMenu.tooltip')
        }
        size="xsmall"
        style={{
          backgroundColor: colors.gray250,
        }}
        disabled={unlinkingDisabled}
        {...triggerProps}
        ref={triggerRef}
        name={t('purchaseOrderCardContextMenu.tooltip')}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
        }}
      />
      {!unlinkingDisabled && isOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          width="365px"
          padding="space14">
          <Grid gap="space16">
            <Grid gap="space8">
              <Text fontWeight="bold">
                {t('purchaseOrderCardContextMenu.title')}
              </Text>
              <Paragraph>{t(description)}</Paragraph>
            </Grid>
            <Grid autoFlow="column" gap="space16" justifyContent="end">
              <Button
                variant="secondary"
                onClick={close}
                disabled={isUnlinkLoading}
                name={t('purchaseOrderCardContextMenu.cancelCTALabel')}>
                {t('purchaseOrderCardContextMenu.cancelCTALabel')}
              </Button>
              <Button
                color="error"
                loading={isUnlinkLoading}
                onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  if (!disabled && documentId) {
                    await unlinkAllPurchaseOrdersFromDocument({
                      variables: {
                        documentId,
                      },
                    });
                  }
                }}
                name={t('purchaseOrderCardContextMenu.confirmCTALabel')}>
                {t('purchaseOrderCardContextMenu.confirmCTALabel')}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};

// TODO: probably need to update the description and tooltip since now we support multiple purchase orders
