import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import { NavigationTableParams } from 'components/DocumentsTable/types';
import { queryParameter } from 'components/Table/consts';
import { DocumentPreviewDrawer } from 'containers/DocumentPreview/DocumentPreviewDrawer';
import { DocumentTableRowActionsOverlay } from 'containers/DocumentPreview/DocumentTableRowActionsOverlay';
import DocumentPreviewUpsellDrawer from 'containers/DocumentPreviewUpsell/DocumentPreviewUpsellDrawer';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider/feature-flag-names';
import { useOrganizationId } from 'providers/OrganizationProvider/useOrganizationId';
import { useCallback, useMemo, useState } from 'react';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';
import { appendParamsToQueryString } from 'utils/url-helper';
import { TabView } from 'views/Inbox/models';
import {
  ArchiveDocumentsTable,
  ArchiveDocumentsTableDSProps,
} from '../ArchiveDocumentsTable/ArchiveDocumentsTable';
import { useArchiveDocumentsData } from '../ArchiveDocumentsTable/hooks/useArchiveDocumentsData';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';

type Props = RouteComponentProps<{ organizationSlug: string }>;

export const MAX_DOCUMENTS_FOR_PDF_ZIP_DOWNLOAD = 1000;
export const MIN_DOCUMENTS_FOR_PDF_ZIP_DOWNLOAD = 1;

export const ArchiveDocuments = ({
  match: {
    params: { organizationSlug },
  },
}: Props) => {
  const { searchParams, updateSearchParam } = useMutateSearchParams();
  const [tableRowLinkFF] = useCandisFeatureFlags([FEATURE_FLAGS.tableRowLink]);

  const { showDocumentPreview, showDocumentPreviewPromo } = useEcm();
  const showPreview = showDocumentPreview || showDocumentPreviewPromo;

  const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(
    null
  );

  const searchQuery = searchParams.get(queryParameter) ?? '';
  const isUsingSearchQuery = Boolean(searchQuery);

  const { search } = useLocation();
  const history = useHistory();

  const orgId = useOrganizationId();

  const {
    documentsTableData,
    handleDebounceSearch,
    hasMoreData,
    loading,
    onLoadMore,
    selectedDocumentsCount,
  } = useArchiveDocumentsData({
    isUsingSearchQuery,
    previewDocumentId,
  });

  const documentPreviewRowOverlay: ArchiveDocumentsTableDSProps['rowOverlay'] =
    useMemo(
      () =>
        showPreview
          ? ({ data }) => {
              if (data.selected) return <></>; // TODO: Fix DS type to allow to return null

              return (
                <DocumentTableRowActionsOverlay
                  data={data}
                  onQuickViewClick={data => {
                    setPreviewDocumentId(data.id);
                  }}
                  isSelected={data.id === previewDocumentId}
                />
              );
            }
          : undefined,
      [showPreview, previewDocumentId]
    );

  const cursor =
    documentsTableData.find(d => d.id === previewDocumentId)?.cursor ?? null;

  const openDocument = useCallback(
    ({
      documentId,
      cursor,
    }: {
      documentId: string | null;
      cursor: string | null;
    }): void => {
      if (!documentId) return;

      const params = new URLSearchParams();
      if (cursor) params.set('cursor', cursor);

      const path = `/${organizationSlug}${Routes.ARCHIVE}/${documentId}?${params}`;

      window.open(path, '_blank');
    },
    [organizationSlug]
  );

  const documentPreviewDrawer = useMemo(() => {
    if (showDocumentPreviewPromo) {
      return (
        <DocumentPreviewUpsellDrawer
          documentId={previewDocumentId}
          closePreviewDrawer={() => setPreviewDocumentId(null)}
        />
      );
    }

    if (showDocumentPreview) {
      return (
        <DocumentPreviewDrawer
          documentId={previewDocumentId}
          closePreviewDrawer={() => setPreviewDocumentId(null)}
          openDocument={() =>
            openDocument({ documentId: previewDocumentId, cursor })
          }
        />
      );
    }

    return null;
  }, [
    showDocumentPreviewPromo,
    showDocumentPreview,
    previewDocumentId,
    openDocument,
    cursor,
  ]);

  const getPath = useCallback(
    ({ id, cursor }: NavigationTableParams) => {
      const searchWithCursor = appendParamsToQueryString(search, {
        cursor: cursor ?? '',
      });

      return `/${orgId}${Routes.ARCHIVE}/${id}?${searchWithCursor}`;
    },
    [orgId, search]
  );

  const onClick = useCallback(
    ({ id, cursor }: NavigationTableParams) => {
      if (tableRowLinkFF) {
        return;
      }

      const path = getPath({ id, cursor });
      history.push(path);
    },
    [getPath, history, tableRowLinkFF]
  );

  const cellWrapper = useMemo(
    () => (tableRowLinkFF ? getCellWrapperLink(getPath) : undefined),
    [tableRowLinkFF, getPath]
  );

  const cellStyle = tableRowLinkFF
    ? () => ({
        padding: 'unset',
      })
    : undefined;

  return (
    <ArchiveViewLayout
      activeTab={TabView.ARCHIVE_DOCUMENTS}
      rightSide={documentPreviewDrawer}>
      <ArchiveDocumentsTable
        data={documentsTableData}
        isLoading={loading}
        hasMoreData={hasMoreData}
        selectedDocumentsCount={selectedDocumentsCount}
        organizationSlug={organizationSlug}
        isUsingSearchQuery={isUsingSearchQuery}
        searchFieldValue={searchQuery}
        searchFieldOnChange={query => {
          handleDebounceSearch(query);
          updateSearchParam(queryParameter, query);
        }}
        onEndReached={onLoadMore}
        onClick={onClick}
        rowOverlay={documentPreviewRowOverlay}
        cellWrapper={cellWrapper}
        getCellStyles={cellStyle}
      />
    </ArchiveViewLayout>
  );
};
