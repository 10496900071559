import {
  Card,
  Grid,
  ScrollBox,
  Tag,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { capitalize } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useReimbursementTags } from 'views/Reimbursement/hooks/useReimbursementTags';

interface ReimbursementDetailsCardProps {
  title?: string;
  totalAmount?: string;
  status: ReimbursementCaseStatus;
}

export const ReimbursementDetailsCard = ({
  title,
  totalAmount,
  status,
}: ReimbursementDetailsCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const tags = useReimbursementTags();

  return (
    <ScrollBox scrollDirection="none" scrollbarGutter="stable">
      <Card padding="space14 space16">
        <Grid gap="space12">
          <Grid
            width="fit-content"
            fontWeight="semibold"
            paddingX="space12"
            paddingY="space4"
            borderRadius="6px"
            background="gray200">
            <Text fontSize="basic">
              {t('reimbursementView.leftSection.filledDetails.title')}
            </Text>
          </Grid>
          <Grid gap="space4">
            <Grid autoFlow="column" justifyContent="space-between">
              <TruncatedText fontSize="basic" fontWeight="semibold">
                {capitalize(
                  title ?? t('dashboard.list.reimbursementItemPlaceholder')
                )}
              </TruncatedText>
              <Tag variant="secondary" color={tags[status].color}>
                {tags[status].text}
              </Tag>
            </Grid>
            <Grid
              autoFlow="column"
              justifyContent="space-between"
              alignItems="baseline">
              <Text color="gray500" fontSize="basic">
                {t('reimbursementView.leftSection.filledDetails.description')}
              </Text>
              <Text fontSize="xlarge" fontWeight="semibold">
                {totalAmount ??
                  t('reimbursementView.leftSection.filledDetails.emptyAmount')}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </ScrollBox>
  );
};
