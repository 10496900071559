import { Button, Card, Grid, Text } from '@candisio/design-system';
import { useToastMessage } from 'components/Toast/useToastMessage';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useDeleteReimbursement } from 'views/Reimbursement/hooks/useDeleteReimbursement';
import { ReimbursementError } from 'views/Reimbursement/hooks/useSubmitReimbursementCaseForReview';
import { useUpdateGeneralExpense } from 'views/Reimbursement/hooks/useUpdateGeneralExpense';
import { useUpdateHospitalityExpense } from 'views/Reimbursement/hooks/useUpdateHospitalityExpense';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { DeleteAction } from './DeleteAction';
import { ErrorSection } from './ErrorSection';

export interface FooterActionsProps {
  isSubmittingForReview: boolean;
  onCycleReimbursments: () => void;
  getExpenses: UseFormGetValues<ExpensesFormOutput>;
  onSubmitForReview: (reimbursementId: string) => Promise<{
    status: string;
    reimbursementItemsErrors: ReimbursementError[];
    hasNoReimbursements?: boolean;
  }>;
}

type ErrorState = {
  showHasNoExpenseError: boolean;
  itemErrors: ReimbursementError[];
};

export const FooterActions = ({
  isSubmittingForReview,
  getExpenses,
  onCycleReimbursments,
  onSubmitForReview,
}: FooterActionsProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [errors, setErrors] = useState<ErrorState>({
    itemErrors: [],
    showHasNoExpenseError: false,
  });

  const { updateGeneralExpense, isUpdateGeneralExpensePending } =
    useUpdateGeneralExpense({ shouldDebounce: false });

  const { updateHosExpensePending, updateHospitalityExpense } =
    useUpdateHospitalityExpense({ shouldDebounce: false });

  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { error, success } = useToastMessage();
  const { deletePending, deleteReimbursement } = useDeleteReimbursement();

  const { triggerAllValidations, lastModifiedExpense } =
    useReimbursementFormsContext();

  const errMessage = t('reimbursementView.createErr');
  const successMessage = t(
    'reimbursementView.rightSection.footerActions.doubleConfirm.deleteSuccessToast'
  );

  const handleDelete = async () => {
    const status = await deleteReimbursement(reimbursementId);
    if (status === 'error') {
      error(errMessage);

      return;
    }

    success(successMessage);
    onCycleReimbursments();
  };

  const handleClick = async () => {
    const lastModifiedExpenseData =
      lastModifiedExpense !== null
        ? getExpenses('expenses')[lastModifiedExpense]
        : undefined;

    if (
      lastModifiedExpenseData &&
      lastModifiedExpenseData.expenseType === 'general'
    ) {
      await updateGeneralExpense(lastModifiedExpenseData);
    }

    if (
      lastModifiedExpenseData &&
      lastModifiedExpenseData.expenseType === 'hospitality'
    ) {
      await updateHospitalityExpense(lastModifiedExpenseData);
    }

    const response = await onSubmitForReview(reimbursementId);
    if (response.status === 'error') {
      triggerAllValidations();
      setErrors({
        itemErrors: response.reimbursementItemsErrors,
        showHasNoExpenseError: response.hasNoReimbursements ?? false,
      });

      return;
    }

    setErrors({ itemErrors: [], showHasNoExpenseError: false });
    onCycleReimbursments();
  };

  const isSubmitPending =
    isSubmittingForReview ||
    updateHosExpensePending ||
    isUpdateGeneralExpensePending;

  return (
    <Card corners="top" boxShadow="elevatedShadow4" padding="space20">
      <Grid gap="space8">
        <Text color="gray500" fontWeight="semibold" textTransform="uppercase">
          {t('reimbursementView.rightSection.footerActions.title')}
        </Text>
        {errors.showHasNoExpenseError && (
          <Text color="red500" fontSize="basic">
            {t('reimbursementView.rightSection.errorNoItem')}
          </Text>
        )}
        {errors.itemErrors.length > 0 && (
          <ErrorSection errors={errors.itemErrors} />
        )}

        <Grid gap="space8">
          <Button
            color="blue"
            onClick={handleClick}
            disabled={isSubmitPending}
            loading={isSubmitPending}>
            {t('reimbursementView.rightSection.footerActions.submitCta')}
          </Button>
          <DeleteAction loading={deletePending} onDelete={handleDelete} />
        </Grid>
      </Grid>
    </Card>
  );
};
