import {
  useDocumentAccessLevelsQuery,
  AccessLevelName,
  ActivityUser,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';

export type AccessByData =
  | {
      users: ActivityUser[] | null;
      roleName: AccessLevelName;
      roleTranslationKey: string;
      accessTranslationKey: string;
    }
  | undefined;

interface UseDocumentAccessLevelDataProps {
  globalDocumentId?: string;
}

export const useDocumentAccessLevelsData = ({
  globalDocumentId,
}: UseDocumentAccessLevelDataProps) => {
  const [accountantRoleFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.accountantRole,
  ]);

  const { data, loading } = useDocumentAccessLevelsQuery({
    fetchPolicy: 'no-cache',
    skip: !globalDocumentId,
    variables: globalDocumentId ? { globalDocumentId } : undefined,
  });

  const documentAccessLevels =
    data?.documentAccessLevels?.__typename === 'AccessLevels'
      ? data.documentAccessLevels.levels
      : [];

  const accessLevels = documentAccessLevels.reduce((acc, level) => {
    acc[level.name] = level.users;

    return acc;
  }, {} as Record<AccessLevelName, ActivityUser[]>);

  // AccessByData
  const accessByRoleData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Admins],
      roleName: AccessLevelName.Admins,
      roleTranslationKey: 'documentAccess.membership.builtinRoles.admins',
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
    accountantRoleFF
      ? {
          users: accessLevels[AccessLevelName.Accountants],
          roleName: AccessLevelName.Accountants,
          roleTranslationKey:
            'documentAccess.membership.builtinRoles.accountants',
          accessTranslationKey: 'documentAccess.membership.access.edit',
        }
      : undefined,
    {
      users: accessLevels[AccessLevelName.Requesters],
      roleName: AccessLevelName.Requesters,
      roleTranslationKey: 'documentAccess.membership.builtinRoles.requesters',
      accessTranslationKey: 'documentAccess.membership.access.edit',
    },
  ];

  const accessByApproversData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Approvers],
      roleName: AccessLevelName.Approvers,
      roleTranslationKey: 'documentAccess.membership.builtinRoles.approvers',
      accessTranslationKey: 'documentAccess.membership.access.approve',
    },
  ];

  const accessByViewersData: AccessByData[] = [
    {
      users: accessLevels[AccessLevelName.Viewers],
      roleName: AccessLevelName.Viewers,
      roleTranslationKey: 'documentAccess.membership.access.viewers.title',
      accessTranslationKey: 'documentAccess.membership.access.view',
    },
  ];

  return {
    admins: accessLevels[AccessLevelName.Admins],
    accountants: accessLevels[AccessLevelName.Accountants],
    requesters: accessLevels[AccessLevelName.Requesters],
    viewers: accessLevels[AccessLevelName.Viewers],
    approvers: accessLevels[AccessLevelName.Approvers],
    accessByRoleData,
    accessByApproversData,
    accessByViewersData,
    loading,
  };
};
