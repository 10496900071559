import { Box } from '@candisio/design-system';
import { KeyboardEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { isMac } from 'utils/osDetection';
import { CommentMentionsControls } from './CommentMentionsControls';
import { CommentMentionsInput } from './CommentMentionsInput';
import { CommentProps } from './types';
import { CommentFormValues } from './types';

export const CommentForm = ({
  onCreateComment,
  isSubmitting,
  loadingMemberships,
  memberships,
  restrictMentions,
}: CommentProps) => {
  const form = useForm<CommentFormValues>({
    defaultValues: {
      mentionIds: [],
      message: '',
    },
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if ((isMac ? e.metaKey : e.ctrlKey) && e.key === 'Enter') {
      e.preventDefault();
      void handleSubmit();
    }
  };

  const handleSubmit = form.handleSubmit(async values => {
    if (values.message.trim().length === 0) {
      return;
    }

    await onCreateComment({
      mention: values.message,
      mentionIds: values.mentionIds,
    });

    form.reset();
  });

  return (
    <Box position="relative">
      <FormProvider {...form}>
        <form onKeyDown={handleKeyDown} onSubmit={handleSubmit}>
          <CommentMentionsInput
            allowMentions={!restrictMentions}
            isDisabled={loadingMemberships}
            memberships={memberships}
          />
          <CommentMentionsControls isSubmitting={isSubmitting} />
        </form>
      </FormProvider>
    </Box>
  );
};
