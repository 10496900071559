import { Grid } from '@candisio/design-system';
import {
  getSplitBookingsFormErrorMessages,
  SplitBookingsFormValues,
  splitBookingsSchema,
} from 'components/Form/SplitBookingsForm/schema';
import { Amount } from 'components/Form/SplitBookingsForm/toolkit/fields/Amount';
import { ArtistSocial } from 'components/Form/SplitBookingsForm/toolkit/fields/ArtistSocial';
import { CostCenter } from 'components/Form/SplitBookingsForm/toolkit/fields/CostCenter';
import { CostObject } from 'components/Form/SplitBookingsForm/toolkit/fields/CostObject';
import { DueDate } from 'components/Form/SplitBookingsForm/toolkit/fields/DueDate';
import { ExtraCostInfo } from 'components/Form/SplitBookingsForm/toolkit/fields/ExtraCostInfo';
import { GeneralLedgerAccount } from 'components/Form/SplitBookingsForm/toolkit/fields/GeneralLedgerAccount';
import { Note } from 'components/Form/SplitBookingsForm/toolkit/fields/Note';
import { PostingText } from 'components/Form/SplitBookingsForm/toolkit/fields/PostingText';
import { Quantity } from 'components/Form/SplitBookingsForm/toolkit/fields/Quantity';
import { TaxCode } from 'components/Form/SplitBookingsForm/toolkit/fields/TaxCode';
import { TaxRepresentation } from 'components/Form/SplitBookingsForm/toolkit/fields/TaxRepresentation';
import { VatRate } from 'components/Form/SplitBookingsForm/toolkit/fields/VatRate';
import {
  determineIsFieldEditable,
  determineIsFieldHidden,
  onChangeFactory,
} from 'components/Form/SplitBookingsForm/toolkit/utils';
import {
  SplitBookingsFormFieldOptions,
  SplitBookingsFormPropsDS,
  SplitDS,
  TaxPresentation,
} from 'components/Form/SplitBookingsForm/types';
import { HookFormCheckboxField } from 'components/HookFormFields/HookFormCheckboxField';
import { useSplitBookingsContext } from 'containers/SplitBookings/SplitBookingsContext';
import { SCROLL_COUNT_THRESHOLD } from 'containers/SplitBookings/toolkit/hooks/useAutoFocusField';
import { BookingKeysActiveQuery } from 'generated-types/graphql.types';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { merge } from 'lodash';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { useProcessingFormOverlayContext } from 'views/Inbox/DocumentProcessing/components/AddContact/ProcessingFormOverlay';
import {
  useShouldRequireGeneralLedgerAccounts,
  useShouldRequireTaxCodes,
} from 'views/utils/useShouldRequireField';
import {
  useShowDueDateField,
  useShowInvoiceCorrectionField,
} from 'views/utils/useShouldShowField';
import { UnitPrice } from './toolkit/fields/UnitPrice';

export const accordionItemIdPrefix = 'split-bookings-accordion-id';

export const useScrollToActiveBooking = ({
  openedIndex,
  preventScroll,
}: {
  openedIndex: number;
  preventScroll: boolean;
}) => {
  const { animating } = useProcessingFormOverlayContext();
  const activeBooking = document.getElementById(
    `${accordionItemIdPrefix}-${openedIndex}`
  );

  useEffect(() => {
    if (activeBooking?.scrollIntoView && !animating && !preventScroll) {
      activeBooking.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [activeBooking, preventScroll, animating]);
};

export const SplitBookingsFormDS = ({
  documentDirection,
  fieldOptions,
  initialValues,
  isFieldEditable,
  mode,
  readOnly,
  autoFocusField,
  formContext: {
    availableBookingKeys,
    bdsConnected,
    hasCostCenters,
    hasCostObjects,
  },
}: SplitBookingsFormPropsDS & {
  autoFocusField: keyof SplitDS | null;
  fieldOptions: SplitBookingsFormFieldOptions;
  formContext: {
    availableBookingKeys: BookingKeysActiveQuery['bookingKeysActive'];
    bdsConnected: boolean;
    hasCostCenters: boolean;
    hasCostObjects: boolean;
  };
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);

  const { shouldUseSapPurchaseOrder, shouldUseSapNetAmount } = useSap();

  const shouldRequireGeneralLedgerAccount =
    useShouldRequireGeneralLedgerAccounts();

  const shouldRequireTaxCode = useShouldRequireTaxCodes();

  const showInvoiceCorrectionField = useShowInvoiceCorrectionField();
  const showDueDateField = useShowDueDateField({
    // allDueDatesAreSame is not used as it is only relevant for the processing form
    allDueDatesAreSame: true,
    isSplitBookingForm: true,
  });

  const integration = useIntegrationSettings();

  const {
    bookings,
    openedIndex,
    setBookings,
    showSubmitErrors,
    initialBookings,
  } = useSplitBookingsContext();

  const defaultValues: Partial<SplitBookingsFormValues> = {
    ...merge(initialValues, initialValues.bookings?.[0]),
    amount: initialValues.bookings?.[0].amount ?? undefined,
    netAmount: initialValues.bookings?.[0].netAmount ?? undefined,
    invoiceCorrection: (initialValues.bookings?.[0].amount || 0) < 0,
  };

  const isHidden = determineIsFieldHidden({
    fieldOptions,
    booking: initialBookings[openedIndex],
  });

  const isOpenedBookingSapExpenseType = bookings[openedIndex]?.sapExpenseType;

  const showPurhcaseOrderQuantityField =
    shouldUseSapPurchaseOrder &&
    !isHidden.quantity &&
    !isOpenedBookingSapExpenseType;

  const form = useForm<SplitBookingsFormValues>({
    context: {
      availableBookingKeys,
      documentDirection,
      hasCostCenters,
      hasCostObjects,
      integration,
      invoiceDate: initialValues.invoiceDate,
      mode,
      shouldRequireGeneralLedgerAccount,
      shouldRequireTaxCode,
      shouldUseSapPurchaseOrder,
      shouldUseSapNetAmount,
      isFormReadOnly: readOnly,
      shouldRequireQuantity: showPurhcaseOrderQuantityField,
    },
    defaultValues,
    mode: 'all',
    resolver: zodResolver({
      translationNamespace: LOCALE_NAME_SPACE.SPLIT_BOOKINGS,
      zodSchema: splitBookingsSchema,
      errorMessages: getSplitBookingsFormErrorMessages(),
    }),
    shouldFocusError: true,
  });

  useEffect(() => {
    if (showSubmitErrors) {
      setTimeout(() => {
        void form.trigger();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSubmitErrors]);

  const onChangeHandlers = onChangeFactory({
    integration,
    bookingKeys: availableBookingKeys,
    bookings,
    openedIndex,
    setBookings,
    setAmount: amount => form.setValue('amount', amount),
    setTaxAmount: taxAmount => form.setValue('taxAmount', taxAmount),
    setNetAmount: netAmount => form.setValue('netAmount', netAmount),
    setVatRate: vatRate => form.setValue('vatRate', vatRate),
  });

  const isNegativeAmount = form.getValues().amount < 0;

  const isEditable = determineIsFieldEditable({
    openedIndex,
    isFieldEditable,
    fieldOptions,
  });

  const preventScroll = bookings.length < SCROLL_COUNT_THRESHOLD;

  useScrollToActiveBooking({
    openedIndex,
    preventScroll,
  });

  // XXX our tooltips that show the error message on focus, will not be able to handle ongoing animations.
  // So we have to add a best guess delay to ensure the animation is done before showing the tooltip.
  const delayForShowOnFocusInMS = preventScroll ? 300 : 1000;

  // Focus on amount field when pressing new split
  useEffect(() => {
    form.setFocus(
      (autoFocusField as keyof Omit<SplitDS, 'id' | 'sapExpenseType'> | null) ||
        'amount'
    );
  }, [autoFocusField, form]);

  return (
    <FormProvider {...form}>
      <Grid
        gap="space12"
        width="100%"
        background="gray50"
        padding="space16"
        borderRadius={bookings.length === 1 ? 'medium' : 'none'}>
        {showInvoiceCorrectionField &&
          (isEditable.amount || isNegativeAmount) && (
            <HookFormCheckboxField
              name="invoiceCorrection"
              label={t('inputs.invoiceCorrection.label')}
              infoMessage={t('inputs.invoiceCorrection.info')}
              onChange={() => {
                form.setValue('amount', form.getValues().amount * -1);
                onChangeHandlers.onChangeField(
                  'amount',
                  form.getValues().amount
                );
              }}
              readOnly={!isEditable.amount}
            />
          )}

        {shouldUseSapNetAmount && (
          <Grid gap="space12">
            {showPurhcaseOrderQuantityField && (
              <Grid columns={2} gap="space8">
                <Quantity
                  autoFocus={autoFocusField === 'quantity'}
                  readOnly={!isEditable.quantity}
                  onChange={newValue => {
                    onChangeHandlers.onChangeField('quantity', newValue);
                  }}
                />
                <UnitPrice
                  autoFocus={autoFocusField === 'unitPrice'}
                  readOnly={!isEditable.unitPrice}
                  onChange={newValue => {
                    onChangeHandlers.onChangeField('unitPrice', newValue);
                  }}
                  label={t('inputs.unitPrice.label')}
                />
              </Grid>
            )}

            <Amount
              name="netAmount"
              readOnly={!isEditable.netAmount}
              autoFocus={autoFocusField === 'netAmount'}
              onChange={value => {
                onChangeHandlers.onChangeNetAmount(value);
                void form.trigger('amount');
              }}
              label={t('inputs.netAmount.label')}
              placeholder={t('inputs.netAmount.placeholder.enabled')}
            />

            <Grid columns={2} gap="space8">
              <Amount
                name="taxAmount"
                readOnly={!isEditable.taxAmount}
                autoFocus={autoFocusField === 'taxAmount'}
                onChange={onChangeHandlers.onChangeTaxAmount}
                label={t('inputs.taxAmount.label')}
                placeholder={t('inputs.taxAmount.placeholder.enabled')}
              />

              {!isHidden.taxCode && (
                <TaxCode
                  autoFocus={autoFocusField === 'taxCode'}
                  {...fieldOptions?.taxCode?.props}
                  integration={integration}
                  documentDirection={documentDirection}
                  readOnly={!isEditable.taxCode}
                  onChange={newValue => {
                    onChangeHandlers.onChangeTaxCode(newValue as string);
                  }}
                />
              )}
            </Grid>

            <Amount
              readOnly={!isEditable.amount}
              autoFocus={autoFocusField === 'amount'}
              onChange={value => {
                onChangeHandlers.onChangeGrossAmount(value);
                void form.trigger('netAmount');
              }}
              label={t('inputs.grossAmount.label')}
              placeholder={t('inputs.grossAmount.placeholder.enabled')}
              dataCy="grossAmount-form-field"
            />
          </Grid>
        )}

        {!shouldUseSapNetAmount && shouldUseSapPurchaseOrder && (
          <Grid
            templateColumns={
              showPurhcaseOrderQuantityField ? '4rem 1fr 6rem' : '1fr 6rem'
            }
            gap="space8">
            {showPurhcaseOrderQuantityField && (
              <Quantity
                autoFocus={autoFocusField === 'quantity'}
                readOnly={!isEditable.quantity}
                onChange={newValue => {
                  onChangeHandlers.onChangeField('quantity', newValue);
                }}
              />
            )}
            <Amount
              readOnly={!isEditable.amount}
              autoFocus={autoFocusField === 'amount'}
              onChange={newValue => {
                onChangeHandlers.onChangeGrossAmount(newValue);
                if (newValue) {
                  form.setValue('invoiceCorrection', newValue < 0);
                }
              }}
              label={t('inputs.totalAmount.label')}
              placeholder={t('inputs.grossAmount.placeholder.enabled')}
              dataCy="grossAmount-form-field"
            />
            <TaxRepresentation
              autoFocus={autoFocusField === 'taxPresentation'}
              onChange={async newValue => {
                void form.trigger('vatRate');

                if (readOnly) {
                  onChangeHandlers.onChangeTaxPresentationReadonly(
                    newValue as TaxPresentation | null
                  );
                } else {
                  onChangeHandlers.onChangeField('taxPresentation', newValue);
                }
              }}
            />
          </Grid>
        )}

        {!shouldUseSapNetAmount && !shouldUseSapPurchaseOrder && (
          <Grid
            templateColumns="minmax(63px, 95px) minmax(72px, 1fr) minmax(50px, 60px)"
            gap="space8">
            <TaxRepresentation
              autoFocus={autoFocusField === 'taxPresentation'}
              onChange={async newValue => {
                void form.trigger('vatRate');

                if (readOnly) {
                  onChangeHandlers.onChangeTaxPresentationReadonly(
                    newValue as TaxPresentation | null
                  );
                } else {
                  onChangeHandlers.onChangeField('taxPresentation', newValue);
                }
              }}
            />
            <Amount
              readOnly={!isEditable.amount}
              autoFocus={autoFocusField === 'amount'}
              onChange={newValue => {
                onChangeHandlers.onChangeGrossAmount(newValue);
                if (newValue) {
                  form.setValue('invoiceCorrection', newValue < 0);
                }
              }}
              label={t('inputs.amount.label')}
              placeholder={t('inputs.amount.placeholder.enabled')}
              dataCy="amount-form-field"
            />
            <VatRate
              autoFocus={autoFocusField === 'vatRate'}
              readOnly={!isEditable.vatRate}
              onChange={onChangeHandlers.onChangeVatRate}
            />
          </Grid>
        )}

        {showDueDateField && (
          <DueDate
            autoFocus={autoFocusField === 'dueDate'}
            readOnly={!isEditable.dueDate}
            onChange={newValue => {
              onChangeHandlers.onChangeField('dueDate', newValue);
            }}
          />
        )}
        {!isHidden.generalLedgerAccount && !isOpenedBookingSapExpenseType && (
          <GeneralLedgerAccount
            autoFocus={autoFocusField === 'generalLedgerAccount'}
            {...fieldOptions?.generalLedgerAccount?.props}
            readOnly={!isEditable.generalLedgerAccount}
            onChange={newValue => {
              onChangeHandlers.onChangeComboBoxField(
                fieldOptions?.generalLedgerAccount?.props?.items,
                'generalLedgerAccount',
                newValue
              );
            }}
            delayForShowOnFocusInMS={delayForShowOnFocusInMS}
          />
        )}
        {!shouldUseSapNetAmount && !isHidden.taxCode && (
          <TaxCode
            autoFocus={autoFocusField === 'taxCode'}
            {...fieldOptions?.taxCode?.props}
            integration={integration}
            documentDirection={documentDirection}
            readOnly={!isEditable.taxCode}
            onChange={newValue => {
              onChangeHandlers.onChangeField('taxCode', newValue);
            }}
          />
        )}
        {!isHidden.costCenter && (
          <CostCenter
            autoFocus={autoFocusField === 'costCenter'}
            {...fieldOptions?.costCenter?.props}
            readOnly={!isEditable.costCenter}
            onChange={newValue => {
              onChangeHandlers.onChangeComboBoxField(
                fieldOptions?.costCenter?.props?.items,
                'costCenter',
                newValue
              );
            }}
            delayForShowOnFocusInMS={delayForShowOnFocusInMS}
          />
        )}
        {!isHidden.costObject && (
          <CostObject
            autoFocus={autoFocusField === 'costObject'}
            {...fieldOptions?.costObject?.props}
            readOnly={!isEditable.costObject}
            onChange={newValue => {
              onChangeHandlers.onChangeComboBoxField(
                fieldOptions?.costObject?.props?.items,
                'costObject',
                newValue
              );
            }}
            delayForShowOnFocusInMS={delayForShowOnFocusInMS}
          />
        )}
        {!isHidden.artistSocialInsuranceCode && (
          <ArtistSocial
            autoFocus={autoFocusField === 'artistSocialInsuranceCode'}
            {...fieldOptions?.artistSocialInsuranceCode?.props}
            readOnly={!isEditable.artistSocialInsuranceCode}
            onChange={newValue => {
              onChangeHandlers.onChangeField(
                'artistSocialInsuranceCode',
                newValue
              );
            }}
          />
        )}
        {!isHidden.extraCostInfo && (
          <ExtraCostInfo
            autoFocus={autoFocusField === 'extraCostInfo'}
            {...fieldOptions?.extraCostInfo?.props}
            readOnly={!isEditable.extraCostInfo}
            onChange={newValue => {
              onChangeHandlers.onChangeComboBoxField(
                fieldOptions?.extraCostInfo?.props?.items,
                'extraCostInfo',
                newValue
              );
            }}
            delayForShowOnFocusInMS={delayForShowOnFocusInMS}
          />
        )}
        {!isHidden.postingText && (
          <PostingText
            autoFocus={autoFocusField === 'postingText'}
            {...fieldOptions?.postingText?.props}
            readOnly={!isEditable.postingText}
            onChange={newValue => {
              onChangeHandlers.onChangeField('postingText', newValue);
            }}
            currentValueLength={form.getValues().postingText?.length}
          />
        )}
        <Note
          autoFocus={autoFocusField === 'note'}
          readOnly={!isEditable.note}
          onChange={newValue => {
            onChangeHandlers.onChangeField('note', newValue);
          }}
          currentValueLength={form.getValues().note?.length}
        />
      </Grid>
    </FormProvider>
  );
};
