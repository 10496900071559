import { Box, Flex, Grid, Tag, TruncatedText } from '@candisio/design-system';
import { CollapsibleCard } from 'components/CollapsibleCard/CollapsibleCard';
import { CommentContainer } from 'components/Comment/CommentContainer';
import {
  DocumentHistoryContainer,
  DocumentHistoryContainerProps,
} from 'components/DocumentHistory/DocumentHistoryContainer';
import { useDocumentHistory } from 'components/DocumentHistory/useDocumentHistory';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DocumentRelations,
  DocumentRelationsPromo,
  DocumentRelationsProps,
} from 'views/Inbox/DocumentProcessing/components/DocumentRelations';
import { useDocumentRelations } from 'views/Inbox/DocumentProcessing/components/hooks/useDocumentRelations';
import { useDocumentTransactions } from 'views/Inbox/DocumentProcessing/components/hooks/useDocumentTransactions';
import { ManageRelationshipsActivationPopover } from './ManageRelationshipsActivationPopover';
import { useManageRelationshipsActivationPopover } from './useManageRelationshipsActivationPopover';

type DocumentSummaryContainerProps = DocumentRelationsProps &
  DocumentHistoryContainerProps;

export const DocumentSummarySection = ({
  documentId,
  documentName,
  documentStatus,
  canAddTransaction = false,
  isEcmDocument = false,
  isSensitiveDocument = false,
  readOnly = false,
}: DocumentSummaryContainerProps) => {
  const { showDocumentRelations } = useEcm();

  return (
    <>
      {!showDocumentRelations && <DocumentRelationsComponentPromo />}
      {showDocumentRelations && (
        <DocumentRelationsComponent
          documentId={documentId}
          documentName={documentName}
          documentStatus={documentStatus}
          canAddTransaction={canAddTransaction}
          readOnly={readOnly}
        />
      )}
      <DocumentHistoryComponent
        documentId={documentId}
        isEcmDocument={isEcmDocument}
        isSensitiveDocument={isSensitiveDocument}
      />
    </>
  );
};

const DocumentRelationsComponentPromo = memo(() => {
  const [t] = useTranslation();

  return (
    <CollapsibleCard
      borderRadius="medium"
      maxHeight="55cqh"
      overflow="hidden"
      id="document-summary-relationships-open"
      headerProps={{ background: 'gray0' }}
      header={<PromoCardHeader />}
      data-testid={t('document.tabs.relationships.title')}
      defaultOpen>
      <DocumentRelationsPromo />
    </CollapsibleCard>
  );
});

const PromoCardHeader = memo(() => {
  const [t] = useTranslation();

  return (
    <Grid templateColumns="auto auto 1fr" alignItems="center" gap="space8">
      <TruncatedText>{t('document.tabs.relationships.title')}</TruncatedText>
      <Tag color="purple" variant="secondary">
        {t('entitlements:promo.tag')}
      </Tag>
    </Grid>
  );
});

const DocumentRelationsComponent = memo(
  ({
    documentId,
    documentName,
    documentStatus,
    canAddTransaction,
    readOnly,
  }: DocumentRelationsProps) => {
    const [t] = useTranslation();

    const { linkedDocumentsCount, loadingLinkedDocuments } =
      useLinkedDocuments(documentId);

    return (
      <>
        <CollapsibleCard
          borderRadius="medium"
          maxHeight="55cqh"
          overflow="hidden"
          id="document-summary-relationships-open"
          headerProps={{ background: 'gray0' }}
          header={
            <CardHeader
              linkedDocumentsCount={linkedDocumentsCount}
              loadingLinkedDocuments={loadingLinkedDocuments}
            />
          }
          data-testid={t('document.tabs.relationships.title')}>
          <DocumentRelations
            documentId={documentId}
            documentName={documentName}
            documentStatus={documentStatus}
            canAddTransaction={canAddTransaction}
            readOnly={readOnly}
          />
        </CollapsibleCard>
      </>
    );
  }
);

const DocumentHistoryComponent = memo(
  ({
    documentId,
    isEcmDocument,
    isSensitiveDocument,
  }: DocumentHistoryContainerProps) => {
    const [t] = useTranslation();

    const [allowCommentsInDMSFF] = useCandisFeatureFlags([
      FEATURE_FLAGS.allowCommentsInDMS,
    ]);

    const {
      documentData: documentHistoryData,
      loading: loadingDocumentHistory,
    } = useDocumentHistory(documentId);

    const timelineEntriesCount = documentHistoryData?.timeline?.length ?? 0;
    const showCountSuffix = !loadingDocumentHistory && timelineEntriesCount > 0;
    const countSuffix = showCountSuffix ? ` (${timelineEntriesCount})` : '';

    const charsAfterEllipsis = countSuffix.length && countSuffix.length - 1;
    const historyTitle = t('document.tabs.protocol') + countSuffix;

    const title = (
      <TruncatedText charsAfterEllipsis={charsAfterEllipsis}>
        {historyTitle}
      </TruncatedText>
    );

    return (
      <Box paddingBottom="space16">
        <CollapsibleCard
          borderRadius="medium"
          maxHeight="85cqh"
          overflow="hidden"
          id="document-summary-protocol-open"
          headerProps={{ background: 'gray0' }}
          header={title}
          data-testid={t('document.tabs.protocol')}
          defaultOpen>
          <Flex
            background="gray0"
            direction="column"
            paddingTop="space8"
            overflow="hidden"
            height="100%">
            {(!isEcmDocument || allowCommentsInDMSFF) && (
              <Box paddingY="space8" paddingX="space16" background="gray0">
                <CommentContainer
                  documentId={documentId}
                  restrictMentions={Boolean(isSensitiveDocument)}
                  isEcmDocument={isEcmDocument}
                />
              </Box>
            )}
            <DocumentHistoryContainer documentId={documentId} />
          </Flex>
        </CollapsibleCard>
      </Box>
    );
  }
);

type CardHeaderProps = {
  linkedDocumentsCount: number;
  loadingLinkedDocuments: boolean;
};

const CardHeader = memo(
  ({ linkedDocumentsCount, loadingLinkedDocuments }: CardHeaderProps) => {
    const [t] = useTranslation();

    const { headerProps, headerRef, isOpen, popoverProps, popoverRef, close } =
      useManageRelationshipsActivationPopover();

    const showCountSuffix = !loadingLinkedDocuments && linkedDocumentsCount > 0;
    const countSuffix = showCountSuffix ? ` (${linkedDocumentsCount})` : '';
    const charsAfterEllipsis = countSuffix.length && countSuffix.length - 1;

    const relationshipsTitle =
      t('document.tabs.relationships.title') + countSuffix;

    return (
      <>
        <Grid
          autoFlow="column"
          justifyContent="start"
          alignItems="center"
          gap="space8"
          {...headerProps}
          ref={headerRef}>
          <TruncatedText charsAfterEllipsis={charsAfterEllipsis}>
            {relationshipsTitle}
          </TruncatedText>
          <Tag color="blue" callout>
            {t('tag.new')}
          </Tag>
        </Grid>
        {isOpen && (
          <ManageRelationshipsActivationPopover
            onClose={close}
            popoverProps={popoverProps}
            popoverRef={popoverRef}
          />
        )}
      </>
    );
  }
);

const useLinkedDocuments = (documentId: string) => {
  const { relatedDocuments, isLoading: loadingRelations } =
    useDocumentRelations(documentId);

  const { transaction, isLoading: loadingTransactions } =
    useDocumentTransactions(documentId);

  const loadingLinkedDocuments = loadingRelations || loadingTransactions;

  const linkedDocumentsCount =
    Number(relatedDocuments?.length) + Number(Boolean(transaction));

  const value = useMemo(
    () => ({ linkedDocumentsCount, loadingLinkedDocuments }),
    [linkedDocumentsCount, loadingLinkedDocuments]
  );

  return value;
};
