import {
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
  useTooltip,
} from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const Divider = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  const { isOpen, tooltipProps, tooltipRef, triggerProps, triggerRef } =
    useTooltip({
      passiveTrigger: true,
    });

  return (
    <>
      <Flex
        position="relative"
        width="calc(100% + .5rem)"
        alignItems="center"
        overflow="hidden"
        style={{ marginTop: '-.5rem', marginBottom: '-.5rem' }}
        justifyContent="center">
        {/* left line */}
        <Box flex="1" height="1px" background="gray300" />
        {/* icon */}
        <Box
          background="gray0"
          paddingX="space4"
          height="space16"
          zIndex={1}
          {...triggerProps}
          ref={triggerRef}>
          <Icon icon="linkOutlined" color="gray300" size="space16" />
        </Box>
        {/* right line */}
        <Box flex="1" height="1px" background="gray300" />
      </Flex>
      {isOpen && (
        <Tooltip {...tooltipProps} ref={tooltipRef}>
          <Text>{t('detailsCard.linkedTooltip')}</Text>
        </Tooltip>
      )}
    </>
  );
};
