import {
  Button,
  Flex,
  Link,
  Text,
  defaultTheme,
} from '@candisio/design-system';
import { usePromotionDetailsModalContent } from 'components/NewPromotions/hooks/usePromotionDetailsModalContent';
import { PromotionId } from 'components/NewPromotions/types';
import { motion } from 'framer-motion';
import { useAnalytics } from 'providers/AnalyticsProvider';
import {
  PromotionLocation,
  PromotionsTrackingEvents,
} from 'providers/AnalyticsProvider/events/PromotionsTrackingEvents';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

const MotionButton = motion(Button);

export const CallInActionSection = ({
  promotionId,
}: {
  promotionId: PromotionId;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  const { promotionDetails } = usePromotionDetailsModalContent(promotionId);
  const { ctaButton } = promotionDetails;
  const { track } = useAnalytics();

  const onClick = () => {
    track(PromotionsTrackingEvents.PROMOTION_CTA_BUTTON_CLICKED, {
      placement: PromotionLocation.DASHBOARD,
      promotionId,
    });
  };

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="start"
      paddingTop="space24"
      gap="space16"
      borderRadius="medium"
      position="sticky"
      bottom="0"
      style={{
        background: 'linear-gradient(180deg, #F8F6F533 0%, #F8F6F5 20%)',
      }}>
      {ctaButton && (
        <MotionButton
          as="a"
          color="purple"
          target="_blank"
          rel="noopener noreferer"
          href={ctaButton.url}
          icon="arrowRight"
          iconPosition="right"
          whileHover={{ color: defaultTheme.colors.gray0 }}
          onClick={onClick}>
          {ctaButton?.text}
        </MotionButton>
      )}
      <Text color="gray500" width="80%">
        <Trans t={t} i18nKey="promotionDetailsModal.ctaMarketingLink.text">
          These features are part of our new package structure
          <Link
            as="a"
            color="gray"
            target="_blank"
            rel="noopener noreferer"
            href={t('promotionDetailsModal.ctaMarketingLink.url')}
            style={{ textDecoration: 'underline' }}>
            candis.io/en/price
          </Link>
        </Trans>
      </Text>
    </Flex>
  );
};
