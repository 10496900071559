import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Tag,
  Text,
} from '@candisio/design-system';
import { EcmDocumentsTable } from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { PromotionCard } from 'components/NewPromotions';
import { PromotionsProvider } from 'components/NewPromotions/context/PromotionsProvider';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { PromotionLink } from 'components/PromotionLink/PromotionLink';
import { useEcmDocumentsTableData } from 'containers/document-relationships/useEcmDocumentsTableData';
import { appointmentLinks } from 'containers/Entitlements/toolkit/utils';
import { Locale } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { noop } from 'lodash';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { ArchiveDocumentManagementEvents } from 'providers/AnalyticsProvider/events/DocumentManagementEvents';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { LOCALE_NAME_SPACE, Trans } from 'providers/LocaleProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { styled } from 'styles/themes';
import { ArchiveViewLayout } from 'views/Archive/components/ArchiveViewLayout';
import { TabView } from 'views/Inbox/models';
import { documentsData } from '../dummyData';
import { UpsellPromo } from '../UpsellPromo';
import { thumbnails } from '../utils';
import { FeatureBenefit, PromotionCards } from './PromotionCard';
import { VideoCard } from './VideoCard';

export const EcmTablePromo = () => {
  const [newSettingsPromotionsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.newSettingsPromotions,
  ]);

  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_ECM_DOCUMENTS}>
      {newSettingsPromotionsFF ? <NewPromotions /> : <OldPromotions />}
    </ArchiveViewLayout>
  );
};

const ecmBenefits: FeatureBenefit[] = [
  {
    icon: 'creditCardOutline',
    heading: 'promo.ecm.modal.benefit1.heading',
    description: 'promo.ecm.modal.benefit1.description',
  },
  {
    icon: 'linkOutlined',
    heading: 'promo.ecm.modal.benefit2.heading',
    description: 'promo.ecm.modal.benefit2.description',
  },
  {
    icon: 'payments',
    heading: 'promo.ecm.modal.benefit3.heading',
    description: 'promo.ecm.modal.benefit3.description',
  },
  {
    icon: 'search',
    heading: 'promo.ecm.modal.benefit4.heading',
    description: 'promo.ecm.modal.benefit4.description',
  },
] as const;

const { MODAL_DEMO_BTN_CLICKED, MORE_INFO_CLICKED, DEMO_BTN_CLICKED } =
  ArchiveDocumentManagementEvents;

const OldPromotions = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.ENTITLEMENTS);

  const { track } = useAnalytics();

  const trackModalDemoClick = useCallback(
    () => track(MODAL_DEMO_BTN_CLICKED),
    [track]
  );

  const trackDemoClick = useCallback(
    () => track(DEMO_BTN_CLICKED), // ignoring prettier
    [track]
  );

  const trackMoreInfoClick = useCallback(
    () => track(MORE_INFO_CLICKED),
    [track]
  );

  const user = useCurrentUser();
  const locale = user?.locale ?? Locale.De;
  const thumbnail = thumbnails[FEATURE.ECM][locale];

  return (
    <Flex direction="column" gap="space16" paddingX="space12" maxWidth="1300px">
      <Tag variant="secondary" color="purple">
        {t('promo.tag')}
      </Tag>
      <Text as="h2" fontSize="xxlarge">
        <Trans t={t} i18nKey="promo.ecm.title">
          <Text fontWeight="semibold">Dokumentenmanagement</Text>
          Alle wichtigen Dokumente im Überblick.
        </Trans>
      </Text>
      <VideoCard feature={FEATURE.ECM} withFading>
        <Image src={thumbnail} alt="" opacity=".7" />
      </VideoCard>
      <Flex
        position="absolute"
        bottom="-4vmax"
        direction="column"
        alignItems="center"
        gap="space16"
        alignSelf="center"
        width="fit-content">
        <PromotionLink
          trackClick={trackDemoClick}
          href={t(appointmentLinks[FEATURE.ECM])}
          variant="calendar">
          {t('promo.modal.bookAppointment')}
        </PromotionLink>
        <UpsellPromo
          feature={FEATURE.ECM}
          CustomPromoMaterial={
            <PromotionCards benefits={ecmBenefits} feature={FEATURE.ECM} />
          }
          trackMoreInfo={trackMoreInfoClick}
          trackDemo={trackModalDemoClick}
          hidePackages>
          <Button variant="tertiary" color="promo" width="100%">
            {t('promo.ecm.moreInfoCTA')}
          </Button>
        </UpsellPromo>
      </Flex>
    </Flex>
  );
};

const OverlayGrid = styled(Grid)`
  height: 100%;
  align-items: center;

  & > * {
    grid-row: 1;
    grid-column: 1;
  }
`;

const tableData = documentsData.map((el, j) => ({
  ...el,
  id: j + '',
})) as EcmDocumentsTableData[];

const NewPromotions = () => {
  const { documentManagementPromotion } = usePromotionContent();
  const { availableDocumentColumnIds, configurationsTable } =
    useEcmDocumentsTableData({ filterParamsSource: 'url' });

  return (
    <OverlayGrid height="100%">
      <Box
        height="100%"
        style={{
          maskImage: 'linear-gradient(to top, transparent 10%, #000000 25%)',
        }}>
        <EcmDocumentsTable
          context="documents"
          columns={availableDocumentColumnIds}
          configurationsTable={configurationsTable}
          data={tableData}
          selectedDocumentsCount={0}
          onSearchChange={noop}
          onRowClick={noop}
          onFilter={noop}
          onEndReached={noop}
          onUpdateConfigurations={noop}
          onSort={noop}
          onResetTableConfigurations={noop}
        />
      </Box>
      <Box inset="0" height="100%" />
      <Box justifySelf="center" width="50rem" height="28rem">
        <PromotionsProvider promotions={[documentManagementPromotion]}>
          <PromotionCard
            promotion={documentManagementPromotion}
            closable={false}
          />
        </PromotionsProvider>
      </Box>
    </OverlayGrid>
  );
};
