import { Tag } from '@candisio/design-system';
import { AddOnIcon } from 'containers/Entitlements/components/Upsell/components/AddOnIcon';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useDatev } from 'orgConfig/datev';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { useTeams } from 'orgConfig/teams/useTeams';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router';
import { useCheckDatevSetup } from '../Integrations/DATEV/setup-checks';
import {
  useNeedsSapConfig,
  useNeedsSapCreditCardConfig,
} from '../Integrations/SAP/utils';
import { useOrganizationData } from '../Organization/hooks/useOrganizationData';
import { useOrganizationOwnIbansData } from '../PaymentsSettings/useOrganizationOwnIbansData';
import { SETTINGS_ROUTES } from '../types';

const STATUS_PAGE_URL = import.meta.env.STATUS_PAGE_URL;

const NewTag = memo(() => {
  const [t] = useTranslation();

  return (
    <Tag color="blue" variant="secondary" callout>
      {t('tag.new')}
    </Tag>
  );
});

export const useSettingsNavigationConfig = () => {
  const [t] = useTranslation();
  const { organizationSlug } = useParams<{ organizationSlug?: string }>();

  const { isAdmin, isAccountant } = useUserRoles();
  const isAdminOrAccountant = isAdmin || isAccountant;

  const { showMissingIBANIndicator } = useOrganizationOwnIbansData();

  const newSettingsPromotionsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.newSettingsPromotions
  );

  const { isTeamsFeatureEnabled } = useTeams();
  const { showDocumentTags, showContractCustomSubCategories } = useEcm();
  const { shouldUseSapConfig } = useSap();
  const creditCards = useCreditCardsSetup();
  const { shouldUseCoreDataApi, shouldUseCandisApi, shouldUseAccountingAreas } =
    useOtherIntegration();

  const indicateSapSetupNeeded = useNeedsSapConfig();
  const indicateSapCreditCardSetupNeeded = useNeedsSapCreditCardConfig();
  const {
    indicateDatevCreditCardSetupNeeded,
    hasReadClientsButNotSelected,
    hasProvisionsAndRequiresSetup,
    accountingCoreDataMissing,
  } = useCheckDatevSetup();

  const { hasOrgTaxDetails } = useOrganizationData();

  const {
    bdsBoughtButNotConnected,
    exportProvisionsFF,
    hide: hideDatev,
  } = useDatev(); // BDS-checked

  const settingsRoute = `/${organizationSlug}${Routes.SETTINGS}`;

  const navLinkConfig = [
    {
      showItem: isAdminOrAccountant,
      to: `${settingsRoute}${Routes.ORGANIZATION}`,
      label: t('settings.navigation.general.items.organization'),
      showIndicator: !hasOrgTaxDetails || showMissingIBANIndicator,
      'data-cy': 'settings.navigation.general.items.organization',
    },
    {
      showItem: isTeamsFeatureEnabled && isAdminOrAccountant,
      to: `${settingsRoute}${Routes.TEAM_MEMBERS}`,
      label: t('settings.navigation.general.items.users'),
      'data-cy': 'settings.navigation.organization.items.users',
    },
    {
      showItem: !isTeamsFeatureEnabled && isAdminOrAccountant,
      to: `${settingsRoute}${Routes.TEAM_MEMBERS}`,
      label: t('settings.navigation.general.items.teamMembers'),
      'data-cy': 'settings.navigation.organization.items.teamMembers',
    },
    {
      showItem: isTeamsFeatureEnabled && isAdmin,
      to: `${settingsRoute}/${SETTINGS_ROUTES.TEAMS}`,
      label: t('settings.navigation.general.items.teams'),
      tag: <NewTag />,
      'data-cy': 'settings.navigation.organization.items.teams',
    },
    {
      showItem: true,
      to: `${settingsRoute}/${SETTINGS_ROUTES.MOBILE_APPLICATION}`,
      label: t('settings.navigation.organization.items.mobileApplication'),
      'data-cy': 'settings.navigation.organization.items.mobileApplication',
    },
    {
      showItem: STATUS_PAGE_URL,
      to: `${settingsRoute}${STATUS_PAGE_URL}`,
      label: t('settings.navigation.general.items.statusPage'),
      'data-cy': 'settings.navigation.general.items.statusPage',
    },
    {
      showItem: isAdminOrAccountant,
      to: `${settingsRoute}${Routes.MAIL_SYNC}`,
      label: t('settings.navigation.organization.items.mailSync'),
      'data-cy': 'settings.navigation.organization.items.mailSync',
    },
    {
      showItem: !hideDatev && isAdminOrAccountant,
      to: `${settingsRoute}${Routes.DATEV}`,
      label: t('settings.navigation.organization.items.datev'),
      showIndicator:
        hasReadClientsButNotSelected ||
        accountingCoreDataMissing ||
        bdsBoughtButNotConnected,
      indicatorStatus: 'default',
      source: 'datev',
      'data-cy': 'settings.navigation.organization.items.datev',
    },
    {
      showItem: !shouldUseCoreDataApi && isAdmin,
      to: `${settingsRoute}${Routes.PAYMENTS}`,
      label: t('settings.navigation.organization.items.payments'),
      'data-cy': 'settings.navigation.organization.items.payments',
    },
    {
      showItem: isAdminOrAccountant,
      to: `${settingsRoute}${Routes.CREDIT_CARDS}`,
      label: t('settings.navigation.general.items.creditCards'),
      showIndicator: shouldUseSapConfig
        ? indicateSapCreditCardSetupNeeded
        : indicateDatevCreditCardSetupNeeded,
      requiresUpgrade: !creditCards.isAddonBought,
      icon: !creditCards.isAddonBought ? <AddOnIcon /> : null,
      source: 'credit-cards',
      'data-cy': 'settings.navigation.organization.items.creditCards',
    },
    {
      showItem: shouldUseSapConfig && isAdminOrAccountant,
      to: `${settingsRoute}${Routes.SAP}`,
      label: t('settings.navigation.organization.items.sap'),
      showIndicator: indicateSapSetupNeeded,
      source: 'sap',
      'data-cy': 'settings.navigation.organization.items.sap',
    },
    {
      showItem: shouldUseCandisApi && isAdmin,
      to: `${settingsRoute}${Routes.CANDIS_API}`,
      label: t('settings.navigation.organization.items.apiSettings'),
      source: 'apiSettings',
      'data-cy': 'settings.navigation.organization.items.candisAPI',
    },
    {
      showItem: isAdminOrAccountant,
      to: `${settingsRoute}${Routes.WORKFLOWS}`,
      label: t('settings.navigation.automation.items.workflow'),
      'data-cy': 'settings.navigation.automation.items.workflow',
    },
    {
      showItem: isAdmin,
      to: `${settingsRoute}${Routes.SMART_FIELD_SUGGESTIONS}`,
      label: t('settings.navigation.automation.items.smartFieldSuggestions'),
      'data-cy': 'settings.navigation.automation.items.smartFieldSuggestions',
    },
    {
      showItem: (showDocumentTags || newSettingsPromotionsFF) && isAdmin,

      to: `${settingsRoute}${Routes.DOCUMENT_TAGS}`,
      label: t('settings.navigation.organization.items.documentTags'),
      icon: !showDocumentTags && newSettingsPromotionsFF ? <AddOnIcon /> : null,
      tag: showDocumentTags ? <NewTag /> : null,
      'data-cy': 'settings.navigation.organization.items.documentTags',
    },
    {
      showItem:
        (showContractCustomSubCategories || newSettingsPromotionsFF) && isAdmin,
      to: `${settingsRoute}${Routes.CONTRACT_SUBCATEGORIES}`,
      label: t(
        'settings.navigation.organization.items.contractCustomSubCategories'
      ),
      tag: showContractCustomSubCategories ? <NewTag /> : null,
      icon:
        !showContractCustomSubCategories && newSettingsPromotionsFF ? (
          <AddOnIcon />
        ) : null,
      'data-cy':
        'settings.navigation.organization.items.contractCustomSubCategories',
    },
    {
      showItem: isAdminOrAccountant,
      to: `${settingsRoute}${Routes.COST_CENTER}`,
      label: t('settings.navigation.organization.items.costCenters'),
      'data-cy': 'settings.navigation.organization.items.costCenters',
    },
    {
      showItem: shouldUseAccountingAreas && isAdminOrAccountant,
      to: `${settingsRoute}/${SETTINGS_ROUTES.ACCOUNTING_AREAS}`,
      label: t('settings.navigation.organization.items.accountingAreas'),
      'data-cy': 'settings.navigation.organization.items.accountingAreas',
    },
    {
      showItem: isAdminOrAccountant,
      to: `${settingsRoute}${Routes.BOOKING_ACCOUNT}`,
      label: t('settings.navigation.organization.items.bookingAccounts'),
      'data-cy': 'settings.navigation.organization.items.bookingAccounts',
    },
    {
      showItem: isAdminOrAccountant,
      to: `${settingsRoute}${Routes.TAX_CODES}`,
      label: t('settings.navigation.organization.items.bookingKeys'),
      'data-cy': 'settings.navigation.organization.items.bookingKeys',
    },
    {
      showItem: !hideDatev && isAdminOrAccountant,
      to: `${settingsRoute}${Routes.PROVISIONS}`,
      label: t('settings.navigation.organization.items.provisions'),
      'data-cy': 'settings.navigation.organization.items.provisions',
      showIndicator: exportProvisionsFF
        ? hasProvisionsAndRequiresSetup
        : bdsBoughtButNotConnected,
      requiresUpgrade: !exportProvisionsFF,
      icon: !exportProvisionsFF ? <AddOnIcon /> : null,
      source: 'provisions',
    },
    {
      showItem: isAdminOrAccountant,
      to: `${settingsRoute}/${SETTINGS_ROUTES.PAYMENT_CONDITIONS}`,
      label: t('settings.navigation.organization.items.paymentConditions'),
      'data-cy': 'settings.navigation.organization.items.paymentConditions',
    },
  ];

  return { navLinkConfig };
};
