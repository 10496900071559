import { Box, Grid } from '@candisio/design-system';
import { BoxWrapper } from 'components/Layouts/styles';
import {
  StickyCardRefProvider,
  useStickyCardRefContext,
} from 'providers/StickyCardRefProvider';
import { ReactNode } from 'react';
import { ReimbursementProvider } from '../toolkit/ReimbursementContext/ReimbursementProvider';

interface ReimbursementLayoutProps {
  leftSection: ReactNode;
  middleSection: ReactNode;
  rightSection: ReactNode;
}

export const ReimbursementLayout = ({
  leftSection,
  middleSection,
  rightSection,
}: ReimbursementLayoutProps) => {
  const cardRef = useStickyCardRefContext();

  return (
    <Grid
      // @ts-ignore
      style={{ containerType: 'size' }}
      height="100%"
      paddingBottom={0}
      paddingRight={0}
      paddingLeft="space16"
      maxWidth="100%"
      overflow="hidden"
      alignItems="start"
      gap="space16"
      templateColumns="1fr 2fr 1fr">
      <ReimbursementProvider>
        <Box overflow="hidden" height="100%">
          {leftSection}
        </Box>
        {middleSection}
      </ReimbursementProvider>
      <StickyCardRefProvider>
        <BoxWrapper
          overflow="hidden"
          height="100%"
          $scrollmargin={cardRef?.current?.offsetHeight}>
          {rightSection}
        </BoxWrapper>
      </StickyCardRefProvider>
    </Grid>
  );
};
