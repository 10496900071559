import { Flex, Grid, Heading } from '@candisio/design-system';
import { useSSO } from 'orgConfig/sso/sso';
import { useTranslation } from 'react-i18next';
import { useGetRolesForUser } from 'views/Settings/TeamMembers/hooks/useGetRolesForUser';
import { RolesField } from './RolesField';

interface RolesSectionProps {
  readOnly?: boolean;
}

export const RolesSection = ({ readOnly }: RolesSectionProps) => {
  const sso = useSSO();

  const { roles, loading: rolesLoading } = useGetRolesForUser();

  const [t] = useTranslation();

  if (rolesLoading) {
    return null;
  }

  return (
    <Grid alignContent="space-between" height="100%">
      <Flex direction="column" gap="space8">
        <Heading as="h3">
          {t('settings.teamMembers.form.rolesForm.heading')}
        </Heading>

        <RolesField
          roles={roles}
          name="roles"
          isReadOnly={sso.provides.roles || readOnly}
        />
      </Flex>
    </Grid>
  );
};
