import { Flex, Grid } from '@candisio/design-system';
import { splitBookingsSchema } from 'components/Form/SplitBookingsForm/schema';
import {
  accordionItemIdPrefix,
  SplitBookingsFormDS,
} from 'components/Form/SplitBookingsForm/SplitBookingsForm';
import { useSplitBookingsFieldOptions } from 'components/Form/SplitBookingsForm/toolkit/hooks/useSplitBookingsFieldOptions';
import {
  SplitBookingsFormFieldOptions,
  SplitBookingsFormPropsDS,
  TaxPresentation,
} from 'components/Form/SplitBookingsForm/types';
import { Actions } from 'containers/SplitBookings/components/Actions/Actions';
import {
  Header,
  HeaderProps,
} from 'containers/SplitBookings/components/Header/Header';
import { Loading } from 'containers/SplitBookings/components/Loading/Loading';
import { SplitBookingsLayout } from 'containers/SplitBookings/components/SplitBookingsLayout';
import { AccordionItem } from 'containers/SplitBookings/components/Summary/AccordionItem';
import { Summary } from 'containers/SplitBookings/components/Summary/Summary';
import {
  SplitBookingsProvider,
  useSplitBookingsContext,
} from 'containers/SplitBookings/SplitBookingsContext';
import { useAddNewSplit } from 'containers/SplitBookings/toolkit/hooks/useAddNewSplit';
import { useAutoFocusField } from 'containers/SplitBookings/toolkit/hooks/useAutoFocusField';
import { useHasInitialRemainingAmountError } from 'containers/SplitBookings/toolkit/hooks/useHasInitialRemainingAmountError';
import {
  bookingToSummaryData,
  bookingWithNetAmount,
  calculateRestAmount,
  getRoundingDifference,
  grossToNet,
} from 'containers/SplitBookings/toolkit/utils';
import {
  BookingKeysActiveQuery,
  useBookingKeysActiveQuery,
  useOrganizationQuery,
} from 'generated-types/graphql.types';
import { useIntegrationSettings } from 'hooks/useIntegrationSettings';
import { isNil } from 'lodash';
import { useDatev } from 'orgConfig/datev';
import { useSap } from 'orgConfig/sap';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { amountFormatNonNull } from 'utils/format';
import { roundToCurrencyPrecision } from 'utils/roundToCurrencyPrecision';
import { getIsQuantityRequired } from 'views/Inbox/DocumentProcessing/util/getIsQuantityRequired';

export const Container = (
  props: SplitBookingsFormPropsDS & {
    fieldOptions: SplitBookingsFormFieldOptions;
    formContext: {
      availableBookingKeys: BookingKeysActiveQuery['bookingKeysActive'];
      bdsConnected: boolean;
      hasCostCenters: boolean;
      hasCostObjects: boolean;
    };
  }
) => {
  const [t] = useTranslation('split-bookings');
  const { readOnly, isFieldEditable } = props;

  const {
    shouldUseSapGL,
    shouldUseSapPurchaseOrder,
    shouldUsePackageFreight,
    shouldUseSapNetAmount,
  } = useSap();

  const integration = useIntegrationSettings();

  const {
    bookings,
    currency,
    grossAmount,
    invoiceDate,
    openedIndex,
    showSubmitErrors,
    sapNetAmountHasSingleBookingError,
    setBookings,
    setCurrency,
    setGrossAmount,
    setOpenedIndex,
    setShowSubmitErrors,
  } = useSplitBookingsContext();

  const schemaContext = {
    availableBookingKeys: props.formContext.availableBookingKeys,
    documentDirection: props.documentDirection,
    hasCostCenters: props.formContext.hasCostCenters,
    hasCostObjects: props.formContext.hasCostObjects,
    integration,
    invoiceDate,
    mode: props.mode,
    shouldRequireGeneralLedger: shouldUseSapGL,
    shouldUseSapPurchaseOrder,
    shouldUseSapNetAmount,
  };

  const schema = splitBookingsSchema(schemaContext);

  const isBookingValid = bookings.map(booking => {
    const res = schema.safeParse({
      ...booking,
      invoiceCorrection: (booking.amount ?? 0) < 0,
    });

    return res.success;
  });

  const isLastSplitOpen = openedIndex === bookings.length - 1;
  const isOnlyBooking = bookings.length === 1;

  const remainingAmount = calculateRestAmount({
    grossAmount,
    bookings: bookings.map(bookingWithNetAmount),
  });

  const roundingDifference = getRoundingDifference({
    grossAmount,
    bookings: bookings.map(bookingWithNetAmount),
  });

  const hasRemainingAmountError =
    Math.abs(remainingAmount) > Math.abs(roundingDifference ?? 0);

  const allBookingsValid = Boolean(
    isBookingValid.every(Boolean) &&
      grossAmount &&
      currency &&
      (shouldUseSapNetAmount
        ? !sapNetAmountHasSingleBookingError
        : !hasRemainingAmountError)
  );

  const hasInitialRemainingAmountError = useHasInitialRemainingAmountError();

  const autoFocusField = useAutoFocusField({
    schemaContext,
    fieldOptions: props.fieldOptions,
    isFieldEditable,
  });

  const { onAddNewSplit } = useAddNewSplit({
    isCurrentSplitValid: isBookingValid[openedIndex],
  });

  const onDeleteBooking = (fieldIndex: number) => {
    setBookings(bookings.filter((b, i) => i !== fieldIndex));

    if (openedIndex > fieldIndex || isLastSplitOpen) {
      setOpenedIndex(openedIndex - 1);
    }
  };

  const onAcceptBookings = () => {
    setShowSubmitErrors(true);

    if (!allBookingsValid || !props.onAcceptBookings) {
      return;
    }

    const prevBookingIsMultipleSplit =
      (props.initialValues?.bookings?.length ?? 0) > 1;

    const isSapPackageFreightPrevBookings =
      shouldUsePackageFreight && !isNil(props.initialValues?.bookings);

    const currentBookingIsMultipleSplit = bookings?.length > 1;

    const isSapPackageFreightCurrentBookings =
      shouldUsePackageFreight && !isNil(bookings);

    const isCreatingMultipleSplitsFromSingleSplit =
      (!isSapPackageFreightPrevBookings &&
        isSapPackageFreightCurrentBookings) ||
      (!prevBookingIsMultipleSplit && currentBookingIsMultipleSplit);

    const bookingsWithoutId = bookings.map(({ id, ...rest }) => rest);

    const bookingsWithId = bookings;

    const normalizedBookings = shouldUseSapPurchaseOrder
      ? bookingsWithId
      : isCreatingMultipleSplitsFromSingleSplit
      ? bookingsWithoutId
      : bookingsWithId;

    const modifiedBookings =
      roundingDifference && !shouldUseSapNetAmount
        ? normalizedBookings.map((booking, index) => {
            if (index === normalizedBookings.length - 1) {
              const updatedAmount = (booking.amount ?? 0) + roundingDifference;

              const netAmount =
                booking.taxPresentation === TaxPresentation.Gross
                  ? grossToNet(updatedAmount, booking.vatRate ?? 0)
                  : updatedAmount;

              const taxAmount = roundToCurrencyPrecision(
                booking.taxPresentation === TaxPresentation.Gross
                  ? updatedAmount - netAmount
                  : (netAmount * (booking.vatRate ?? 0)) / 100
              );

              return {
                ...booking,
                amount: updatedAmount,
                taxAmount,
                netAmount,
              };
            } else {
              return booking;
            }
          })
        : normalizedBookings;

    props.onAcceptBookings({
      amount: grossAmount,
      currency,
      bookings: modifiedBookings,
    });
  };

  useEffect(() => {
    // we always want to immediately surface if there is a remaining amount
    if (
      !showSubmitErrors &&
      hasInitialRemainingAmountError &&
      !shouldUseSapNetAmount
    ) {
      setShowSubmitErrors(true);
    }
  }, [
    hasInitialRemainingAmountError,
    showSubmitErrors,
    shouldUseSapNetAmount,
    setShowSubmitErrors,
  ]);

  const headerProps: HeaderProps = {
    readOnly: !!readOnly,
    isFieldEditable,
    roundingDifference,
    headerData: {
      currency,
      remainingAmount,
      amount: grossAmount,
    },
    onChange: headerData => {
      setGrossAmount(headerData.amount);
      setCurrency(headerData.currency);
    },
  };

  return (
    <SplitBookingsLayout
      title={t('title')}
      header={<Header {...headerProps} />}>
      <Grid
        alignContent="space-between"
        gap="space16"
        height="max-content"
        minHeight="100%">
        <Flex direction="column" gap="1rem">
          <Flex direction="column">
            {isOnlyBooking ? (
              <SplitBookingsFormDS
                {...props}
                autoFocusField={autoFocusField}
                initialValues={{
                  ...props.initialValues,
                  bookings: [bookings[0]],
                }}
              />
            ) : (
              bookings.map((b, i) => {
                return (
                  <AccordionItem
                    key={b.id}
                    id={`${accordionItemIdPrefix}-${i}`}
                    open={i === openedIndex}
                    error={showSubmitErrors && !isBookingValid[i]}
                    onClick={() => setOpenedIndex(i)}
                    summary={
                      <Summary
                        splitNumber={i + 1}
                        onClickItem={() => setOpenedIndex(i)}
                        onDelete={
                          readOnly ? undefined : () => onDeleteBooking(i)
                        }
                        data={bookingToSummaryData({
                          currency,
                          booking: b,
                          isSapNetAmount: shouldUseSapNetAmount,
                        })}
                      />
                    }>
                    {i === openedIndex && (
                      <SplitBookingsFormDS
                        {...props}
                        autoFocusField={autoFocusField}
                        initialValues={{
                          ...props.initialValues,
                          bookings: [bookings[i]],
                        }}
                      />
                    )}
                  </AccordionItem>
                );
              })
            )}
          </Flex>
        </Flex>
        {props.onAcceptBookings ? (
          <Actions
            roundingDifference={
              roundingDifference && currency && !shouldUseSapNetAmount
                ? amountFormatNonNull(roundingDifference, currency)
                : undefined
            }
            readOnly={!!readOnly}
            onDiscardBookings={props.onDiscardBookings}
            errorMessage={
              !allBookingsValid && showSubmitErrors ? t('errors.required') : ''
            }
            onAcceptBookings={onAcceptBookings}
            onAddNewSplit={onAddNewSplit}
            purchaseOrderType={props.purchaseOrderType}
            currentBookings={bookings}
          />
        ) : null}
      </Grid>
    </SplitBookingsLayout>
  );
};

export const SplitBookings = (props: SplitBookingsFormPropsDS) => {
  const { fieldOptions, isLoading } = useSplitBookingsFieldOptions({
    defaultValues: props.initialValues,
    shouldRequireQuantity: getIsQuantityRequired(props.purchaseOrderType),
  });

  const organizationId = useOrganizationId();
  const { data: organizationData } = useOrganizationQuery({
    variables: { id: organizationId },
  });

  const hasCostCenters =
    organizationData?.organization?.hasCostCenters ?? false;

  const hasCostObjects =
    organizationData?.organization?.hasCostObjects ?? false;

  const { data: bookingKeysActiveData, loading } = useBookingKeysActiveQuery();
  const availableBookingKeys = bookingKeysActiveData?.bookingKeysActive ?? [];

  const { bdsConnected } = useDatev(); // BDS-checked

  return isLoading || loading ? (
    <Loading
      bookingCount={(props.initialValues.bookings || []).length || 1}
      readOnly={!!props.readOnly}
      onDiscardBookings={props.onDiscardBookings}
      onAcceptBookings={props.onAcceptBookings}
      openedIndex={props.initialOpenIndex ?? 0}
    />
  ) : (
    <SplitBookingsProvider
      openedIndex={props.initialOpenIndex ?? -1}
      bookings={props.initialValues.bookings || []}
      initialBookings={props.initialValues.bookings || []}
      grossAmount={props.initialValues.grossAmount}
      currency={props.initialValues.currency}
      invoiceDate={props.initialValues.invoiceDate}
      showSubmitErrors={props.showSubmitErrors || props.mode === 'approve'}
      roundingAmount={props.initialValues.roundingAmount}>
      <Container
        {...props}
        fieldOptions={fieldOptions}
        formContext={{
          bdsConnected,
          hasCostObjects,
          hasCostCenters,
          availableBookingKeys,
        }}
      />
    </SplitBookingsProvider>
  );
};
