import { Box, Button, Flex, Grid } from '@candisio/design-system';
import { useAnalytics } from 'providers/AnalyticsProvider';
import {
  PromotionLocation,
  PromotionsTrackingEvents,
} from 'providers/AnalyticsProvider/events/PromotionsTrackingEvents';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { usePromotionsContext } from '../context/PromotionsProvider';
import { Promotion } from '../types';
import { PromotionCallInAction } from './PromotionCallInAction/PromotionCallInAction';
import { PromotionContent } from './PromotionContent/PromotionContent';
import { PromotionHeader } from './PromotionHeader/PromotionHeader';
import { PromotionImages } from './PromotionImages/PromotionImages';

export type PromotionCardProps = { promotion: Promotion; closable?: boolean };

export const PromotionCard = ({
  promotion,
  closable = true,
}: PromotionCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  const { hidePromotionCard } = usePromotionsContext();

  const { track } = useAnalytics();

  const trackEvent = () => {
    track(PromotionsTrackingEvents.PROMOTION_HIDDEN_CLICKED, {
      placement: PromotionLocation.DASHBOARD,
      promotionId: promotion.id,
    });
  };

  const onClickCloseIcon = () => {
    hidePromotionCard?.(promotion?.id);
    trackEvent();
  };

  return (
    <Flex
      width="100%"
      height="100%"
      background="gray0"
      boxShadow="elevatedShadow4"
      borderRadius="medium"
      overflow="hidden">
      <Flex
        height="100%"
        width="50%"
        flexGrow="1"
        direction="column"
        justifyContent="space-between"
        color="gray800"
        fontSize="large"
        lineHeight="space32"
        paddingY="space20"
        background="gray0">
        <Flex direction="column" gap="space24">
          <PromotionHeader promotion={promotion} />
          <PromotionContent promotion={promotion} />
        </Flex>

        <Flex direction="column" paddingX="space20">
          <PromotionCallInAction promotion={promotion} />
        </Flex>
      </Flex>
      <Grid
        height="100%"
        width="60%"
        flexGrow="0"
        style={{ isolation: 'isolate' }}>
        <PromotionImages promotion={promotion} />
      </Grid>
      {closable && (
        <Box position="absolute" top="0" right="0" padding="10px" opacity="0.5">
          <Button
            icon="close"
            variant="tertiary"
            color="gray"
            onClick={onClickCloseIcon}
            label={t('closeButton.tooltip')}
          />
        </Box>
      )}
    </Flex>
  );
};
