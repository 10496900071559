import { Grid } from '@candisio/design-system';
import { HookFormAmountField } from 'components/HookFormFields/HookFormAmountField';
import { HookFormDatePickerField } from 'components/HookFormFields/HookFormDatePickerField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { ExpenseFormWrapper } from '../ExpenseFormWrapper';
import { ExpenseInvoicePreviewContainer } from '../ExpenseInvoicePreviewContainer';
import { generateGeneralExpenseFormFieldNames } from '../utils/generateGeneralExpenseFormFieldNames';

interface ReadonlyGeneralExpensesFormProps {
  formIndex: number;
  expenseId: string;
  reason: string | undefined;
  totalAmount?: number | null;
  invoice?: ExpenseInvoice;
}
export const ReadonlyGeneralExpensesForm = ({
  expenseId,
  formIndex,
  reason,
  invoice,
  totalAmount,
}: ReadonlyGeneralExpensesFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const fields = generateGeneralExpenseFormFieldNames(formIndex);

  return (
    <ExpenseFormWrapper
      expenseId={expenseId}
      totalAmount={totalAmount}
      expenseType="general"
      reason={reason}>
      <Grid as="form" gap="space24">
        <Grid columns={3} gap="space8">
          <HookFormTextField
            name={fields.reason}
            readOnly
            label={t(
              'reimbursementView.middleSection.form.general.reason.label'
            )}
            ariaLabel={t(
              'reimbursementView.middleSection.form.general.reason.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.general.reason.placeholder'
            )}
          />
          <HookFormAmountField
            name={fields.totalAmount}
            readOnly
            label={t(
              'reimbursementView.middleSection.form.general.totalAmount.label'
            )}
            placeholder={t(
              'reimbursementView.middleSection.form.general.totalAmount.placeholder'
            )}
            currency="EUR"
          />
          <HookFormDatePickerField
            name={fields.expenseDate}
            readOnly
            label={t('reimbursementView.middleSection.form.general.date.label')}
          />
        </Grid>
        <ExpenseInvoicePreviewContainer invoice={invoice} isReadOnly />
      </Grid>
    </ExpenseFormWrapper>
  );
};
