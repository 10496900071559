import { DocumentFormContainer } from 'components/Form/DocumentForm/DocumentFormContainer';
import { ProcessedDocument } from 'components/Form/DocumentForm/toolkit/types';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { DocumentStatus } from 'generated-types/graphql.types';

interface ArchivedProps {
  isLoading?: boolean;
  document?: ProcessedDocument;
}

export const Archived = ({ document, isLoading }: ArchivedProps) => {
  if (!document) {
    // TODO Add error state
    return null;
  }

  return (
    <ProcessSidebar
      documentStatus={DocumentStatus.Archived}
      globalDocumentId={document.globalDocumentId ?? undefined}>
      {/* TODO add loading state */}
      {isLoading ? null : (
        <DocumentFormContainer allowInlineEditing={false} document={document} />
      )}
    </ProcessSidebar>
  );
};
