import { PDFDetails } from 'components/DocumentViewer/utils';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  AttachFilesMutationResult,
  useAttachFilesMutation,
  useDocumentAttachmentUploadUrlMutation,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import {
  getInboxDocumentNavigationQueries,
  getInboxInvoiceDocumentsNavigationQueries,
} from 'hooks/useFetchDocNavigationData/queries';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from 'react-router-dom-v5-compat';
import { uploadFile } from 'utils/upload';
import { isSupportedFile } from 'views/AppContainer/components/Header/utils';
import { documentHistoryQuery } from 'views/queries';
import { documentQueries } from '../queries';

export const MAX_FILE_NUMBER_DATEV = 47;

export const MAX_FILES_SIZE_MB = 19;
export const MAX_FILES_SIZE = MAX_FILES_SIZE_MB * 1024 * 1024;

const fileWasAttached = (
  fileId: string,
  document: AttachFilesMutationResult['data']
): boolean => {
  const attachments = document?.attachFiles?.attachments ?? [];
  const documentAttachmentIds = attachments.map(({ id }) => id);

  return documentAttachmentIds.includes(fileId);
};

export const useFilesSelected = (
  attachments: PDFDetails[] | undefined,
  documentFile: PDFDetails | undefined,
  documentId: string
) => {
  const [t] = useTranslation();
  const { success, error } = useToastMessage();

  const [searchParams] = useSearchParams();
  const currentDocumentCursor = searchParams.get('cursor');
  const enableNewIndexInInboxViewFF = useCandisFeatureFlags(
    FEATURE_FLAGS.enableNewIndexInInboxView
  );

  // @TODO define handle upload and use that implementation here and in DocumentViewer
  const [attachFile] = useAttachFilesMutation({
    refetchQueries: [
      {
        query: documentQueries.forDraftForm,
        variables: { id: documentId },
      },
      {
        query: documentHistoryQuery,
        variables: { id: documentId },
      },
      {
        query: enableNewIndexInInboxViewFF
          ? getInboxInvoiceDocumentsNavigationQueries
          : getInboxDocumentNavigationQueries,
        variables: { cursor: currentDocumentCursor },
      },
    ],
  });

  const [documentAttachmentUploadUrl] =
    useDocumentAttachmentUploadUrlMutation();

  const [isAttachingFiles, setIsAttachingFiles] = useState(false);

  const handleUploadAttachment = useCallback(
    async (file: File) => {
      if (!isSupportedFile(file)) {
        error(t('uploads.errors.wrongFileType'));

        return;
      }

      try {
        setIsAttachingFiles(true);

        if (file.size > MAX_FILES_SIZE) {
          error(
            t('uploads.errors.totalFilesSize', { size: MAX_FILES_SIZE_MB })
          );

          return;
        }

        // request url to upload file to S3
        const { data } = await documentAttachmentUploadUrl({
          variables: {
            options: {
              contentType: file.type,
              fileName: file.name,
              size: file.size,
            },
          },
        });

        if (!data?.documentAttachmentUploadUrl.id) {
          error(t('uploads.errors.baseError'));

          return;
        }

        await uploadFile(
          file,
          data.documentAttachmentUploadUrl.postOptions || [],
          data.documentAttachmentUploadUrl.url
        );

        const { errors, data: attachFileData } = await attachFile({
          variables: {
            input: {
              documentId,
              fileIds: [data.documentAttachmentUploadUrl.id],
            },
          },
        });

        // XXX We should modify/create a new mutation to return which file ids
        // were attached and which ones were not (any why).
        const successfullyAttached = fileWasAttached(
          data.documentAttachmentUploadUrl.id,
          attachFileData
        );

        // Keep error messages around slightly longer, in case many file are attached
        // the error message might disappear before its seen.
        const ERROR_MSG_TIMEOUT = 10;

        if (successfullyAttached) {
          success(
            t('document.attachments.success.attachedFileFromDisk', {
              fileName: file.name,
            })
          );

          return;
        }

        if (!errors?.length) {
          error(
            t('document.attachments.errors.couldNotAttachFile', {
              fileName: file.name,
            }),
            ERROR_MSG_TIMEOUT
          );

          return;
        }

        error(t('uploads.errors.baseError'), ERROR_MSG_TIMEOUT);
      } catch (e) {
        throw e;
      } finally {
        setIsAttachingFiles(false);
      }
    },
    [attachFile, documentAttachmentUploadUrl, documentId, error, success, t]
  );

  const onAttachFiles = useCallback(
    (selectedFiles: File[]) => {
      // size of currently selected files
      let selectedFilesSize = 0;
      // size of currently attached files
      let attachedFilesSize = 0;

      selectedFiles.forEach(file => {
        selectedFilesSize += file.size;
      });

      attachments?.forEach(attachment => {
        attachedFilesSize += attachment.size ?? 0;
      });

      const totalSize =
        (documentFile?.size ?? 0) + selectedFilesSize + attachedFilesSize;

      if (totalSize > MAX_FILES_SIZE) {
        error(t('uploads.errors.totalFilesSize', { size: MAX_FILES_SIZE_MB }));

        return;
      }

      const filesCount = selectedFiles.length + (attachments?.length ?? 0);

      // DATEV has a restriction of max 50 files to upload:
      //    XML with document data
      //  + document
      //  + summary (audit trail)
      //  + 47 attachments
      // also consider existing attachments
      if (filesCount > MAX_FILE_NUMBER_DATEV) {
        error(t('uploads.errors.numberOfFiles'));

        return;
      }

      // TODO: call onUploadAttachment once with all attached files
      // instead of forEach
      selectedFiles.forEach(handleUploadAttachment);
    },
    [attachments, documentFile?.size, error, handleUploadAttachment, t]
  );

  return { onAttachFiles, isAttachingFiles };
};
