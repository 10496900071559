import { useApolloClient } from '@apollo/client';
import { AppRouteParams, Routes } from 'models';
import { useCallback, useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { REIMBURSEMENT_URL_PARAM } from '../Reimbursement';
import {
  reimbursementCaseByIdQuery,
  reimbursementItemsQuery,
} from '../toolkit/queries';
import { useDefaultQueryParameters } from './useDefaultQueryParameters';
import { useReimbursementsCursorBasedNavigationData } from './useReimbursementsCursorBasedNavigationData';

export type RouteType =
  | Routes.ARCHIVE
  | Routes.INBOX
  | Routes.REIMBURSEMENTS_DASHBOARD;

export interface ReimbursementRouteParams {
  reimbursementId: string;
  organizationSlug: string;
  type: RouteType;
}

interface NavigationParams {
  type: RouteType;
  cursor: string;
  organizationSlug: string;
  reimbursementId?: string;
}

interface UseReimbursementNavigationProps {
  cursor: string | null;
  organizationSlug: string;
  type: RouteType;
}

export type ReimbursementNavigationResult = {
  isLoadingNavigationData: boolean;
  reimbursementCount: number;
  prevReimbursementLink?: string;
  prevReimbursementId?: string;
  nextReimbursementLink?: string;
  nextReimbursementId?: string;
  cycleReimbursements: () => void;
};

export const REIMBURSEMENT_VIEW_ROUTES: Record<RouteType, string> = {
  [Routes.INBOX]: `/:${AppRouteParams.organizationSlug}${Routes.INBOX}${Routes.REIMBURSEMENTS}/:reimbursementId`,
  [Routes.ARCHIVE]: `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}${Routes.REIMBURSEMENTS}/:reimbursementId`,
  [Routes.REIMBURSEMENTS_DASHBOARD]: `/:${AppRouteParams.organizationSlug}${Routes.REIMBURSEMENTS_DASHBOARD}/:reimbursementId`,
};

const generateReimbursementLink = ({
  cursor,
  type,
  organizationSlug,
  reimbursementId,
}: NavigationParams): string => {
  const searchParams = new URLSearchParams(location.search);

  const pathname = generatePath(REIMBURSEMENT_VIEW_ROUTES[type], {
    organizationSlug,
    reimbursementId,
  });

  if (cursor) {
    searchParams.set(REIMBURSEMENT_URL_PARAM.CURSOR, cursor);
  }

  return `${pathname}?${searchParams.toString()}`;
};

export const useReimbursementNavigation = ({
  type,
  cursor,
  organizationSlug,
}: UseReimbursementNavigationProps): ReimbursementNavigationResult => {
  const navigate = useNavigate();
  const { filters, sortBy } = useDefaultQueryParameters(type) ?? {};

  const {
    prevReimbursementEdge,
    nextReimbursementEdge,
    isLoadingNavigationData,
    reimbursementCount,
  } = useReimbursementsCursorBasedNavigationData({
    cursor,
    filters,
    sortBy,
    skip: !cursor,
  });

  const generateLink = useCallback(
    (edge: typeof prevReimbursementEdge | typeof nextReimbursementEdge) => {
      return edge
        ? generateReimbursementLink({
            type,
            organizationSlug,
            cursor: edge.cursor,
            reimbursementId: edge.node.id,
          })
        : undefined;
    },
    [organizationSlug, type]
  );

  const nextReimbursementId = nextReimbursementEdge?.node?.id ?? undefined;
  const prevReimbursementId = prevReimbursementEdge?.node?.id ?? undefined;
  const nextReimbursementLink = generateLink(nextReimbursementEdge);
  const prevReimbursementLink = generateLink(prevReimbursementEdge);

  const cycleReimbursements = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);

    if (nextReimbursementLink) {
      navigate(nextReimbursementLink);
    } else if (prevReimbursementLink) {
      navigate(prevReimbursementLink);
    } else {
      searchParams.delete(REIMBURSEMENT_URL_PARAM.CURSOR);
      searchParams.delete(REIMBURSEMENT_URL_PARAM.VIEW);

      navigate(
        { pathname: '..', search: location.search },
        { relative: 'path' }
      );
    }
  }, [navigate, nextReimbursementLink, prevReimbursementLink]);

  return {
    isLoadingNavigationData,
    reimbursementCount,
    nextReimbursementId,
    prevReimbursementId,
    nextReimbursementLink,
    prevReimbursementLink,
    cycleReimbursements,
  };
};

export const usePrefetchReimbursementQueries = (
  prevReimbursementId?: string,
  nextReimbursementId?: string
) => {
  const { query, cache } = useApolloClient();

  useEffect(() => {
    const fetchReimbursementData = (reimbursementId: string) => {
      void query({
        query: reimbursementCaseByIdQuery,
        variables: { id: reimbursementId },
        fetchPolicy: 'network-only',
      });
      void query({
        query: reimbursementItemsQuery,
        variables: { filters: { reimbursementCaseId: reimbursementId } },
        fetchPolicy: 'network-only',
      });
    };

    const idleCallback = requestIdleCallback(() => {
      if (prevReimbursementId) {
        fetchReimbursementData(prevReimbursementId);
      }

      if (nextReimbursementId) {
        fetchReimbursementData(nextReimbursementId);
      }
    });

    return () => cancelIdleCallback(idleCallback);
  }, [prevReimbursementId, nextReimbursementId, query, cache]);
};
