import { ErrorMessages } from 'utils/zodFormValidation';
import { teamMemberFormSchema } from './teamMemberFormSchema';

export const teamMemberFormErrorMessages: ErrorMessages<
  ReturnType<typeof teamMemberFormSchema>
> = {
  firstName: {
    label: 'settings.teamMembers.form.firstName.label',
  },
  lastName: {
    label: 'settings.teamMembers.form.lastName.label',
  },
  email: {
    label: 'settings.teamMembers.form.email.label',
  },
  locale: {
    label: 'settings.teamMembers.form.locale',
  },
  roles: {
    label: 'settings.teamMembers.form.roles',
    too_small: {
      translationKey: 'settings.teamMembers.errors.roles',
    },
  },
  issueCreditCard: {
    label: 'settings.teamMembers.form.issueCreditCard.field.label',
  },
  'roles.0.id': {
    label: '',
  },
  'roles.0.name': {
    label: '',
  },
  teams: {
    label: '',
  },
  iban: {
    label: '',
  },
  swiftCode: {
    label: '',
  },
  accountsPayableNumber: {
    label: 'settings.teamMembers.form.accountPayableNumber.label',
    custom: {
      translationKey:
        'settings.teamMembers.form.accountPayableNumber.errorDuplicate',
    },
  },
};
