import gql from 'graphql-tag';

export const getOrganizationMembershipsQuery = gql`
  query organizationMemberships(
    $filters: OrganizationMembershipsFiltersInput
    $sortBy: MemberSortInput
  ) {
    organizationMemberships(filters: $filters, sortBy: $sortBy) {
      id
      name
      firstName
      lastName
      roles
      locale
      email
      avatarUrl
      status
      paymentInfo {
        iban
        swiftCode
      }
      membershipRoles {
        __typename
        id
        name
        description
        isArchived
        isBuiltinRole
        isLegacyRole
      }
    }
  }
`;

export const cardIssuerCardholders = gql`
  query getMostActiveCardholders(
    $input: CardIssuerPaginationInput!
    $filters: CardIssuerCardholdersFiltersInput
    $sortBy: CardIssuerCardholdersSortInput
  ) {
    getMostActiveCardholders: getCardIssuerCardholders(
      input: $input
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        node {
          id
          firstName
          lastName
          avatarUrl
          memberships {
            membershipId
            candisOrganizationId
          }
        }
      }
    }
  }
`;
