import { Grid } from '@candisio/design-system';
import { HookFormPaginatedComboBoxField } from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { HookFormUsersField } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { noop } from 'lodash';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { FormProvider as HookFormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from 'utils/zodFormValidation';
import { ReimbursementFormFieldOptions } from 'views/Reimbursement/hooks/types';
import { useFormValidationTrigger } from 'views/Reimbursement/hooks/useFormValidationTrigger';
import { useUpdateReimbursement } from 'views/Reimbursement/hooks/useUpdateReimbursement';
import { reimbursementFormErrorMessages } from 'views/Reimbursement/toolkit/reimbursementFormErrorMessages';
import {
  reimbursementFormSchema,
  ReimbursementFormValues,
} from 'views/Reimbursement/toolkit/reimbursementFormSchema';

const FORM_KEY = 'reimbursementForm';
type ClearableField = keyof ReimbursementFormValues;

interface ReimbursementFormProps {
  values: ReimbursementFormValues;
  defaultValues: ReimbursementFormValues;
  fieldOptions: ReimbursementFormFieldOptions;
}

export const ReimbursementForm = ({
  values,
  defaultValues,
  fieldOptions,
}: ReimbursementFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [clearable, setClearable] = useState<ClearableField>();
  const { updateReimbursement } = useUpdateReimbursement();
  const { targetMembership, suggestedCostCenter } = fieldOptions;

  const form = useForm<ReimbursementFormValues>({
    defaultValues,
    values,
    mode: 'onBlur',
    shouldFocusError: false,
    resetOptions: {
      keepErrors: true,
    },
    resolver: zodResolver({
      zodSchema: reimbursementFormSchema,
      errorMessages: reimbursementFormErrorMessages,
      translationNamespace: LOCALE_NAME_SPACE.REIMBURSEMENT,
    }),
  });

  const { getValues, handleSubmit } = form;

  const handleUpdateReimbursement = () => {
    setClearable(undefined);
    void updateReimbursement(getValues());
  };

  // initialize reference to form for triggering validation
  useFormValidationTrigger(FORM_KEY, () => void handleSubmit(noop)());

  return (
    <HookFormProvider {...form}>
      <Grid gap="space24" as="form">
        <HookFormTextField
          name="title"
          label={t('reimbursementView.rightSection.form.title.label')}
          ariaLabel={t('reimbursementView.rightSection.form.title.label')}
          placeholder={t(
            'reimbursementView.rightSection.form.title.placeholder'
          )}
          clearable={clearable === 'title'}
          onFocus={() => setClearable('title')}
          inputProps={{
            onBlur: handleUpdateReimbursement,
          }}
        />
        <HookFormUsersField
          name="targetMembership"
          readOnly
          label={t('reimbursementView.rightSection.form.targetMember.label')}
          placeholder={t('teams.createTeam.teamMembers.field.placeholder')}
          emptyListPlaceholder={t(
            'reimbursementView.rightSection.form.targetMember.noUserFound'
          )}
          {...targetMembership?.options}
        />
        {suggestedCostCenter?.isVisible && (
          <HookFormPaginatedComboBoxField
            name="suggestedCostCenter"
            clearable={clearable === 'suggestedCostCenter'}
            label={t('reimbursementView.rightSection.form.costCenter.label')}
            placeholder={t(
              'reimbursementView.rightSection.form.costCenter.placeholder'
            )}
            {...suggestedCostCenter?.options}
            onFocus={() => setClearable('suggestedCostCenter')}
            onBlur={handleUpdateReimbursement}
          />
        )}
        <HookFormTextareaField
          name="note"
          label={t('reimbursementView.rightSection.form.note.label')}
          placeholder={t(
            'reimbursementView.rightSection.form.note.placeholder'
          )}
          infoTooltip={{
            message: t('reimbursementView.rightSection.form.note.tooltip'),
          }}
          onBlur={handleUpdateReimbursement}
        />
      </Grid>
    </HookFormProvider>
  );
};
