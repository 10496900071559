import { getCellWrapperLink } from 'components/CellWrapperLink/CellWrapperLink';
import {
  EcmDocumentsTable,
  EcmDocumentsTableProps,
} from 'components/EcmDocumentsTable/EcmDocumentsTable';
import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { queryParameter } from 'components/Table/consts';
import { useEcmDocumentsTableData } from 'containers/document-relationships/useEcmDocumentsTableData';
import { DocumentPreviewDrawer } from 'containers/DocumentPreview/DocumentPreviewDrawer';
import { DocumentTableRowActionsOverlay } from 'containers/DocumentPreview/DocumentTableRowActionsOverlay';
import DocumentPreviewUpsellDrawer from 'containers/DocumentPreviewUpsell/DocumentPreviewUpsellDrawer';
import { EcmDocumentType } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { AppRouteParams, Routes } from 'models';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider/feature-flag-names';
import { useCallback, useMemo, useState } from 'react';
import {
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';
import { TabView } from 'views/Inbox/models';
import { isEcmDocumentId } from 'views/utils/isEcmDocumentId';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';

export const EcmDocuments = () => {
  const { organizationSlug } = useParams<AppRouteParams>();
  const [tableRowLinkFF] = useCandisFeatureFlags([FEATURE_FLAGS.tableRowLink]);
  const { showDocumentPreview, showDocumentPreviewPromo } = useEcm();
  const showPreview = showDocumentPreview || showDocumentPreviewPromo;

  const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(
    null
  );

  const navigate = useNavigate();

  const {
    availableDocumentColumnIds,
    searchQuery,
    searchParams,
    data,
    isLoading,
    filters,
    isTableFiltered,
    configurationsTable,
    totalCount,
    isSavingConfigurations,
    isResetPending,
    customEmptyState,
    sortBy,
    handleSearchChange,
    handleUpdateConfigurations,
    handleResetTableConfigurations,
    onFilter,
    onLoadMore,
    onSort,
  } = useEcmDocumentsTableData({
    filterParamsSource: 'url',
    previewDocumentId,
  });

  const handleRowClick = (
    documentId: string,
    documentType: EcmDocumentType,
    cursor?: string
  ) => {
    if (tableRowLinkFF) {
      return;
    }

    if (!organizationSlug) {
      return;
    }

    const pathname = generatePath(
      `/:${AppRouteParams.organizationSlug}${Routes.ECM_DOCUMENTS}/:documentId`,
      { organizationSlug, documentId }
    );

    const isInvoice = documentType === EcmDocumentType.Invoice;

    if (isInvoice) {
      searchParams.set('isInvoice', 'true');
    }

    if (cursor) {
      searchParams.set('cursor', cursor);
    }

    if (searchQuery) {
      searchParams.set(queryParameter, searchQuery);
    }

    navigate({ pathname, search: searchParams.toString() });
  };

  const getTableRowDocumentType = useCallback(
    (documentId: string) => {
      const tableRow = data.find(
        row => row.id === documentId || row.invoiceId === documentId
      );

      if (
        tableRow === undefined ||
        tableRow.documentType === EcmDocumentType.Invoice
      ) {
        return 'invoice';
      }

      return 'ecmDocument';
    },
    [data]
  );

  const documentPreviewRowOverlay: EcmDocumentsTableProps['rowOverlay'] =
    useMemo(
      () =>
        showPreview
          ? ({ data }) => {
              if (data.selected) return <></>; // TODO: Fix DS type to allow to return null

              return (
                <DocumentTableRowActionsOverlay
                  data={data}
                  onQuickViewClick={data => {
                    const isEcmDocument =
                      getTableRowDocumentType(data.id) === 'ecmDocument';

                    setPreviewDocumentId(
                      isEcmDocument ? data.id : data.invoiceId ?? ''
                    );
                  }}
                  isSelected={
                    data.id === previewDocumentId ||
                    data.invoiceId === previewDocumentId
                  }
                />
              );
            }
          : undefined,
      [showPreview, getTableRowDocumentType, previewDocumentId]
    );

  const openDocument = useCallback(
    ({
      documentId,
      cursor,
    }: {
      documentId: string | null;
      cursor: string | null;
    }): void => {
      if (!documentId) return;

      const params = new URLSearchParams();
      if (cursor) params.set('cursor', cursor);
      if (isEcmDocumentId(documentId)) params.set('isInvoice', 'true');

      const path = `/${organizationSlug}${Routes.ECM_DOCUMENTS}/${documentId}?${params}`;

      window.open(path, '_blank');
    },
    [organizationSlug]
  );

  const cursor =
    data.find(
      d => d.id === previewDocumentId || d.invoiceId === previewDocumentId
    )?.cursor ?? null;

  const documentPreviewDrawer = useMemo(() => {
    if (showDocumentPreviewPromo) {
      return (
        <DocumentPreviewUpsellDrawer
          documentId={previewDocumentId}
          closePreviewDrawer={() => setPreviewDocumentId(null)}
        />
      );
    }

    if (showDocumentPreview) {
      return (
        <DocumentPreviewDrawer
          closePreviewDrawer={() => setPreviewDocumentId(null)}
          openDocument={() =>
            openDocument({ documentId: previewDocumentId, cursor })
          }
          documentId={previewDocumentId}
        />
      );
    }

    return null;
  }, [
    showDocumentPreviewPromo,
    showDocumentPreview,
    previewDocumentId,
    openDocument,
    cursor,
  ]);

  const getPath = useCallback(
    ({ id, cursor, documentType, invoiceId }: EcmDocumentsTableData) => {
      if (!organizationSlug) {
        return '';
      }

      const isInvoice = documentType === EcmDocumentType.Invoice;

      const documentId = invoiceId && isInvoice ? invoiceId : id;

      const pathname = generatePath(
        `/:${AppRouteParams.organizationSlug}${Routes.ECM_DOCUMENTS}/:documentId`,
        { organizationSlug, documentId }
      );

      if (isInvoice) {
        searchParams.set('isInvoice', 'true');
      } else {
        searchParams.delete('isInvoice');
      }

      if (cursor) {
        searchParams.set('cursor', cursor);
      }

      if (searchQuery) {
        searchParams.set(queryParameter, searchQuery);
      }

      return { pathname, search: searchParams.toString() };
    },
    [organizationSlug, searchParams, searchQuery]
  );

  const cellWrapper = useMemo(() => {
    if (!tableRowLinkFF) {
      return;
    }

    return getCellWrapperLink(getPath);
  }, [tableRowLinkFF, getPath]);

  const cellStyle = tableRowLinkFF
    ? () => ({
        padding: 'unset',
      })
    : undefined;

  return (
    <ArchiveViewLayout
      activeTab={TabView.ARCHIVE_ECM_DOCUMENTS}
      rightSide={documentPreviewDrawer}>
      <EcmDocumentsTable
        context="documents"
        columns={availableDocumentColumnIds}
        data={data}
        isLoading={isLoading}
        defaultFilters={filters}
        isTableFiltered={isTableFiltered}
        configurationsTable={configurationsTable}
        searchQuery={searchQuery}
        selectedDocumentsCount={totalCount}
        defaultSortBy={sortBy}
        isLoadingConfigurations={isSavingConfigurations || isResetPending}
        onRowClick={handleRowClick}
        onSearchChange={handleSearchChange}
        onUpdateConfigurations={handleUpdateConfigurations}
        onFilter={onFilter}
        onEndReached={onLoadMore}
        onSort={onSort}
        customEmptyState={customEmptyState}
        onResetTableConfigurations={handleResetTableConfigurations}
        rowOverlay={documentPreviewRowOverlay}
        cellWrapper={cellWrapper}
        getCellStyles={cellStyle}
      />
    </ArchiveViewLayout>
  );
};
