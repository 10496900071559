import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { isNil } from 'lodash';
import { useState, useCallback } from 'react';
import { UseFieldArrayRemove, UseFormReturn } from 'react-hook-form';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { useDeleteExpense } from 'views/Reimbursement/hooks/useDeleteExpense';
import { useUpdateGeneralExpense } from 'views/Reimbursement/hooks/useUpdateGeneralExpense';
import { useUpdateHospitalityExpense } from 'views/Reimbursement/hooks/useUpdateHospitalityExpense';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/Reimbursement';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { useReimbursementContext } from 'views/Reimbursement/toolkit/ReimbursementContext/ReimbursementProvider';

export type BooleanMap = { [key: string]: boolean };
interface UseExpensesFormActionsParams {
  formMethods: UseFormReturn<ExpensesFormOutput>;
  createExpenseInvoice: (file: File) => Promise<ExpenseInvoice | undefined>;
  onRemoveForm: UseFieldArrayRemove;
}

export const useExpensesFormActions = ({
  createExpenseInvoice,
  onRemoveForm,
  formMethods,
}: UseExpensesFormActionsParams) => {
  const [isCreatingInvoice, setIsCreatingInvoice] = useState<BooleanMap>({});
  const { handleSetVisibleElementId } = useReimbursementContext();
  const [isDeletingForm, setIsDeletingForm] = useState<BooleanMap>({});
  const [clearableField, setClearableField] = useState<string>();
  const { updateLastModifiedExpense } = useReimbursementFormsContext();
  const { updateHospitalityExpense } = useUpdateHospitalityExpense();
  const { updateGeneralExpense } = useUpdateGeneralExpense();
  const { deleteExpense } = useDeleteExpense();
  const { updateSearchParam } = useMutateSearchParams();
  const { setValue, getValues, watch } = formMethods;

  const handleClearableField = useCallback(
    (field?: string) => () => {
      setClearableField(field);
    },
    []
  );

  const handleUpdateHospitality = useCallback(
    (index: number) => () => {
      const formValues = getValues(`expenses.${index}`);
      updateLastModifiedExpense(index);
      handleClearableField(undefined)();
      void updateHospitalityExpense(formValues);
    },
    [
      getValues,
      handleClearableField,
      updateHospitalityExpense,
      updateLastModifiedExpense,
    ]
  );

  const handleUpdateGeneralExpense = useCallback(
    (index: number) => () => {
      const formValues = getValues(`expenses.${index}`);
      updateLastModifiedExpense(index);
      setClearableField(undefined);
      void updateGeneralExpense(formValues);
    },
    [getValues, updateGeneralExpense, updateLastModifiedExpense]
  );

  const handleDeleteForm = useCallback(
    (index: number, expenseId: string) => async () => {
      setIsDeletingForm(prev => ({ ...prev, [index]: true }));

      const deleted = await deleteExpense(expenseId);
      setIsDeletingForm(prev => ({ ...prev, [index]: false }));

      if (!deleted) return;
      onRemoveForm(index);
      const expenses = watch('expenses');
      const expensesLength = expenses.length;

      const isDeletedExpenseLast = expensesLength === index;

      if (isDeletedExpenseLast) {
        const visibleExpenseId = getValues(`expenses.${index - 1}.expenseId`);
        handleSetVisibleElementId(visibleExpenseId);
      } else {
        const visibleExpenseId = getValues(`expenses.${index}.expenseId`);
        handleSetVisibleElementId(visibleExpenseId);
      }

      if (!expensesLength) {
        handleSetVisibleElementId(undefined);
        updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, '');
      }
    },
    [
      deleteExpense,
      getValues,
      handleSetVisibleElementId,
      onRemoveForm,
      updateSearchParam,
      watch,
    ]
  );

  const handleInvoiceChange = useCallback(
    async (file: File, index: number) => {
      setIsCreatingInvoice(prev => ({ ...prev, [index]: true }));

      const invoice = await createExpenseInvoice(file);

      if (!invoice) {
        setIsCreatingInvoice(prev => ({ ...prev, [index]: false }));

        return;
      }

      setValue(`expenses.${index}.files`, [invoice]);

      setIsCreatingInvoice(prev => ({ ...prev, [index]: false }));
    },

    [createExpenseInvoice, setValue]
  );

  const handleReplaceInvoiceHospitality = useCallback(
    (index: number) => () => {
      setValue(`expenses.${index}.files`, [], { shouldValidate: true });
      handleUpdateHospitality(index)();
    },
    [handleUpdateHospitality, setValue]
  );

  const handleReplaceInvoiceGeneral = useCallback(
    (index: number) => () => {
      setValue(`expenses.${index}.files`, [], { shouldValidate: true });
      handleUpdateGeneralExpense(index)();
    },
    [handleUpdateGeneralExpense, setValue]
  );

  const handleUpdateTotalAmount = useCallback(
    (index: number) => {
      const grossAmount = watch(`expenses.${index}.receiptAmount`);
      const tipAmount = watch(`expenses.${index}.tipAmount`);

      if (isNil(grossAmount) && isNil(tipAmount)) {
        setValue(`expenses.${index}.totalAmount`, null, {
          shouldValidate: true,
        });

        return;
      }

      const totalAmount = (grossAmount ?? 0) + (tipAmount ?? 0);

      setValue(`expenses.${index}.totalAmount`, totalAmount, {
        shouldValidate: true,
      });
    },
    [setValue, watch]
  );

  return {
    isDeletingForm,
    clearableField,
    isCreatingInvoice,
    handleClearableField,
    handleUpdateHospitality,
    handleDeleteForm,
    handleInvoiceChange,
    handleReplaceInvoiceGeneral,
    handleReplaceInvoiceHospitality,
    handleUpdateGeneralExpense,
    handleUpdateTotalAmount,
  };
};
