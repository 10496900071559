import { Link, Text } from '@candisio/design-system';

import { ProcessingFormSubmitErrors } from 'components/Form/ProcessingForm/ProcessingForm';
import { ProcessingFormValues } from 'components/Form/ProcessingForm/processingFormSchema';
import { useToastMessage } from 'components/Toast/useToastMessage';
import {
  ArtistSocialInsuranceCode,
  DocumentCategory,
  DocumentCurrency,
  DocumentFastApproveInput,
  DocumentStatus,
  useAvailableDocumentCategoriesQuery,
  useDocumentFastApproveMutation,
  useGetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useCounterQueries } from 'hooks/useCounterQueries';
import { useDateConverter } from 'hooks/useDateConverter';
import { isNil } from 'lodash';
import { AppRouteParams, DocumentProcessingRouteParams, Routes } from 'models';
import { useSap } from 'orgConfig/sap';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { Trans } from 'providers/LocaleProvider';
import { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat';
import { isNilOrEmpty } from 'utils/isNilOrEmpty';
import { DocumentDirection } from 'views/utils/DocumentDirection';
import { useShowAccountingAreaField } from 'views/utils/useShouldShowField';
import { DOCUMENT_TYPES } from './consts';
import { getBookingInputDiscountAmount } from './getBookingInputDiscountAmount';
import { useGetFastApproveSubmitErrors } from './useGetFastApproveSubmitErrors';

export const DOCUMENT_FAST_APPROVER_SUCCESS_NOTIFICATION_TEST_ID =
  'document-fast-approval-success-notification';

export const useFormValuesToFastApproveInput = () => {
  const [purchaseOrderNumberFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.purchaseOrderNumber,
  ]);

  const formValuesToFastApproveInput = useCallback(
    (
      values: ProcessingFormValues,
      {
        category,
        hasTransactionLinked,
        shouldIncludeCashDiscount,
        netAndTaxAmountFF,
        shouldUseSapNetAmount,
        isSapCreditMemoType,
        status,
        dateValuesFormat,
        calculatePaymentFieldValue,
        shouldUseAccountingArea,
        shouldUsePackageFreight,
      }: {
        category: DocumentCategory;
        hasTransactionLinked: boolean;
        shouldIncludeCashDiscount: boolean;
        netAndTaxAmountFF: boolean;
        shouldUseAccountingArea: boolean;
        shouldUseSapNetAmount: boolean;
        isSapCreditMemoType: boolean;
        status: DocumentStatus;
        shouldUsePackageFreight: boolean;
        dateValuesFormat: (isoDateString: string) => string;
        calculatePaymentFieldValue: (
          isSapCreditMemoType: boolean,
          hasTransactionLinked: boolean,
          value: string | null | undefined
        ) => string | null | undefined;
      }
    ): DocumentFastApproveInput => ({
      amount: values.grossAmount,
      createTransfer:
        // Create transfer is `false` when document has transaction linked
        isSapCreditMemoType
          ? false
          : !hasTransactionLinked && values.createTransfer,
      bookings: values.bookings.map(booking => ({
        amount: booking.amount,
        artistSocialInsuranceCode: !isNil(booking.artistSocialInsuranceCode)
          ? (booking.artistSocialInsuranceCode as ArtistSocialInsuranceCode)
          : null,
        bookingKeyId: booking.taxCode ?? null,
        costCenterId: booking.costCenter.value ?? null,
        costObjectId: booking.costObject.value ?? null,
        ...(shouldIncludeCashDiscount
          ? {
              discountAmount:
                values.bookings.length === 1
                  ? values.discountAmount ?? null
                  : getBookingInputDiscountAmount(values, booking),
              discountPaymentDate: values.discountDate
                ? dateValuesFormat(values.discountDate)
                : null,
              discountPercentage: values.discountPercentage ?? null,
              paymentConditionId: values.paymentCondition ?? null,
            }
          : {
              discountAmount: null,
              discountPaymentDate: null,
              discountPercentage: null,
              paymentConditionId: null,
            }),
        dueDate: booking.dueDate ? dateValuesFormat(booking.dueDate) : null,
        extraCostInfoId: booking.extraCostInfo.value ?? null,
        generalLedgerAccountId: booking.generalLedgerAccount.value ?? null,
        id: booking.bookingId ?? null,
        note: booking.note ?? null,
        postingText: booking.postingText ?? null,
        vatRate: booking.vatRate ?? null,
        quantity: booking?.quantity ?? undefined,
        taxAmount: netAndTaxAmountFF ? booking?.taxAmount : undefined,
        netAmount: netAndTaxAmountFF ? booking?.netAmount : undefined,
        sapExpenseType: shouldUsePackageFreight
          ? booking.sapExpenseType
          : undefined,
      })),
      category: {
        documentType: category.documentType,
        direction: category.direction,
      },
      contactId: values.contact.value,
      currency: values.currency as DocumentCurrency,
      iban: calculatePaymentFieldValue(
        isSapCreditMemoType,
        hasTransactionLinked,
        values.iban
      ),
      swiftCode: calculatePaymentFieldValue(
        isSapCreditMemoType,
        hasTransactionLinked,
        values.swiftCode
      ),
      invoiceDate: dateValuesFormat(values.invoiceDate),
      invoiceDeliveryDate: values.deliveryDate
        ? dateValuesFormat(values.deliveryDate)
        : null,
      invoicePostingDate: values.postingDate
        ? dateValuesFormat(values.postingDate)
        : null,
      accountingAreaId:
        shouldUseAccountingArea && values.accountingArea.value
          ? values.accountingArea.value
          : undefined,
      invoiceNumber: values.invoiceNumber,
      status,
      roundingAmount: shouldUseSapNetAmount ? values.roundingAmount : undefined,
      purchaseOrderNumber:
        purchaseOrderNumberFF && !isNil(values.purchaseOrderNumber)
          ? values.purchaseOrderNumber
          : null,
    }),
    [purchaseOrderNumberFF]
  );

  return formValuesToFastApproveInput;
};

type FastApprove = (
  values: ProcessingFormValues
) => Promise<
  | { status: 'success' }
  | { status: 'error'; submitErrors?: ProcessingFormSubmitErrors }
>;

/**
 * Fast approves document with submitted values from processing form.
 *
 * Excludes the following form fields when document has linked transaction:
 * - IBAN
 * - create transfer (sets to `false`)
 * - payment condition
 * - discount date, percentage and amount
 *
 * Excludes payment condition, discount date, percentage and amount fields when
 * gross amount is negative or document type does not have incoming direction
 *
 * @todo it might make sense to perform the above normalizations inside the
 * processing form instead of here(?)
 */
export const useFastApprove = (documentId?: string) => {
  const [t] = useTranslation();
  const { success, error, warning, dismiss } = useToastMessage();
  const { dateStringToDateTimeString } = useDateConverter();
  const navigate = useNavigate();
  const sap = useSap();
  const [netAndTaxAmountFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.netAndTaxAmount,
  ]);

  const getSubmitErrors = useGetFastApproveSubmitErrors();
  const formValuesToFastApproveInput = useFormValuesToFastApproveInput();

  const { organizationSlug } = useParams<AppRouteParams>();

  const { data: documentData } = useGetDocumentForDraftQuery({
    variables: { id: documentId as string },
    skip: typeof documentId !== 'string',
  });

  const hasTransactionLinked =
    documentData?.getDocument?.hasTransactionLinked ?? false;

  const shouldUseAccountingArea = useShowAccountingAreaField({
    isAccountingAreaOnDocument: !isNilOrEmpty(
      documentData?.getDocument?.accountingArea?.value.id
    ),
  });

  const status = documentData?.getDocument?.status ?? DocumentStatus.New;
  const wasDuplicate = documentData?.getDocument?.isDuplicate ?? false;

  const { data: availableDocumentCategoriesData } =
    useAvailableDocumentCategoriesQuery();

  const availableDocumentCategories =
    availableDocumentCategoriesData?.availableDocumentCategories ?? [];

  const counterQueries = useCounterQueries();
  const [mutation] = useDocumentFastApproveMutation({
    awaitRefetchQueries: true,
    refetchQueries: [...counterQueries],
  });

  const approve: FastApprove = async values => {
    if (isNil(status) || typeof documentId !== 'string') {
      return { status: 'error' };
    }

    const category = availableDocumentCategories.find(
      category => category.documentType === values.type
    );

    if (!category) {
      return { status: 'error' };
    }

    const isSapCreditMemoType =
      sap.isActiveIntegration &&
      values.type === DOCUMENT_TYPES.EINGANGSGUTSCHRIFT;

    // Only include cash discount fields when document does not have linked
    // transaction, gross amount is positive and selected document type has
    // incoming direction
    const shouldIncludeCashDiscount =
      !hasTransactionLinked &&
      values.grossAmount > 0 &&
      category.direction === DocumentDirection.invoices_received;

    const calculatePaymentFieldValue = (
      isSapCreditMemoType: boolean,
      hasTransactionLinked: boolean,
      value: string | null | undefined
    ) => {
      if (isSapCreditMemoType) {
        return null;
      }

      // Exclude swift code when document has transaction linked
      return !hasTransactionLinked ? value : null;
    };

    const { data } = await mutation({
      variables: {
        id: documentId,
        input: formValuesToFastApproveInput(values, {
          hasTransactionLinked,
          shouldIncludeCashDiscount,
          category,
          status,
          netAndTaxAmountFF,
          shouldUseAccountingArea,
          shouldUseSapNetAmount: sap.shouldUseSapNetAmount,
          shouldUsePackageFreight: sap.shouldUsePackageFreight,
          isSapCreditMemoType,
          calculatePaymentFieldValue,
          dateValuesFormat: dateStringToDateTimeString,
        }),
        options: {
          duplicateCheckByContent: true,
        },
      },
    });

    if (data?.documentFastApprove?.__typename === 'Document') {
      const { documentFile, id, isDuplicate } = data.documentFastApprove;
      const duplicateDetected = !wasDuplicate && isDuplicate;

      if (duplicateDetected) {
        warning(
          <>
            <Text>{t('inbox:duplicate.contentWarning')}</Text>
            <br />
            <Text>{t('inbox:duplicate.contentNextStep')}</Text>
          </>
        );

        return { status: 'error' };
      }

      const path = organizationSlug
        ? generatePath(
            `/:${AppRouteParams.organizationSlug}${Routes.ARCHIVE}/:${DocumentProcessingRouteParams.documentId}`,
            { organizationSlug, documentId: id }
          )
        : undefined;

      success(
        <Text data-testid={DOCUMENT_FAST_APPROVER_SUCCESS_NOTIFICATION_TEST_ID}>
          <Trans
            i18nKey="document.approveDocument.fastApproval.successMessage"
            values={{ name: documentFile?.name }}>
            Document
            <Link
              href={path}
              onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                dismiss();
                if (path) navigate(path);
              }}>
              {{ name: documentFile?.name } as any}
            </Link>
            was approved.
          </Trans>
        </Text>
      );

      return { status: 'success' };
    } else if (
      data?.documentFastApprove?.__typename === 'DocumentFastApproveErrors'
    ) {
      const submitErrors = getSubmitErrors(data?.documentFastApprove);

      return { status: 'error', submitErrors };
    }

    error(t('common.genericErrorMessage'));

    return { status: 'error' };
  };

  return approve;
};
