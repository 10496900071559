import {
  Button,
  Grid,
  InlineSkeleton,
  Item,
  ListView,
  Text,
  TruncatedText,
} from '@candisio/design-system';
import { useBookingsFieldArrayContext } from 'components/Form/DocumentApprovalsForm/BookingsFieldArrayContext';
import { DocumentApprovalFormValues } from 'components/Form/DocumentApprovalsForm/toolkit/approvalFormSchema';
import { useSumOfBookingsAmounts } from 'components/Form/ProcessingForm/hooks/useSumOfBookingsAmounts';
import { ProcessingFormSplitItemProps } from 'components/Form/ProcessingForm/ProcessingFormSplitItem';
import { PurchaseOrderSplitItem } from 'components/PurchaseOrders/PurchaseOrderSplitItem';
import { useSap } from 'orgConfig/sap';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNumberFormat } from 'utils/useNumberFormat';
import {
  useShowCostCenterField,
  useShowCostObjectField,
} from 'views/utils/useShouldShowField';

export interface SplitBookingsListProps {
  onSplitDocument?: (index?: number) => void;
  readOnly?: boolean;
  isLoading?: boolean | undefined;
}

const customStyle = 'auto 1fr 1fr 1.5fr';

const ApprovalsFormSplitItem = ({
  number,
  name,
  onEdit,
  readOnly,
  hasRemainingAmount,
}: ProcessingFormSplitItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);
  const { shouldUseSapPurchaseOrder, shouldUseSapNetAmount } = useSap();

  const {
    amount,
    vatRate,
    costCenter,
    taxCode,
    costObject,
    quantity,
    note,
    sapExpenseType,
  } = useWatch<DocumentApprovalFormValues, typeof name>({ name });

  const fieldIndex = Number(name.split('.')[1]);

  const showCostCenterField = useShowCostCenterField();
  const showCostObjectField = useShowCostObjectField();

  const isOrgUsingOnlyCostObjects = !showCostCenterField && showCostObjectField;

  const hasCostCenterInput = costCenter && costCenter.inputValue;
  const hasCostObjectInput =
    isOrgUsingOnlyCostObjects && costObject && costObject.inputValue;

  const showVatRate =
    typeof vatRate === 'number' && (shouldUseSapNetAmount ? !!taxCode : true);

  const currency = useWatch<DocumentApprovalFormValues, 'currency'>({
    name: 'currency',
  });

  const { getFieldState } = useFormContext();
  const { invalid } = getFieldState(name);

  const amountFormatter = useNumberFormat({
    style: currency ? 'currency' : 'decimal',
    currency: currency ?? undefined,
  });

  const percentFormatter = useNumberFormat({
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'percent',
  });

  const showRemainingError = shouldUseSapNetAmount ? false : hasRemainingAmount;

  const hasError = invalid || showRemainingError;

  return (
    <Grid
      gap="space8"
      templateColumns="1fr space24"
      alignItems="center"
      minHeight="space24"
      padding="space8 space12"
      background={hasError ? 'redbg' : undefined}
      cursor="pointer">
      <Grid
        gap="space8"
        templateColumns={
          shouldUseSapPurchaseOrder ? customStyle : '1fr 2fr 2fr 3fr'
        }>
        <TruncatedText color="gray400">{String(number)}</TruncatedText>
        {typeof amount === 'number' ? (
          <TruncatedText>{amountFormatter.format(amount)}</TruncatedText>
        ) : (
          <Text>–</Text>
        )}
        {shouldUseSapPurchaseOrder ? (
          <PurchaseOrderSplitItem
            note={note}
            fieldIndex={fieldIndex}
            quantity={sapExpenseType ? undefined : quantity}
          />
        ) : (
          <>
            {showVatRate ? (
              <TruncatedText>
                {percentFormatter.format(vatRate / 100)}
              </TruncatedText>
            ) : (
              <Text>–</Text>
            )}

            {/* DocumentForm passes costCenterID */}
            {hasCostCenterInput && (
              // @ts-ignore costCenter.inputValue is available hasCostCenterInput is true
              <TruncatedText>{costCenter.inputValue}</TruncatedText>
            )}
            {!hasCostCenterInput && hasCostObjectInput && (
              // @ts-ignore costObject.inputValue is available hasCostObjectInput is true
              <TruncatedText>{costObject.inputValue}</TruncatedText>
            )}
            {!hasCostCenterInput && !hasCostObjectInput && <Text>–</Text>}
          </>
        )}
      </Grid>
      {onEdit !== undefined && (
        <Button
          color="blue"
          icon={readOnly ? 'view' : 'edit'}
          label={readOnly ? t('viewSplitIcon') : t('editSplitIcon')}
          onClick={onEdit}
          size="xsmall"
          variant="tertiary"
        />
      )}
    </Grid>
  );
};

export const SplitBookingsList = ({
  onSplitDocument,
  readOnly,
  isLoading,
}: SplitBookingsListProps) => {
  const { shouldUseSapPurchaseOrder } = useSap();
  const [grossAmount, bookings] =
    useWatch<DocumentApprovalFormValues, ['grossAmount', 'bookings']>({
      name: ['grossAmount', 'bookings'],
    }) ?? [];

  const bookingsFieldArray = useBookingsFieldArrayContext();
  const splits = bookingsFieldArray?.fields ?? [];
  const showSplit =
    (shouldUseSapPurchaseOrder && splits.length > 0) || splits.length > 1;

  /** make sure to use bookings from the watch hook instead of the context
   * to avoid data inconsistency issues */
  const sumOfBookingsAmounts = useSumOfBookingsAmounts({ bookings });
  const remainingAmount = grossAmount - sumOfBookingsAmounts;

  return (
    <InlineSkeleton isLoading={isLoading} isBlockLevel>
      <Grid borderRadius="medium" overflow="hidden">
        {showSplit && (
          <ListView
            data-cy="approvals-split-list"
            onAction={key => {
              onSplitDocument?.(Number(key));
            }}>
            {splits.map(({ id }, index) => (
              <Item
                key={String(index)} // passed to onAction
                textValue={id}>
                <ApprovalsFormSplitItem
                  key={id} // used by React Hook Form to trigger rerenders
                  name={`bookings.${index}`}
                  number={index + 1}
                  onEdit={() => {
                    onSplitDocument?.(index);
                  }}
                  readOnly={readOnly}
                  hasRemainingAmount={Boolean(remainingAmount)}
                />
              </Item>
            ))}
          </ListView>
        )}
      </Grid>
    </InlineSkeleton>
  );
};
