import { Button, Grid } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const FirstApproverActions = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Grid columns={2} gap="space10">
      <Button color="blue">
        {t('reimbursementView.rightSection.approvedFooterAction.approve')}
      </Button>
      <Button color="blue" variant="secondary">
        {t('reimbursementView.rightSection.approvedFooterAction.reject')}
      </Button>
    </Grid>
  );
};
