import { Grid, ScrollBox } from '@candisio/design-system';
import {
  FieldArray,
  FormProvider,
  UseFieldArrayRemove,
  UseFormReturn,
} from 'react-hook-form';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { useCreateExpenseInvoice } from 'views/Reimbursement/hooks/useCreateExpenseInvoice';
import { useUpdateVisibleElement } from 'views/Reimbursement/hooks/useUpdateVisibleElement';
import { ExpensesFormOutput } from 'views/Reimbursement/toolkit/expensesFormSchema';
import { GeneralExpensesForm } from './GeneralExpenses/GeneralExpensesForm';
import { HospitalityExpenseForm } from './HospitalityExpenseForm';
import { useExpensesFormActions } from './utils/useExpensesFormActions';

interface ExpensesFormsContainerProps {
  onRemoveForm: UseFieldArrayRemove;
  formMethods: UseFormReturn<ExpensesFormOutput>;
  forms: FieldArray<ExpensesFormOutput, 'expenses'>[];
}

export const ExpensesFormsContainer = ({
  forms,
  formMethods,
  onRemoveForm,
}: ExpensesFormsContainerProps) => {
  const { createExpenseInvoice } = useCreateExpenseInvoice();
  const { reimbursementUIConfig } = useReimbursementFormsContext();
  const { isExpenseFormEditable, canManageReimbursementItems } =
    reimbursementUIConfig;

  const {
    clearableField,
    handleClearableField,
    handleDeleteForm,
    handleInvoiceChange,
    handleReplaceInvoiceHospitality,
    handleUpdateHospitality,
    handleReplaceInvoiceGeneral,
    handleUpdateGeneralExpense,
    handleUpdateTotalAmount,
    isCreatingInvoice,
    isDeletingForm,
  } = useExpensesFormActions({
    createExpenseInvoice,
    formMethods,
    onRemoveForm,
  });

  const { ref } = useUpdateVisibleElement({
    forms,
  });

  return (
    <ScrollBox scrollDirection="y" ref={ref}>
      <FormProvider {...formMethods}>
        <Grid gap="space24">
          {forms.map((form, index) => {
            if (form.expenseType === 'hospitality') {
              return (
                <HospitalityExpenseForm
                  key={form.expenseId}
                  expenseId={form.expenseId}
                  formIndex={index}
                  isReadOnly={!isExpenseFormEditable}
                  clearableField={clearableField}
                  isCreatingInvoice={isCreatingInvoice}
                  isDeletingForm={isDeletingForm}
                  onReplaceInvoice={handleReplaceInvoiceHospitality}
                  onUpdateHospitality={handleUpdateHospitality}
                  formMethods={formMethods}
                  onRemoveForm={handleDeleteForm}
                  onInvoiceChange={handleInvoiceChange}
                  onCleareableField={handleClearableField}
                  onUpdateTotalAmount={handleUpdateTotalAmount}
                  canManageReimbursementItems={canManageReimbursementItems}
                />
              );
            }

            return (
              <GeneralExpensesForm
                key={form.expenseId}
                expenseId={form.expenseId}
                formIndex={index}
                isReadOnly={!isExpenseFormEditable}
                clearableField={clearableField}
                isCreatingInvoice={isCreatingInvoice}
                isDeletingForm={isDeletingForm}
                onReplaceInvoice={handleReplaceInvoiceGeneral}
                onUpdateExpense={handleUpdateGeneralExpense}
                formMethods={formMethods}
                onRemoveForm={handleDeleteForm}
                onInvoiceChange={handleInvoiceChange}
                onCleareableField={handleClearableField}
                canManageReimbursementItems={canManageReimbursementItems}
              />
            );
          })}
        </Grid>
      </FormProvider>
    </ScrollBox>
  );
};
