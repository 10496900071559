import {
  Box,
  Button,
  defaultTheme,
  Flex,
  Grid,
  Tag,
  Text,
} from '@candisio/design-system';
import {
  LottieAnimation,
  documentPreviewUpsellEN,
  documentPreviewUpsellDE,
} from 'components/Lottie/Lottie';
import { motion } from 'framer-motion';
import i18next from 'i18next';
import { Trans } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface DocumentPreviewUpsellProps {
  onClose: () => void;
  showContent: boolean;
}

export default function DocumentPreviewUpsell(
  props: DocumentPreviewUpsellProps
) {
  const [t] = useTranslation();

  return (
    <Grid
      background="white"
      templateRows="auto minmax(0, 1fr)"
      height="100%"
      borderTop="1px solid gray250">
      <Grid
        justifyContent="space-between"
        alignItems="center"
        autoFlow="column"
        padding="space16 space16 space16 space24"
        borderBottom="1px solid gray250">
        <Grid>
          <Text fontSize="xlarge" fontWeight="semibold" lineHeight="paragraph">
            {t('documentPreview.title')}
          </Text>
        </Grid>
        <Grid autoFlow="column" justifyContent="center" alignItems="center">
          <Button variant="tertiary" icon="close" onClick={props.onClose} />
        </Grid>
      </Grid>
      <Flex direction="column" height="100%">
        <Flex
          background="purple100"
          justifyContent="center"
          alignItems="center"
          paddingX="space32"
          paddingY="space64"
          height="386">
          <LottieAnimation
            width="100%"
            height="100%"
            options={{
              loop: true,
              animationData: lottieAnimationMap[i18next.language],
            }}
          />
        </Flex>
        {props.showContent && (
          <MotionGrid
            animate={props.showContent ? 'open' : 'close'}
            initial={{ opacity: 0 }}
            variants={{
              open: { opacity: 1 },
              close: { opacity: 0 },
            }}
            overflow="auto"
            justifyContent="start"
            padding="space32"
            gap="space16">
            <Tag color="purple" variant="secondary" size="large">
              {t('tag.addOn')}
            </Tag>
            <Text
              color="gray700"
              fontWeight="regular"
              fontSize="xxlarge"
              whiteSpace="pre-line">
              {t('documentPreview.upSell.title')}
            </Text>
            <Grid gap="space24">
              <Text color="gray600" fontWeight="regular" fontSize="basic">
                {t('documentPreview.upSell.description')}
              </Text>
              <MotionButton
                width="fit-content"
                color="purple"
                size="medium"
                icon="arrowRight"
                iconPosition="right"
                fontWeight="semibold"
                style={{ color: 'white' }}
                whileHover={{ color: defaultTheme.colors.gray0 }}
                as="a"
                target="_blank"
                rel="noopener noreferer"
                href={t('documentPreview.upSell.cta.url')}>
                {t('documentPreview.upSell.cta.text')}
              </MotionButton>
            </Grid>
            <Text color="gray500" fontSize="basic" whiteSpace="pre-line">
              <Trans t={t} i18nKey="documentPreview.upSell.footnote.text">
                <Box
                  as="a"
                  color="gray800"
                  target="_blank"
                  rel="noopener noreferer"
                  href={t('documentPreview.upSell.footnote.url')}
                  style={{ textDecoration: 'underline' }}
                />
              </Trans>
            </Text>
          </MotionGrid>
        )}
      </Flex>
    </Grid>
  );
}

const MotionButton = motion(Button);
const MotionGrid = motion(Grid);
const lottieAnimationMap: Record<string, any> = {
  de: documentPreviewUpsellDE,
  en: documentPreviewUpsellEN,
};
