import Lottie, { LottieProps } from 'react-lottie';
import documentPreviewUpsellDE from '../../assets/lottie/documentPreviewUpsellDE.json';
import documentPreviewUpsellEN from '../../assets/lottie/documentPreviewUpsellEN.json';
import accountsPayableNumberNotMissing from '../../assets/lottie/lf-accountspayablenumbernotmissing.json';
import documentdeleteData from '../../assets/lottie/lf-documentdelete.json';
import documenterrorData from '../../assets/lottie/lf-documenterror.json';
import logoData from '../../assets/lottie/lf-logo.json';
import manageRelationshipsPreview from '../../assets/lottie/lf-manage-relationships-preview.json';
import nocontactData from '../../assets/lottie/lf-nocontact.json';
import nodocumentData from '../../assets/lottie/lf-nodocument.json';
import noDocumentsInTable from '../../assets/lottie/lf-nodocumentsintable.json';
import noemailData from '../../assets/lottie/lf-noemail.json';
import nohistoryData from '../../assets/lottie/lf-nohistory.json';
import noPayableDocuments from '../../assets/lottie/lf-nopayabledocuments.json';
import noresultsData from '../../assets/lottie/lf-noresults.json';
import pulseBlue from '../../assets/lottie/lf-pulse-blue.json';
import pulsePurple from '../../assets/lottie/lf-pulse-purple.json';
import pulseRed from '../../assets/lottie/lf-pulse-red.json';
import successData from '../../assets/lottie/lf-success.json';
import workflowData from '../../assets/lottie/lf-workflow.json';
import reportDE from '../../assets/lottie/reportDE.json';
import reportEN from '../../assets/lottie/reportEN.json';

export const LottieAnimation = ({
  height = 140,
  width = 140,
  options,
  ...props
}: LottieProps) => {
  return (
    <Lottie
      options={{
        loop: false,
        autoplay: true,
        ...options,
      }}
      height={height}
      width={width}
      {...props}
    />
  );
};

export {
  accountsPayableNumberNotMissing,
  documentdeleteData,
  documenterrorData,
  logoData,
  noDocumentsInTable,
  noPayableDocuments,
  nocontactData,
  nodocumentData,
  noemailData,
  nohistoryData,
  noresultsData,
  pulseBlue,
  pulsePurple,
  pulseRed,
  reportDE,
  reportEN,
  successData,
  workflowData,
  manageRelationshipsPreview,
  documentPreviewUpsellEN,
  documentPreviewUpsellDE,
};
