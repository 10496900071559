// eslint-disable-next-line no-restricted-imports
import { Mentions } from 'antd/es';
import { UserWithAbsence } from 'containers/absence/UserWithAbsence';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  COMMENT_LENGTH_MAX,
  COMMENT_LENGTH_MIN,
  COMMENT_TEST_ID,
} from './constants';
import { MentionsInput } from './styles';
import { CommentFormValues, CommentProps } from './types';

export type CommentMentionsInputProps = {
  allowMentions?: boolean;
  isDisabled: boolean;
  memberships: CommentProps['memberships'];
};

export const CommentMentionsInput = ({
  allowMentions,
  isDisabled,
  memberships,
}: CommentMentionsInputProps) => {
  const { t } = useTranslation();
  const form = useFormContext<CommentFormValues>();
  const { isDocumentRelationsAvailable } = useEcm();

  const [searchStr, setSearchStr] = useState('');
  const membershipsFiltered = !allowMentions
    ? []
    : memberships.filter(u =>
        u.name.toLowerCase().includes(searchStr.toLowerCase())
      );

  const notFoundContentLabel = t(
    allowMentions ? 'inputs.noUserFound' : 'inputs.notAllowed'
  );

  const placeholder = t(
    allowMentions
      ? 'comment.placeholder'
      : 'comment.mentioningRestrictedPlaceholder'
  );

  return (
    <Controller
      render={({ field }) => (
        <MentionsInput
          {...field}
          autoSize={{ minRows: 1, maxRows: 20 }}
          data-testid={COMMENT_TEST_ID}
          disabled={isDisabled}
          data-show-document-relations={isDocumentRelationsAvailable}
          maxLength={COMMENT_LENGTH_MAX}
          minLength={COMMENT_LENGTH_MIN}
          notFoundContent={<span>{notFoundContentLabel}</span>}
          onChange={value => form.setValue('message', value)}
          onSearch={setSearchStr}
          onSelect={({ key }) => {
            if (key) {
              form.setValue(
                'mentionIds',
                form.getValues('mentionIds').concat(key)
              );
            }
          }}
          placeholder={placeholder}
          placement="top">
          {membershipsFiltered.map(({ name, id, avatarUrl }) => (
            <Mentions.Option key={id} value={name}>
              <UserWithAbsence label={name} value={id} image={avatarUrl} />
            </Mentions.Option>
          ))}
        </MentionsInput>
      )}
      name="message"
      control={form.control}
      defaultValue=""
    />
  );
};
