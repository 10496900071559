import { Grid } from '@candisio/design-system';
import { HookFormPaginatedComboBoxField } from 'components/HookFormFields/HookFormPaginatedComboBoxField';
import { HookFormTextareaField } from 'components/HookFormFields/HookFormTextareaField';
import { HookFormTextField } from 'components/HookFormFields/HookFormTextField';
import { HookFormUsersField } from 'components/HookFormFields/HookFormUsersField/HookFormUsersField';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { FormProvider as HookFormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReimbursementFormFieldOptions } from 'views/Reimbursement/hooks/types';
import { ReimbursementFormValues } from 'views/Reimbursement/toolkit/reimbursementFormSchema';

interface ReadonlyReimbursementFormProps {
  values: ReimbursementFormValues;
  fieldOptions: ReimbursementFormFieldOptions;
}

export const ReadonlyReimbursementForm = ({
  values,
  fieldOptions,
}: ReadonlyReimbursementFormProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { targetMembership, suggestedCostCenter } = fieldOptions;

  const form = useForm<ReimbursementFormValues>({ values });

  return (
    <HookFormProvider {...form}>
      <Grid gap="space24" as="form">
        <HookFormTextField
          name="title"
          readOnly
          label={t('reimbursementView.rightSection.form.title.label')}
          ariaLabel={t('reimbursementView.rightSection.form.title.label')}
          placeholder={t(
            'reimbursementView.rightSection.form.title.placeholder'
          )}
        />
        <HookFormUsersField
          name="targetMembership"
          readOnly
          label={t('reimbursementView.rightSection.form.targetMember.label')}
          placeholder={t('teams.createTeam.teamMembers.field.placeholder')}
          emptyListPlaceholder={t(
            'reimbursementView.rightSection.form.targetMember.noUserFound'
          )}
          {...targetMembership?.options}
        />
        {suggestedCostCenter?.isVisible && (
          <HookFormPaginatedComboBoxField
            name="suggestedCostCenter"
            readOnly
            label={t('reimbursementView.rightSection.form.costCenter.label')}
            placeholder={t(
              'reimbursementView.rightSection.form.costCenter.placeholder'
            )}
            {...suggestedCostCenter?.options}
          />
        )}
        <HookFormTextareaField
          name="note"
          readOnly
          label={t('reimbursementView.rightSection.form.note.label')}
          placeholder={t(
            'reimbursementView.rightSection.form.note.placeholder'
          )}
          infoTooltip={{
            message: t('reimbursementView.rightSection.form.note.tooltip'),
          }}
        />
      </Grid>
    </HookFormProvider>
  );
};
