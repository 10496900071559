import {
  ListView,
  useTheme,
  Item,
  Flex,
  Grid,
  Button,
} from '@candisio/design-system';
import { useOrganizationsByMembershipList } from 'containers/notifications/hooks/useOrganizationsByMembershipList';
import { useToggleNotificationRead } from 'containers/notifications/hooks/useToggleNotificationRead';
import { NotificationSummaryFooter } from 'containers/notifications/NotifcationSummaryFooter';
import { NotificationLoadingState } from 'containers/notifications/NotificationLoadingState';
import { InAppNotificationData } from 'containers/notifications/NotificationToast';
import { ALL_ORGANIZATIONS } from 'containers/notifications/utils';
import { motion } from 'framer-motion';
import { OnLoadMore } from 'providers/GraphQLProvider/Pagination/usePagePagination';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { useTranslation } from 'react-i18next';
import { EmptyNotificationList } from '../../EmptyNotificationList/EmptyNotificationList';
import { NotificationListItem } from './NotificationListItem/NotificationListItem';

export interface NotificationSummaryProps {
  hasMore?: boolean;
  totalCount: number;
  loading: boolean;
  showOnlyUnread: boolean;
  onLoadMore?: OnLoadMore;
  notifications: Array<InAppNotificationData>;
  onClose: () => void;
  onShowOnlyUnread: (toogled: boolean) => void;
  currentCompanyOnly: boolean;
}

const MotionGrid = motion(Grid);

export const NotificationSummary = ({
  loading,
  totalCount,
  hasMore,
  notifications,
  showOnlyUnread,
  onShowOnlyUnread,
  onClose,
  onLoadMore,
  currentCompanyOnly,
}: NotificationSummaryProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const { radii } = useTheme();

  const isLoading = loading && totalCount === 0;
  const noNotifications = !loading && totalCount === 0;

  const currentOrganizationId = useOrganizationId() ?? '';

  const { markNotificationsAsRead } = useToggleNotificationRead();

  const { organizations } = useOrganizationsByMembershipList();

  const getOrganizationIds = () => {
    return currentCompanyOnly
      ? [currentOrganizationId]
      : organizations.map(({ id }) => id);
  };

  const handleMarkAllAsRead = () => {
    markNotificationsAsRead({
      organizationIds: getOrganizationIds(),
    });
  };

  const showMarkAllAsReadToggle = notifications.some(({ isRead }) => !isRead);

  const title = currentCompanyOnly
    ? t('notifications.emptyList.currentCompany.general.title')
    : t('notifications.emptyList.allCompanies.title');

  const description = currentCompanyOnly
    ? t('notifications.emptyList.currentCompany.general.description')
    : t('notifications.emptyList.allCompanies.description');

  return (
    <>
      {showMarkAllAsReadToggle && (
        <Button
          position="absolute"
          icon="check"
          size="small"
          variant="tertiary"
          top={-50}
          right="space2"
          alignSelf="end"
          onClick={handleMarkAllAsRead}>
          {t('notifications:popover.markAllAsRead')}
        </Button>
      )}
      <Flex
        borderRadius="medium"
        direction="column"
        justifyContent="space-between"
        height="100%"
        data-cy="inAppNotificationContent">
        {isLoading && <NotificationLoadingState />}

        {noNotifications ? (
          <EmptyNotificationList title={title} description={description} />
        ) : (
          <MotionGrid
            // Key is needed in order to trigger the animation while switching tabs and orgs
            key={`${showOnlyUnread}-${
              currentCompanyOnly ? currentOrganizationId : ALL_ORGANIZATIONS
            }`}
            padding="0px space4 0px space12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            height="100%"
            transition={{ ease: 'easeOut', duration: 0.8, delay: 0.25 }}>
            <ListView
              style={{
                borderRadius: radii.medium,
              }}
              height="100%"
              onEndReached={hasMore ? onLoadMore : undefined}
              isVirtualized>
              {notifications.map(notification => (
                <Item key={notification.id} textValue={notification.id}>
                  <NotificationListItem
                    key={notification?.id}
                    notification={notification}
                    onClose={onClose}
                  />
                </Item>
              ))}
            </ListView>
          </MotionGrid>
        )}

        <NotificationSummaryFooter
          isToogled={showOnlyUnread}
          onToogle={onShowOnlyUnread}
        />
      </Flex>
    </>
  );
};
