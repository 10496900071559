import { EcmDocumentsTableData } from 'components/EcmDocumentsTable/types';
import { mapRelationshipToTableRows } from 'containers/document-relationships/DocumentRelationshipsModal';
import { useDocumentRelationshipsActions } from 'containers/document-relationships/useDocumentRelationshipsActions';
import { useEcmDocumentsTableData } from 'containers/document-relationships/useEcmDocumentsTableData';
import {
  GetEcmDocumentsQuery,
  useDocumentRelationshipsQuery,
} from 'generated-types/graphql.types';
import { useMemo, useState } from 'react';
import { isEcmDocumentId } from 'views/utils/isEcmDocumentId';
import { useSourceDocument } from './useSourceDocument';

const isSameDocument = (
  invoiceId?: string | null,
  ecmDocumentId?: string | null,
  sourceDocumentId?: string | null
) => {
  return ecmDocumentId === sourceDocumentId || invoiceId === sourceDocumentId;
};

interface UseManageRelationshipsDataParams {
  documentId: string;
  onLinkDocuments: () => void;
  previewDocumentId: string | null;
}

export const useManageRelationshipsData = ({
  documentId,
  onLinkDocuments,
  previewDocumentId,
}: UseManageRelationshipsDataParams) => {
  const isEcmDocument = isEcmDocumentId(documentId);

  const { documentName, documentType, sourceDocument } = useSourceDocument({
    documentId,
  });

  const { data: existingRelationshipsData, refetch } =
    useDocumentRelationshipsQuery({
      variables: {
        input: {
          invoiceId: isEcmDocument ? undefined : documentId,
          ecmDocumentId: isEcmDocument ? documentId : undefined,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const { handleLinkDocuments, isLinkingDocumentsPending } =
    useDocumentRelationshipsActions(async () => {
      await refetch();
      onLinkDocuments();
    });

  const [tableData, setTableData] = useState<EcmDocumentsTableData[]>([]);

  const handleOnCompleted = (data: GetEcmDocumentsQuery): void => {
    const documentList = (data.getEcmDocuments?.edges ?? []).map(
      ({ record, cursor }): EcmDocumentsTableData => {
        return {
          cursor,
          id: record?.id ?? '',
          documentType: record?.documentType ?? '',
          contact: record?.contactName ?? '',
          invoiceId: record?.invoiceId ?? '',
          notes: record?.notes ?? '',
          documentName: record?.documentName ?? '',
          documentNumber: record?.documentNumber ?? '',
          documentDate: record?.documentDate
            ? new Date(record?.documentDate)
            : undefined,
          documentStatus: {
            status: record?.documentStatus ?? undefined,
            isSensitive: record?.isSensitive ?? false,
          },
          tags: record?.tags ?? [],
        };
      }
    );

    const documentsToHide = [
      documentId,
      ...existingRelationships.map(e => e.id),
      ...selectedDocuments.map(d => d.id),
    ];

    const dataWithoutExistingRelationships = documentList.filter(d => {
      // To be on the safe side, we check both invoiceId and id. We cannot guarantee that if invoiceId is present, the id will be different.
      // This should be fixed once we use globalDocumentId everywhere
      const isExistingRelationship =
        (d?.invoiceId && documentsToHide.includes(d.invoiceId)) ||
        documentsToHide.includes(d.id);

      return !isExistingRelationship;
    });

    const allData = [
      sourceDocument,
      ...existingRelationships,
      ...selectedDocuments,
      ...dataWithoutExistingRelationships,
    ];

    const tabData = allData.map(d => ({
      ...d,
      selected: d.id === previewDocumentId || d.invoiceId === previewDocumentId,
    }));

    setTableData(tabData);
  };

  const {
    handleSearchChange,
    handleUpdateConfigurations,
    isLoading,
    onLoadMore,
    searchQuery,
    selectedDocuments,
    setSelectedDocuments,
    totalCount,
    availableDocumentColumnIds,
    configurationsTable,
    customEmptyState,
    filters,
    onFilter,
    onSort,
    sortBy,
  } = useEcmDocumentsTableData({
    filterParamsSource: 'local',
    queryOptions: {
      onCompleted: handleOnCompleted,
    },
  });

  const existingRelationships = useMemo(
    () =>
      existingRelationshipsData?.documentRelationships?.relationships.flatMap(
        mapRelationshipToTableRows
      ) ?? [],
    [existingRelationshipsData?.documentRelationships?.relationships]
  );

  const linkDocuments = async () => {
    await handleLinkDocuments({
      documentId,
      documentName: documentName ?? '',
      selectedDocuments,
    });
  };

  const canBeSelected = (row: EcmDocumentsTableData): boolean => {
    const isExistingRelationship = existingRelationships.some(
      e => e.id === row.id || e.invoiceId === row.invoiceId
    );

    return !isExistingRelationship;
  };

  const isSelectVisible = (row: EcmDocumentsTableData): boolean => {
    return row.id !== documentId;
  };

  tableData.forEach(d => {
    d.selected = isSameDocument(d.invoiceId, d.id, previewDocumentId);
  });

  return {
    handleSearchChange,
    handleUpdateConfigurations,
    linkDocuments,
    isLoading,
    onLoadMore,
    searchQuery,
    selectedDocuments,
    setSelectedDocuments,
    totalCount,
    availableDocumentColumnIds,
    configurationsTable,
    tableData,
    customEmptyState,
    filters,
    onFilter,
    onSort,
    isLinkingDocumentsPending,
    canBeSelected,
    sortBy,
    documentType,
    documentName,
    isEcmDocument,
    isSelectVisible,
  };
};
