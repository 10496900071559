import { useCountPayableDocumentsQuery } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { MAX_PAYABLE_DOCUMENT_COUNT } from 'views/consts';
import { NavigationIcon } from '../../NavigationIcon';

export const MenuPayments = ({
  showMenuIcon,
  ...iconProps
}: {
  showMenuIcon?: boolean;
}) => {
  const { isAdmin, isAccountant } = useUserRoles();
  const organization = useFullOrganization();

  const { data: paymentsCount, loading: paymentsCountLoading } =
    useCountPayableDocumentsQuery({
      skip: !(isAdmin || isAccountant),
      fetchPolicy: 'cache-first',
    });

  const payableDocumentsCount = paymentsCount?.countPayableDocuments.count
    ? Math.min(
        paymentsCount?.countPayableDocuments.count,
        MAX_PAYABLE_DOCUMENT_COUNT
      )
    : undefined;

  const showPaymentMenuIcon = showMenuIcon && organization?.payment.isActive;

  return (
    <NavigationIcon
      {...iconProps}
      icon="menuPayments"
      count={payableDocumentsCount}
      countLoading={paymentsCountLoading}
      showMenuIcon={showPaymentMenuIcon}
    />
  );
};
