export const PromotionsTrackingEvents = {
  PROMOTION_CTA_BUTTON_CLICKED: 'promotion_cta_button_clicked',
  PROMOTION_MODAL_OPENED: 'promotion_modal_opened',
  PROMOTION_HIDDEN_CLICKED: 'promotion_hidden_clicked',
  PROMOTION_CAROUSEL_PLAY_BUTTON_CLICKED:
    'promotion_carousel_play_button_clicked',
  NEWS_CAROUSEL_PLAY_BUTTON_CLICKED: 'news_carousel_play_button_clicked',
};

export const PromotionLocation = {
  DASHBOARD: 'Dashboard',
};
