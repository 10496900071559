import { Image, Grid, Text, Box, TruncatedText } from '@candisio/design-system';
import { ReimbursementItemType } from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { ReimbursementItemListLeftSection } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import { useReimbursementContext } from 'views/Reimbursement/toolkit/ReimbursementContext/ReimbursementProvider';
import hospitalityIcon from '../../media/hospitality-icon.svg';
import receiptIcon from '../../media/receipt.svg';

interface ReimbursementItemProps {
  reimbursementItem: ReimbursementItemListLeftSection;
  isRoundedBorder: boolean;
  onClick: () => void;
}

const mappedTypesToItems: Record<
  ReimbursementItemType,
  { placeholder: string; icon: string }
> = {
  GENERAL_EXPENSE: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.general',
    icon: receiptIcon,
  },
  HOSPITALITY_EXPENSE: {
    placeholder:
      'reimbursementView.leftSection.reimbursementItems.placeholder.hospitality',
    icon: hospitalityIcon,
  },
  MILEAGE: {
    placeholder: '',
    icon: '',
  },
  PER_DIEM: {
    placeholder: '',
    icon: '',
  },
};

export const ReimbursementItem = ({
  reimbursementItem,
  isRoundedBorder,
  onClick,
}: ReimbursementItemProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { type, reason, totalAmount } = reimbursementItem;

  const { visibleElementId } = useReimbursementContext();

  const item = mappedTypesToItems[type];

  const text = reason ? reason : t(item.placeholder);

  const amount = totalAmount ? totalAmount : '-';

  return (
    <Grid
      padding="space8 space16"
      templateColumns="auto 1fr"
      cursor="pointer"
      alignItems="center"
      borderTopRadius={isRoundedBorder ? 'medium' : undefined}
      onClick={onClick}
      background={
        visibleElementId === reimbursementItem.id ? 'gray250' : 'gray50'
      }
      hover={{ background: 'gray250' }}>
      <Grid templateColumns="auto 1fr" alignItems="center" gap="space8">
        <Box background="pink100" padding="space4" borderRadius="small">
          <Image src={item.icon} alt="" />
        </Box>
        <TruncatedText fontSize="basic">{text}</TruncatedText>
      </Grid>
      <Text justifySelf="right" fontSize="basic">
        {amount}
      </Text>
    </Grid>
  );
};
