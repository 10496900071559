import { ProcessingFormContactFieldItem } from 'components/Form/ProcessingForm/ProcessingFormContactField';
import { Loader } from 'components/Loader';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { Switcher } from 'components/Switcher/Switcher';
import { EntitlementsModal } from 'containers/Entitlements/components/Modal/EntitlementsModal';
import {
  DocumentStatus,
  GetDocumentForDraftQuery,
} from 'generated-types/graphql.types';
import { useEcm } from 'orgConfig/ecm/useEcm';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { StorageDocumentManagementEvents } from 'providers/AnalyticsProvider/events/DocumentManagementEvents';
import { FEATURE } from 'providers/FeatureToggleProvider/types';
import { useCallback } from 'react';
import { StorageFormContainer } from 'views/Inbox/DocumentProcessing/components/Ecm/StorageFormContainer';
import { ProcessingFormContainer } from 'views/Inbox/DocumentProcessing/ProcessingFormContainer';
import { EcmBenefitsPromo } from 'views/Inbox/DocumentProcessing/RightSection';
import { useDocumentProcessingSwitcher } from 'views/Inbox/DocumentProcessing/useDocumentProcessingSwitcher';

const { MODAL_DEMO_BTN_CLICKED, MORE_INFO_CLICKED } =
  StorageDocumentManagementEvents;

interface NewProps {
  cycleDocument: () => void;
  document?: GetDocumentForDraftQuery['getDocument'];
  loading?: boolean;
  purchaseOrderId?: string;
  contactItem?: ProcessingFormContactFieldItem;
  onGetContactItem?: (value?: ProcessingFormContactFieldItem) => void;
}

export const New = ({
  cycleDocument,
  document,
  loading,
  purchaseOrderId,
  contactItem,
  onGetContactItem,
}: NewProps) => {
  const { showEcm } = useEcm();

  const { track } = useAnalytics();

  const trackModalDemoClick = useCallback(
    () => track(MODAL_DEMO_BTN_CLICKED),
    [track]
  );

  const trackPromoClick = useCallback(
    () => track(MORE_INFO_CLICKED), // ignore prettier
    [track]
  );

  const { formType, setFormType, switcherProps, switcherShown } =
    useDocumentProcessingSwitcher(document, trackPromoClick);

  if (loading) {
    return <Loader />;
  }

  if (!document) {
    // TODO Add error state
    return null;
  }

  return (
    <ProcessSidebar
      key={purchaseOrderId}
      documentId={document.id}
      globalDocumentId={document.globalDocumentId ?? undefined}
      documentStatus={DocumentStatus.New}
      switcher={switcherShown ? <Switcher {...switcherProps} /> : undefined}>
      {showEcm && formType === 'storage' ? (
        <StorageFormContainer
          documentId={document.id}
          isInvoice
          onDeleteDocument={cycleDocument}
          onStoreDocument={cycleDocument}
        />
      ) : (
        <ProcessingFormContainer
          documentId={document.id}
          onApprove={cycleDocument}
          onDeleteDocument={cycleDocument}
          onRequestApproval={cycleDocument}
          contactItem={contactItem}
          onGetContactItem={onGetContactItem}
        />
      )}
      {!showEcm && (
        <EntitlementsModal
          feature={FEATURE.ECM}
          isOpen={formType === 'storage'}
          onClose={() => setFormType('approval')}
          CustomPromoMaterial={<EcmBenefitsPromo />}
          trackDemo={trackModalDemoClick}
          hidePackages
        />
      )}
    </ProcessSidebar>
  );
};
