import { IconProps } from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { Routes } from 'models';
import { useCreditCardsSetup } from 'orgConfig/creditCards/useCreditCardsSetup';
import { useAnalytics } from 'providers/AnalyticsProvider';
import { travelExpenseEvents } from 'providers/AnalyticsProvider/events/TravelAndExpenses';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { useFeatureToggle } from 'providers/FeatureToggleProvider/FeatureToggleProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useFullOrganization } from 'providers/OrganizationProvider';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInitialExportUrl } from 'views/Integrations/Export/Manifest';

export type NavIconProps = {
  icon: IconProps['icon'];
  label?: string;
  route?: string;
  count?: number;
  countLoading?: boolean;
  showMenuIcon?: boolean;
  onClick?: () => void;
  pulseIndicator?: ReactNode;
};

type NavIconPropsReturnType = {
  icons: NavIconProps[];
  intercomIcon: NavIconProps;
  notificationsIcon: NavIconProps;
};

export const useNavigationIcons = (): NavIconPropsReturnType => {
  const organization = useFullOrganization();
  const [t] = useTranslation(LOCALE_NAME_SPACE.NAVIGATION);
  const { track } = useAnalytics();
  const { showAllExtraFeatures } = useCreditCardsSetup();
  const currentUser = useCurrentUser();
  const { ft, show } = useFeatureToggle();
  const exportViewDef = useInitialExportUrl();
  const { isAdmin, isAccountant, isCardManager, isRequester, hasRoles } =
    useUserRoles();

  const showCreditCardMenuItem = Boolean(
    organization?.creditCardsDetails && showAllExtraFeatures
  );

  const creditCardsRoute =
    isAdmin || isCardManager || isAccountant
      ? Routes.CREDIT_CARDS_DASHBOARD
      : Routes.CREDIT_CARDS_CARDHOLDER_OVERVIEW;

  const initialIcons: NavIconProps[] = useMemo(
    () => [
      {
        icon: 'menuDashboard',
        label: t('menuLabels.dashboard'),
        route: Routes.DASHBOARD,
        showMenuIcon: hasRoles,
      },
      {
        icon: 'menuUpload',
        label: t('menuLabels.upload'),
        showMenuIcon: isAdmin || isRequester || isAccountant,
      },
      {
        icon: 'menuInbox',
        label: t('menuLabels.inbox'),
        route: Routes.INBOX,
        showMenuIcon: hasRoles,
      },
      {
        icon: 'menuApprovals',
        label: t('menuLabels.approvals'),
        route: `${Routes.APPROVALS}?approvers=${currentUser?.id}`,
        showMenuIcon: hasRoles,
      },
      {
        icon: 'menuPayments',
        label: t('menuLabels.payments'),
        route: Routes.PAYMENTS,
        showMenuIcon: show(ft.SEPA_XML) ? isAccountant || isAdmin : false,
      },
      {
        icon: 'menuExport',
        label: t('menuLabels.export'),
        route: exportViewDef.url || Routes.EXPORTS,
        showMenuIcon: isAccountant || isAdmin,
      },
      {
        icon: 'menuArchive',
        label: t('menuLabels.archive'),
        route: Routes.ARCHIVE,
        showMenuIcon: hasRoles,
      },
      {
        icon: 'menuTravel',
        label: t('menuLabels.travelAndExpense'),
        route: Routes.REIMBURSEMENTS_DASHBOARD,
        showMenuIcon: hasRoles,
        onClick: () => track(travelExpenseEvents.DASHBOARD_OPENED),
      },
      {
        icon: 'menuCreditCards',
        label: t('menuLabels.creditCards'),
        route: creditCardsRoute,
        showMenuIcon: showCreditCardMenuItem && hasRoles,
      },
      {
        icon: 'menuContactsAlt',
        label: t('menuLabels.contacts'),
        route: Routes.CONTACTS,
        showMenuIcon: isAccountant || isAdmin || isRequester,
      },
    ],
    [
      creditCardsRoute,
      currentUser?.id,
      exportViewDef.url,
      ft.SEPA_XML,
      hasRoles,
      isAccountant,
      isAdmin,
      isRequester,
      show,
      showCreditCardMenuItem,
      t,
      track,
    ]
  );

  const intercomIcon: NavIconProps = useMemo(
    () => ({
      icon: 'menuIntercomAlternative',
      label: t('menuLabels.support'),
      showMenuIcon: hasRoles,
    }),
    [t, hasRoles]
  );

  const notificationsIcon: NavIconProps = useMemo(
    () => ({
      icon: 'menuNotifications',
      label: t('menuLabels.notifications'),
      showMenuIcon: hasRoles,
    }),
    [t, hasRoles]
  );

  return {
    icons: initialIcons,
    intercomIcon,
    notificationsIcon,
  };
};
