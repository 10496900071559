import { Box, Grid, Heading, ScrollBox, Theme } from '@candisio/design-system';
import { NavLink } from 'components/NavLink/NavLink';
import { Routes } from 'models';
import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
// import from react-router-dom because we’re inside a v5 route (deprecated)
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useSettingsNavigationConfig } from './hooks/useSettingsNavigationConfig';

export interface SettingsNavSectionProps {
  children: ReactNode;
}
const PADDING: keyof Theme['space'] = 'space32';

const Divider = () => (
  <Box padding="space16 0">
    <Box height={1} width="100%" background="gray250" />
  </Box>
);

const scrollToTop = () => {
  const mainContent = document.getElementById('main_content');
  if (mainContent) {
    mainContent.scrollTo({
      top: 0,
    });
  }
};

export const SettingsNavSection = ({ children }: SettingsNavSectionProps) => {
  return (
    <Grid gap="space2" listStyle="none" padding={0}>
      {children}
    </Grid>
  );
};

export const SettingsNavigation = () => {
  const [t] = useTranslation();
  const { organizationSlug } = useParams<{ organizationSlug?: string }>();
  const { navLinkConfig } = useSettingsNavigationConfig();

  return (
    <Grid
      as="nav"
      height="100%"
      overflow="hidden"
      templateRows="auto 1fr"
      gap="space16"
      paddingY={PADDING}
      alignContent="start">
      <Box paddingX={PADDING}>
        <Heading as="h1">{t('settings.heading')}</Heading>
      </Box>
      <ScrollBox paddingX={PADDING}>
        <Grid gap="space16">
          <SettingsNavSection>
            {navLinkConfig.map(
              (
                {
                  showItem,
                  to,
                  label,
                  showIndicator,
                  tag,
                  icon,
                  requiresUpgrade,
                  source,
                },
                index
              ) => {
                const dividerPositions = [6, 9, 13];

                return (
                  <Fragment key={to}>
                    {showItem && (
                      <NavLink
                        to={to}
                        onClick={scrollToTop}
                        showIndicator={showIndicator}
                        source={source}
                        requiresUpgrade={requiresUpgrade}
                        tag={tag}
                        iconComponent={icon}>
                        {label}
                      </NavLink>
                    )}
                    {dividerPositions.includes(index) && <Divider />}
                  </Fragment>
                );
              }
            )}
          </SettingsNavSection>

          {localStorage.getItem('enableDevTools') !== null && (
            <SettingsNavSection>
              <NavLink
                data-cy="settings.navigation.developerTools.items.graphiql.sidebarName"
                to={`/${organizationSlug}${Routes.SETTINGS}${Routes.DEV_TOOLS}/graphiql`}
                onClick={scrollToTop}>
                {t(
                  'settings.navigation.developerTools.items.graphiql.sidebarName'
                )}
              </NavLink>
            </SettingsNavSection>
          )}
        </Grid>
      </ScrollBox>
    </Grid>
  );
};
