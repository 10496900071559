import { Box, Flex } from '@candisio/design-system';
import { Promotion } from 'components/NewPromotions/types';
import { motion } from 'framer-motion';
import { Trans } from 'providers/LocaleProvider';
import { PromotionBenefitItem } from './PromotionBenefitItem/PromotionBenefitItem';

const MotionBox = motion(Box);

const contentVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export const PromotionContent = ({ promotion }: { promotion: Promotion }) => {
  const { benefits, contents } = promotion;

  if (benefits) {
    return (
      <Flex
        color="gray600"
        fontSize="basic"
        lineHeight="space20"
        direction="column">
        {benefits?.map((benefit, index) => (
          <li style={{ listStyle: 'none' }} key={benefit.text}>
            <PromotionBenefitItem benefit={benefit} index={index} />
          </li>
        ))}
      </Flex>
    );
  }

  if (contents) {
    return (
      <MotionBox
        color="gray600"
        fontSize="basic"
        lineHeight="space20"
        direction="column"
        padding="0 space20"
        variants={contentVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        transition={{ duration: 0.6, ease: 'easeInOut' }}>
        <Trans>{contents.text}</Trans>
      </MotionBox>
    );
  }

  return null;
};
