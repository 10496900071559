import {
  Flex,
  Icon,
  Tooltip,
  TruncatedText,
  useTooltip,
  Text,
  Checkbox,
  Tag,
} from '@candisio/design-system';
import { BuiltinRoleName } from 'generated-types/graphql.types';
import { useTranslation } from 'react-i18next';

export interface HandleChangeParams {
  isChecked: boolean;
  switchValue: string;
  switchName: string;
}

interface RoleSwitchProps {
  label: string;
  value: string;
  name: string;
  description: string | undefined;
  isToggled: boolean;
  isReadOnly: boolean;
  onChange: (params: HandleChangeParams) => void;
}

export const RoleCheckbox = ({
  label,
  value,
  isToggled,
  name,
  isReadOnly,
  onChange,
  description,
}: RoleSwitchProps) => {
  const descriptionTooltip = useTooltip();

  const ssoTooltip = useTooltip({ placement: 'left' });
  const [t] = useTranslation();

  return (
    <Flex padding="space16" justifyContent="space-between" alignItems="center">
      <Flex gap="space8" alignItems="center">
        {/** There is a type mistmatchment between the props coming from the tooltip and the prop from the switch thus the next line for expecting an error*/}
        {/**@ts-expect-error */}
        <Checkbox
          {...ssoTooltip.triggerProps}
          ref={ssoTooltip.triggerRef}
          isDisabled={isReadOnly}
          value={value}
          name={name}
          aria-label={name}
          isSelected={isToggled}
          onChange={isChecked =>
            onChange({ isChecked, switchValue: value, switchName: name })
          }
        />
        <TruncatedText fontSize="basic">{label}</TruncatedText>
        {name === BuiltinRoleName.Accountant && (
          <Tag color="blue" callout={true}>
            {t('tag.new')}
          </Tag>
        )}
      </Flex>
      {description && (
        <Icon
          icon="infoOutline"
          {...descriptionTooltip.triggerProps}
          ref={descriptionTooltip.triggerRef}
          size="space16"
        />
      )}
      {descriptionTooltip.isOpen && (
        <Tooltip
          {...descriptionTooltip.tooltipProps}
          ref={descriptionTooltip.tooltipRef}>
          <Text>{description}</Text>
        </Tooltip>
      )}
      {ssoTooltip.isOpen && isReadOnly && (
        <Tooltip {...ssoTooltip.tooltipProps} ref={ssoTooltip.tooltipRef}>
          {t('settings.teamMembers.roles.readOnlySSO')}
        </Tooltip>
      )}
    </Flex>
  );
};
