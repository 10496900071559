import { Box, Grid, ScrollBox, TruncatedText } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { FooterActionBox } from './components/RightSection/FooterActions/FooterActionBox';
import { InfoBox } from './components/RightSection/InfoBox/InfoBox';
import { ReimbursementFormContainer } from './components/RightSection/ReimbursementFormContainer';
import { SectionLayout } from './components/SectionLayout';
import { useGetReimbursementById } from './hooks/useGetReimbursementById';
import { useReimbursementExpenseItemsList } from './hooks/useReimbursementExpenseItemsList';
import { useSubmitReimbursementCaseForReview } from './hooks/useSubmitReimbursementCaseForReview';
import { ExpensesFormOutput } from './toolkit/expensesFormSchema';

interface RightSectionContainerProps {
  onCycleReimbursments: () => void;
  getExpenses: UseFormGetValues<ExpensesFormOutput>;
}

export const RightSectionContainer = ({
  onCycleReimbursments,
  getExpenses,
}: RightSectionContainerProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { reimbursementId } = useParams<{ reimbursementId: string }>();
  const { reimbursement } = useGetReimbursementById({
    reimbursementId,
  });

  const { reimbursementItemsCount } = useReimbursementExpenseItemsList();

  const {
    submitReimbursmentCaseForReview,
    isSubmitReimbursmentCaseForReviewPending,
  } = useSubmitReimbursementCaseForReview({
    reimbursementItemsCount,
  });

  return (
    <SectionLayout
      headerElement={
        <Box paddingLeft="space18">
          <TruncatedText as="h1" lineHeight="normal" fontWeight="regular">
            {t('reimbursementView.rightSection.title')}
          </TruncatedText>
        </Box>
      }>
      <Grid overflow="hidden" templateRows="1fr auto" paddingLeft="space8">
        <ScrollBox scrollDirection="y" padding="0 space12 space10">
          <Grid gap="space24">
            <ReimbursementFormContainer reimbursement={reimbursement} />
            <InfoBox reimbursement={reimbursement} />
          </Grid>
        </ScrollBox>
        <FooterActionBox
          reimbursement={reimbursement}
          onCycleReimbursments={onCycleReimbursments}
          onSubmitForReview={submitReimbursmentCaseForReview}
          getExpenses={getExpenses}
          isSubmittingForReview={isSubmitReimbursmentCaseForReviewPending}
        />
      </Grid>
    </SectionLayout>
  );
};
