// Only enables the rule in this file.
/* eslint typescript-sort-keys/string-enum: "error" */
export enum FEATURE_FLAGS {
  accountantRole = 'candis_features.expose_builtin_accountant_role',
  accountingAreas = 'candis_features.accounting_areas',
  allowCommentsInDMS = 'candis_features.allow_comments_in_dms',
  archiveDocumentPreview = 'candis_features.archive_document_preview',
  artistSocialInsuranceAndExtraCostInfo = 'candis_features.artist_social_insurance_and_extra_cost_info',
  bdsExportEnabled = 'candis_features.export_bds_enabled',
  bdsExportPaymentConditionsAndContacts = 'candis_features.export_payment_conditions_and_contacts_with_bds',
  bdsPermissionCheckFlow = 'candis_features.bds_permission_check_flow',
  bdsSetupWizard = 'candis_features.bds_setup_wizard',
  bookingKeyTaxPercentage = 'candis_features.booking_key_tax_percentage',
  candisApi = 'candis_features.candis_api',
  commentRefactor = 'candis_features.comment_refactor',
  contactsArchiving = 'candis_features.contacts_archiving',
  contactsExport = 'candis_features.contacts_export',
  contractsCustomSubtypes = 'candis_features.contracts_custom_subtypes',
  coreDataApi = 'candis_features.core_data_api',
  coreDataApiHideTaxCodeCreation = 'candis_features.core_data_api_hide_tax_code_creation',
  creditCards = 'candis_features.credit_cards',
  creditCardsAutomatedMatching = 'candis_features.credit_cards_automated_matching',
  creditCardsHideSpecialFeatures = 'candis_features.hide_extra_credit_cards_features',
  creditCardsInvoiceNotNeededOnCard = 'candis_features.credit_cards_invoice_not_needed_on_card',
  creditCardsStatements = 'candis_features.credit_cards_bill_statement_payment',
  displayDocumentAccessDrawer = 'candis_features.display_document_access_drawer',
  documentRelations = 'candis_features.document_relations',
  documentTags = 'candis_features.document_tags',
  documentTagsEcmFilters = 'candis_features.document_tags_ecm_filters',
  documentTagsEcmFiltersUntagged = 'candis_features.document_tags_ecm_filters_untagged',
  documentTagsMerge = 'candis_features.document_tags_merge',
  documentTagsSecondIteration = 'candis_features.document_tags_second_iteration',
  documentTypesForOtherIntegrations = 'candis_features.document_types_for_other_integrations',
  eInvoices = 'candis_features.einvoices',
  ecmContractManagement = 'candis_features.ecm_contract_management',
  ecmDocumentsDownload = 'candis_features.ecm_documents_download',
  ecmSensitiveDocuments = 'candis_features.ecm_sensitive_documents',
  employeeContactType = 'candis_features.employee_contact_type',
  enableNewIndexInApprovalView = 'candis_features.enable_new_es_index_in_approval_view',
  enableNewIndexInArchiveView = 'candis_features.enable_new_es_index_in_archive_view',
  enableNewIndexInInboxView = 'candis_features.enable_new_es_index_in_inbox_view',
  enterpriseContentManagement = 'candis_features.enterprise_content_management',
  errorVisibilityImprovement = 'candis_features.error_visibility_improvement',
  experimentalCandisCsvExport = 'candis_features.experimental_candis_csv_export',
  exportDatevBdsViaIntegrationSvc = 'candis_features.export_datev_bds_via_integration_svc',
  exportProvisions = 'candis_features.export_provisions',
  exportProvisionsDatevFormatInternal = 'candis_features.export_provisions_datev_format',
  inAppNotifications = 'candis_features.in_app_notifications',
  increasedLimitForWidgets = 'candis_features.use_increased_limit_for_insights_widgets',
  mainNavigationRefactor = 'candis_features.main_navigation_refactor',
  manageDocumentRelations = 'candis_features.manage_document_relations',
  mobileAppPromotion = 'candis_features.mobile_app_promotion',
  netAndTaxAmount = 'candis_features.net_and_tax_amount',
  newDashboardPromotions = 'candis_features.new_dashboard_promotions',
  newFieldsForIntegrationOther = 'candis_features.new_fields_for_integration_other',
  newSettingsPromotions = 'candis_features.new_settings_promotions',
  paymentConditionsExport = 'candis_features.payment_condition_export',
  permissionsManagement = 'candis_features.permissions_management',
  purchaseOrderNumber = 'candis_features.purchase_order_number',
  reauthenticateBeforeRevealCardCredentials = 'candis_features.protect_reveal_cc_credentials_with_in_app_reauthentication',
  rolesManagement = 'candis_features.allow_roles_management',
  sapB1Export = 'candis_features.sap_b1_export',
  sapConfig = 'candis_features.sap_config',
  sapContacts = 'candis_features.sap_contacts',
  sapCostCenters = 'candis_features.sap_cost_centers',
  sapCsvExport = 'candis_features.experimental_sap_csv_export',
  sapGeneralLedgerAccounts = 'candis_features.sap_general_ledger_accounts',
  sapNetAmounts = 'candis_features.sap_net_amount',
  sapPackageFreight = 'candis_features.sap_package_freight',
  sapPaymentConditions = 'candis_features.sap_payment_conditions',
  sapPurchaseOrder = 'candis_features.sap_purchase_order',
  sapTaxCodes = 'candis_features.sap_tax_codes',
  showOthersHaveOpenedSameDocument = 'candis_features.show_others_have_opened_same_document',
  slackIntegration = 'candis_features.slack_integration',
  ssoConfig = 'candis_features.sso_config',
  suggestSplitBookings = 'candis_features.suggest_split_bookings_from_vat_rates',
  tableRowLink = 'candis_features.table_row_link',
  teamsManagement = 'candis_features.teams_management',
  travelExpenseManagement = 'candis_features.travel_expense_management',
  validateBookingAccountBaseOffIntegration = 'candis_features.validate_booking_accounts_based_off_integration',
  visualiseWaitingForClarification = 'candis_features.visualise_waiting_for_clarification',
  wipImportExportNotifications = 'candis_features.wip_navigation_import_export_notifications',
  wipShowUploadDocumentMenuIcon = 'candis_features.wip_show_upload_document_menu_icon',
}
