import { useLayoutEffect, useRef } from 'react';
import { FieldArray } from 'react-hook-form';
import { ExpensesFormOutput } from '../toolkit/expensesFormSchema';
import { useReimbursementContext } from '../toolkit/ReimbursementContext/ReimbursementProvider';

interface UseUpdateVisibleElementParams {
  forms: FieldArray<ExpensesFormOutput, 'expenses'>[];
}

const getVisibleHeight = (
  refContainer: HTMLDivElement,
  element: HTMLDivElement
) => {
  const container = refContainer;
  const scrollTop = container?.scrollTop ?? 0;
  const scrollBot = (scrollTop ?? 0) + (container?.clientHeight ?? 0);
  const containerRect = container?.getBoundingClientRect();
  const eleRect = element.getBoundingClientRect();
  const rect: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  } = {};

  rect.top = eleRect.top - (containerRect?.top ?? 0);
  rect.right = eleRect.right - (containerRect?.right ?? 0);
  rect.bottom = eleRect.bottom - (containerRect?.bottom ?? 0);
  rect.left = eleRect.left - (containerRect?.left ?? 0);
  const eleTop = rect.top + scrollTop;
  const eleBot = eleTop + element.offsetHeight;
  const visibleTop = eleTop < scrollTop ? scrollTop : eleTop;
  const visibleBot = eleBot > scrollBot ? scrollBot : eleBot;

  return visibleBot - visibleTop;
};

/**
 *
 * hook to setUp onScrollEnd handler to check which of the element is currently visible in the scrollable view
 */
export const useUpdateVisibleElement = ({
  forms,
}: UseUpdateVisibleElementParams) => {
  const ref = useRef<HTMLDivElement>(null);
  const visibleForm = useRef<string>(forms?.[0]?.expenseId);

  const { handleSetVisibleElementId } = useReimbursementContext();

  useLayoutEffect(() => {
    const refContainer = ref.current;

    if (!refContainer) {
      return;
    }

    const updateVisibleElement = () => {
      if (!refContainer) {
        return;
      }

      const containerHeight = ref.current.clientHeight;

      const children = ref.current.children[0].children;

      const childrenLength = children?.length ?? 0;

      for (let i = 0; i < childrenLength; i++) {
        const child = children?.[i];
        if (!child) {
          return;
        }

        const singleFormHeight = getVisibleHeight(
          refContainer,
          child as HTMLDivElement
        );

        const halfSizeContainer = (containerHeight ?? 0) / 2;

        if (singleFormHeight >= halfSizeContainer) {
          visibleForm.current = forms[i].expenseId;
          handleSetVisibleElementId(visibleForm.current);
        }
      }
    };

    refContainer.addEventListener('scrollend', updateVisibleElement);

    return () =>
      refContainer.removeEventListener('scrollend', updateVisibleElement);
  }, [forms, handleSetVisibleElementId, visibleForm]);

  // Called only on first render to set up the first form as visible
  useLayoutEffect(() => {
    handleSetVisibleElementId(visibleForm.current);
  }, [handleSetVisibleElementId, visibleForm]);

  return { ref };
};
