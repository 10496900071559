import { Box } from '@candisio/design-system';
import { ReimbursementCaseStatus } from 'generated-types/graphql.types';
import { ReactNode, useMemo } from 'react';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { ApprovedStatusFooterActionBox } from './ApprovedStatusFooterActionBox';
import { ApprovingStatusFooterActionBox } from './ApprovingStatusFooterActionBox';
import { FooterActions, FooterActionsProps } from './FooterActions';
import { RejectedStatusFooterActionBox } from './RejectedStatusFooterActionBox';
import { ReviewStatusFooterActionBox } from './ReviewStatusFooterActionBox';

interface FooterActionBoxProps extends FooterActionsProps {
  reimbursement?: Reimbursement;
  isSubmittingForReview: boolean;
}

export const FooterActionBox = ({
  reimbursement,
  getExpenses,
  onCycleReimbursments,
  onSubmitForReview,
  isSubmittingForReview,
}: FooterActionBoxProps) => {
  const { status, createdByMembership } = reimbursement ?? {};
  const createdById = createdByMembership?.id;

  const footer: Partial<Record<ReimbursementCaseStatus, ReactNode>> = useMemo(
    () => ({
      DRAFT: (
        <FooterActions
          getExpenses={getExpenses}
          onCycleReimbursments={onCycleReimbursments}
          onSubmitForReview={onSubmitForReview}
          isSubmittingForReview={isSubmittingForReview}
        />
      ),
      REVIEW: <ReviewStatusFooterActionBox createdById={createdById} />,
      APPROVED: <ApprovedStatusFooterActionBox createdById={createdById} />,
      APPROVING: <ApprovingStatusFooterActionBox createdById={createdById} />,
      REJECTED: <RejectedStatusFooterActionBox createdById={createdById} />,
    }),
    [
      createdById,
      getExpenses,
      isSubmittingForReview,
      onCycleReimbursments,
      onSubmitForReview,
    ]
  );

  if (!status || !footer[status]) return null;

  return (
    <Box paddingRight="space14" right={-2.5}>
      {footer[status]}
    </Box>
  );
};
