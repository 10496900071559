import { PurchaseOrderDetailsCard } from 'components/PurchaseOrders/PurchaseOrderDetailsCard/PurchaseOrderDetailsCard';
import { SelectPurchaseOrderContainer } from 'components/PurchaseOrders/SelectPurchaseOrderContainer/SelectPurchaseOrderContainer';
import { isEmpty } from 'lodash';
import { PurchaseOrders } from '../ThreeWayMatch/types';

export interface PurchaseOrderSectionProps {
  documentId: string;
  documentAmount?: number | null;
  purchaseOrders: PurchaseOrders;
  contactName?: string;
  accountsPayableNumber?: string | null;
  isLoading: boolean;
}

export const PurchaseOrderSection = ({
  documentAmount,
  documentId,
  contactName,
  accountsPayableNumber,
  purchaseOrders,
  isLoading,
}: PurchaseOrderSectionProps) => {
  return isLoading || isEmpty(purchaseOrders) ? (
    <SelectPurchaseOrderContainer
      documentAmount={documentAmount}
      documentId={documentId}
      contactName={contactName}
      accountsPayableNumber={accountsPayableNumber}
    />
  ) : (
    purchaseOrders?.map((purchaseOrder, index) => (
      <PurchaseOrderDetailsCard
        key={purchaseOrder?._id}
        purchaseOrder={purchaseOrder}
        hasNextPurchaseOrder={
          index !== 0 && !!purchaseOrder?.goodsReceipts?.length
        }
      />
    ))
  );
};
