import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { noop } from 'lodash';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

const SidebarContext = createContext<{
  isNavigationOpen: boolean;
  toggleSidebar: () => void;
}>({
  isNavigationOpen: false,
  toggleSidebar: () => noop,
});

const SMALL_SCREEN_WIDTH = 1280;
export const MAIN_NAVIGATION_OPEN_ONLOAD = 'main-navigation-open-onload';

export const useNavigationSidebarContext = () => useContext(SidebarContext);

export const NavigationSidebarProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const windowWidth = useRef(window.innerWidth);
  const isLargeDesktop = windowWidth.current > SMALL_SCREEN_WIDTH;

  const [isOpen, setIsOpen] = useLocalStorage(
    MAIN_NAVIGATION_OPEN_ONLOAD,
    isLargeDesktop
  );

  const preserveStateInLocalStorage = useCallback(() => {
    setIsOpen(prev => !prev);
  }, [setIsOpen]);

  const [isNavigationOpen, setIsNavigationOpen] = useState(isOpen);

  const toggleSidebar = () => {
    setIsNavigationOpen(!isNavigationOpen);
    preserveStateInLocalStorage();
  };

  return (
    <SidebarContext.Provider
      value={{
        isNavigationOpen,
        toggleSidebar,
      }}>
      {children}
    </SidebarContext.Provider>
  );
};
