import { Card, Grid, Text } from '@candisio/design-system';
import { useUserRoles } from 'hooks/useUserRoles';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

interface ReviewStatusInfoBoxProps {
  createdAt?: string;
}

export const ReviewStatusInfoBox = ({
  createdAt,
}: ReviewStatusInfoBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { isOnlyApprover } = useUserRoles();

  if (!isOnlyApprover) return null;

  return (
    <Card
      border="1px blue200 solid"
      background="bluebg"
      boxShadow="elevatedShadow4"
      padding="space20">
      <Grid gap="space8">
        <Text fontSize="basic">
          {t('reimbursementView.rightSection.footerInfoBox.desc', {
            createdAt,
          })}
        </Text>
      </Grid>
    </Card>
  );
};
