import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { Reimbursement } from 'views/Reimbursement/hooks/useFormattedReimbursement';
import { useInitialReimbursementFormValues } from 'views/Reimbursement/hooks/useInitialReimbursementFormValues';
import { useReimbursementFormFieldOptions } from '../../hooks/useReimbursementFormFieldOptions';
import { ReadonlyReimbursementForm } from './ReadonlyReimbursementForm';
import { ReimbursementForm } from './ReimbursementForm';

interface ReimbursementFormContainerProps {
  reimbursement?: Reimbursement;
}

export const ReimbursementFormContainer = ({
  reimbursement,
}: ReimbursementFormContainerProps) => {
  const fieldOptions = useReimbursementFormFieldOptions();
  const { values, defaultValues } =
    useInitialReimbursementFormValues(reimbursement);

  const { reimbursementUIConfig } = useReimbursementFormsContext();
  const { isReimbursementFormEditable } = reimbursementUIConfig;

  if (!isReimbursementFormEditable) {
    return (
      <ReadonlyReimbursementForm values={values} fieldOptions={fieldOptions} />
    );
  }

  return (
    <ReimbursementForm
      values={values}
      defaultValues={defaultValues}
      fieldOptions={fieldOptions}
    />
  );
};
