import { Grid, ScrollBox, TruncatedText } from '@candisio/design-system';
import { CollapsibleCard } from 'components/CollapsibleCard/CollapsibleCard';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReimbursementCommentContainer } from './ReimbursementCommentContainer';

export const HistoryAndCommentSection = memo(() => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <ScrollBox scrollDirection="y" scrollbarGutter="stable">
      <CollapsibleCard
        borderRadius="medium"
        paddingBottom="space10"
        maxHeight="85cqh"
        id="history-open"
        headerProps={{ background: 'gray0' }}
        header={
          <TruncatedText>
            {t('reimbursementView.leftSection.protocol.title')}
          </TruncatedText>
        }
        defaultOpen>
        <Grid
          background="gray0"
          borderBottomRadius="medium"
          templateRows="auto auto"
          padding="space8 space16">
          <ReimbursementCommentContainer />
        </Grid>
      </CollapsibleCard>
    </ScrollBox>
  );
});
