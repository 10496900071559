import { Button, Flex, Link, Modal } from '@candisio/design-system';
import { usePromotionsContext } from 'components/NewPromotions/context/PromotionsProvider';
import { Promotion } from 'components/NewPromotions/types';
import { useAnalytics } from 'providers/AnalyticsProvider';
import {
  PromotionLocation,
  PromotionsTrackingEvents,
} from 'providers/AnalyticsProvider/events/PromotionsTrackingEvents';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import qrCode from 'views/AppContainer/components/Header/components/MobileAppPromotionModal/media/user-profile-menu-QR-code.jpg';
import { MobileAppPromotionModal } from 'views/AppContainer/components/Header/components/MobileAppPromotionModal/MobileAppPromotionModal';
import { PromotionDetailsModalContent } from '../PromotionDetailsModalContent/PromotionDetailsModalContent';
import { PromotionLabel } from '../PromotionHeader/PromotionHeader';

export const PromotionCallInAction = ({
  promotion,
}: {
  promotion: Promotion;
}) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.PROMOTIONS);
  const { ctaButton, link, label } = promotion;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { pauseAutoPlayCard, resumeAutoPlayCard } = usePromotionsContext();

  const { track } = useAnalytics();

  const trackEvent = () => {
    track(PromotionsTrackingEvents.PROMOTION_MODAL_OPENED, {
      placement: PromotionLocation.DASHBOARD,
      promotionId: promotion.id,
    });
  };

  const onClickCtaButton = () => {
    trackEvent();

    if (ctaButton.url) return;

    setIsModalOpen(true);
    pauseAutoPlayCard?.();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resumeAutoPlayCard?.();
  };

  const renderModal = () => {
    if (promotion.id === 'mobileApp') {
      return (
        <MobileAppPromotionModal
          qrCode={qrCode}
          visible={isModalOpen}
          onCancel={closeModal}
        />
      );
    }

    return (
      <Modal
        width="1000px"
        background="gray100"
        padding="0"
        style={{
          background:
            'linear-gradient(90deg, rgba(255,255,255,1) 60%, rgba(248,246,245,1) 40%)',
        }}
        titleInfo={<PromotionLabel label={label} />}
        closeLabel={t('common:common.close')}
        isOpen={isModalOpen}
        onClose={closeModal}>
        <PromotionDetailsModalContent promotionId={promotion.id} />
      </Modal>
    );
  };

  return (
    <>
      <Flex
        direction="column"
        justifyContent="end"
        alignItems="center"
        gap="space8">
        <Button
          color="gray"
          variant="secondary"
          width="100%"
          onClick={onClickCtaButton}>
          {ctaButton.text}
        </Button>

        {link?.url && (
          <Link
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            color={link.color ?? 'purple'}
            fontSize="basic"
            fontWeight="500"
            href={link.url}>
            {link.text}
          </Link>
        )}
      </Flex>

      {renderModal()}
    </>
  );
};
