import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface ReimbursementContextValues {
  visibleElementId: string | undefined;
  handleSetVisibleElementId: (visibleElementId: string | undefined) => void;
}

export const ReimbursementContext = createContext<
  ReimbursementContextValues | undefined
>(undefined);

export const ReimbursementProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [visibleElementId, setVisibleElementId] = useState<string>();

  const handleSetVisibleElementId = useCallback(
    (visibleElementId: string | undefined) => {
      setVisibleElementId(visibleElementId);
    },
    [setVisibleElementId]
  );

  const contextValue = useMemo(() => {
    return { visibleElementId, handleSetVisibleElementId };
  }, [handleSetVisibleElementId, visibleElementId]);

  return (
    <ReimbursementContext.Provider value={contextValue}>
      {children}
    </ReimbursementContext.Provider>
  );
};

export const useReimbursementContext = () => {
  const context = useContext(ReimbursementContext);
  if (context === undefined) {
    throw new Error(
      'useReimbursementContext must be used within a ReimbursementProvider'
    );
  }

  return context;
};
