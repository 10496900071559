import { Box, Image } from '@candisio/design-system';
import { IntercomLink } from 'components/InfoPanel/components/IntercomLink';
import { InfoPanel } from 'components/InfoPanel/InfoPanel';
import { usePromotionContent } from 'components/NewPromotions/hooks/usePromotionContent';
import { PromotionCard } from 'components/NewPromotions/PromotionCard/PromotionCard';
import { VideoCard } from 'containers/Entitlements/components/Upsell/components/VideoCard';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { SettingsLayout } from '../components/SettingsLayout/SettingsLayout';
import thumbnail from './toolkit/assets/teaser.png';

const DOCUMENT_TAGS_GRID_WIDTH = '50rem';

export const DocumentTagsPromo = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SETTINGS);

  const { tagsPromotion } = usePromotionContent();

  return (
    <SettingsLayout
      title={t('documentTags.title')}
      width={DOCUMENT_TAGS_GRID_WIDTH}>
      <Box height="370px">
        <PromotionCard promotion={tagsPromotion} closable={false} />
      </Box>
      <InfoPanel
        sections={t('documentTags.infoPanel.sections', { returnObjects: true })}
        title={t('documentTags.infoPanel.title')}
        intercomLink={
          <IntercomLink
            translation={t('documentTags.infoPanel.feedbackButton')}
            id="settings-tags-intercom-link"
          />
        }>
        <VideoCard
          videoTourUrl={t('documentTags.drawer.links.videoTour.url')}
          videoTourTitle={t('documentTags.drawer.links.videoTour.label')}
          withFilter>
          <Image src={thumbnail} alt="" height="10rem" />
        </VideoCard>
      </InfoPanel>
    </SettingsLayout>
  );
};
