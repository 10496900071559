import {
  ConnectedInvoicesData,
  ConnectedPurchaseOrdersData,
  GoodsReceiptEdge,
  GoodsReceiptQueryField,
  Query,
} from 'generated-types/graphql.types';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import { useSap } from 'orgConfig/sap';
import { usePagination } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useMemo } from 'react';
import { Filters, SortingRule } from 'react-table';
import { getApproximateNumberOfRowForTable } from 'views/utils/pagination-helper';
import { getGoodsReceiptQuery } from '../queries';
import { GoodsReceiptsTableData } from '../types';
import { mapToFilterInput, mapToSortInput } from '../utils';

type HookProps = {
  searchValue: string;
  sortBy: SortingRule<GoodsReceiptsTableData>[];
  filters: Filters<GoodsReceiptsTableData>;
  queryFields?: GoodsReceiptQueryField[];
  setSearchValue?: (value: string) => void;
};

const transformGoodsReceiptsToTableData = (
  goodsReceiptsList: GoodsReceiptEdge[]
): GoodsReceiptsTableData[] => {
  return goodsReceiptsList.map(({ node }) => ({
    id: node._id,
    contact: node.contactName,
    orderNumber: (node.connectedPurchaseOrders ?? []).filter(
      (order): order is ConnectedPurchaseOrdersData => order !== undefined
    ),
    deliveryDate: node.deliveryDate ? new Date(node.deliveryDate) : null,
    status: node.status,
    receiptNumber: node.receiptNumber,
    receiptLines: node.goodsLineItems ?? [],
    connectedInvoices: (node.connectedInvoices ?? []).filter(
      (invoice): invoice is ConnectedInvoicesData => invoice !== undefined
    ),
  }));
};

export const useGetGoodsReceipts = ({
  searchValue,
  filters,
  sortBy,
  queryFields = [],
}: HookProps) => {
  const { shouldUseSapPurchaseOrder } = useSap();
  const [debouncedSearchValue] = useDebouncedValue(searchValue);
  const computeVariables = (endCursor: string | undefined) => ({
    input: {
      limit: getApproximateNumberOfRowForTable(),
      after: endCursor,
    },
    query: debouncedSearchValue,
    queryFields,
    filters: mapToFilterInput(filters),
    sortBy: mapToSortInput(sortBy),
  });

  const queryOptions = {
    variables: computeVariables(undefined),
    skip: !shouldUseSapPurchaseOrder,
  };

  const { data, loading, onLoadMore, refetch } = usePagination<
    Pick<Query, 'inboxGoodsReceipts'>
  >(getGoodsReceiptQuery, 'inboxGoodsReceipts', queryOptions, {
    computeVariables,
  });

  const goodsReceiptsList = data?.inboxGoodsReceipts?.edges ?? [];
  const goodsReceiptsTableData =
    transformGoodsReceiptsToTableData(goodsReceiptsList);

  const hasMore = data?.inboxGoodsReceipts?.pageInfo?.hasNextPage ?? false;

  const isTableEmpty = useMemo(
    () => goodsReceiptsTableData.length === 0 && !loading,
    [goodsReceiptsTableData, loading]
  );

  return {
    tableData: goodsReceiptsTableData,
    hasMore,
    onLoadMore,
    refetch,
    isTableEmpty,
    isLoading: loading,
  };
};
