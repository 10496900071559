import { Button, Grid, Icon, Text } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useCallback, useRef, useMemo, MouseEvent } from 'react';
import {
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDragAndDrop } from 'views/TransactionAssociation/component/InvoiceUploadContainer/useDragAndDrop';
import { getColorCode } from 'views/TransactionAssociation/component/InvoiceUploadContainer/util';
import { useDraggedFileStatus } from 'views/TransactionAssociation/hooks/useDraggedFileStatus';

const ACCEPTED_FILE_TYPE = 'application/pdf';

interface ExpenseInvoiceInputFieldProps<TFormValues extends FieldValues> {
  id: string;
  name: Path<TFormValues>;
  control?: UseControllerProps<TFormValues>['control'];
  onChange: (file: File) => void;
  onInvoiceUpdate: () => void;
  disabled?: boolean;
}

export const ExpenseInvoiceInputField = <TFormValues extends FieldValues>({
  id,
  name,
  control,
  onChange,
  onInvoiceUpdate,
  disabled = false,
}: ExpenseInvoiceInputFieldProps<TFormValues>) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const { fieldState } = useController<TFormValues>({
    name,
    control,
    disabled,
  });

  const isInvalid = !!fieldState.error?.message;

  const onFileSelected = useCallback(
    (files: File[]) => {
      files.forEach(onChange);
      onInvoiceUpdate();
    },
    [onChange, onInvoiceUpdate]
  );

  const {
    draggedFileCount,
    isAcceptedFileType,
    isFileDraggable,
    onFileDrop,
    onDragLeave,
    onDragOver,
    onDropZoneClick,
    onFilesChanged,
  } = useDragAndDrop({ fileUploadRef, onFileSelected, isDisabled: disabled });

  const { statusText, canUserDropFile } = useDraggedFileStatus(
    draggedFileCount,
    isAcceptedFileType
  );

  const style = useMemo(() => {
    const baseColor = canUserDropFile ? 'gray500' : 'red500';
    const iconBaseColor = disabled ? 'gray400' : baseColor;

    const getFileDraggableIconColor = () =>
      isFileDraggable ? getColorCode('color', canUserDropFile) : iconBaseColor;

    const getFileDraggableBgColor = () =>
      isFileDraggable ? getColorCode('background', canUserDropFile) : 'gray0';

    return {
      textColor: isInvalid ? 'red500' : baseColor,
      iconColor: isInvalid ? 'red500' : getFileDraggableIconColor(),
      bgColor: isInvalid ? 'redbg' : getFileDraggableBgColor(),
    };
  }, [canUserDropFile, disabled, isFileDraggable, isInvalid]);

  return (
    <Grid
      gap="space24"
      cursor={disabled ? 'no-drop' : 'pointer'}
      paddingY="space24"
      borderRadius="medium"
      placeItems="center"
      placeContent="center"
      draggable={isFileDraggable}
      onDrop={onFileDrop}
      onDragOver={onDragOver}
      onClick={onDropZoneClick}
      onDragLeave={onDragLeave}
      background={style.bgColor}>
      <Grid placeItems="center">
        <Icon
          size="60px"
          icon={canUserDropFile ? 'upload' : 'warning'}
          color={style.iconColor}
        />
        <Text
          as="label"
          htmlFor={id}
          lineHeight="paragraph"
          fontSize="basic"
          color={style.textColor}
          onClick={(e: MouseEvent<HTMLLabelElement>) => e.preventDefault()}>
          {statusText()}
        </Text>
      </Grid>
      <Button size="small" disabled={disabled}>
        {t('reimbursementView.middleSection.form.uploadCta')}
      </Button>
      <input
        id={id}
        name={name}
        disabled={disabled}
        type="file"
        accept={ACCEPTED_FILE_TYPE}
        ref={fileUploadRef}
        onChange={onFilesChanged}
        hidden
      />
    </Grid>
  );
};
