import {
  Text,
  useTooltip,
  Tooltip,
  Flex,
  mergeRefs,
  Icon,
  Skeleton,
  mergeProps,
  Box,
} from '@candisio/design-system';
import { colors } from '@candisio/design-system/src/Theme/themeValues';
import { motion } from 'framer-motion';
import { CSSProperties, forwardRef } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { IconLinkProps } from './types';

const MotionFlex = motion(Flex);

const textStyle: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  textAlign: 'left',
};

const motionTextVariants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

const motionFlexVariants = {
  initial: {
    background: 'transparent',
    outline: 'none',
    color: colors.gray600,
    transition: { color: { duration: 0 } },
  },
  hover: {
    outline: 'none',
    background: colors.bluebg,
    color: colors.blue600,
    transition: { color: { duration: 0 } },
  },
};

export const IconLink = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  IconLinkProps
>(
  (
    {
      icon,
      avatar,
      as: Component,
      size = 'space20',
      count,
      countLoading = false,
      label,
      showLabelInTooltip = true,
      expanded = false,
      to,
      description,
      isActive,
      pulseIndicator,
      'aria-label': ariaLabel,
      ...restProps
    }: IconLinkProps,
    ref
  ) => {
    const { isOpen, triggerProps, triggerRef, tooltipProps, tooltipRef } =
      useTooltip({
        placement: 'right',
        passiveTrigger: true,
      });

    const LinkOrButton = Component === 'button' ? 'button' : Link;

    const showLabelTooltip = !expanded && showLabelInTooltip;

    const getGapValue = () => {
      if (expanded && icon) return 'space12';
      if (expanded && avatar) return 'space8';

      return undefined;
    };

    return (
      <>
        <MotionFlex
          as={LinkOrButton}
          alignItems="center"
          justifyContent="space-between"
          textDecoration="none"
          background="transparent"
          borderRadius="medium"
          cursor="pointer"
          color="gray600"
          padding={icon ? 'space10 space12' : 'space4'}
          border="none"
          outline="none"
          gap={count || pulseIndicator ? 'space8' : undefined}
          width={expanded ? 'inherit' : 'fit-content'}
          to={to}
          tabIndex={0}
          type={Component === 'button' ? 'button' : undefined}
          variants={motionFlexVariants}
          initial={isActive ? 'hover' : 'initial'}
          animate={isActive ? 'hover' : 'initial'}
          whileHover="hover"
          whileFocus="hover"
          ref={mergeRefs([triggerRef, ref])}
          aria-label={ariaLabel ?? label}
          {...mergeProps(triggerProps, restProps)}>
          <Flex alignItems="center" gap={getGapValue()}>
            {icon && <Icon icon={icon} size={size} />}
            {avatar}
            {expanded && (
              <MotionFlex
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                initial="closed"
                animate={expanded ? 'open' : 'closed'}
                variants={motionTextVariants}
                transition={{ duration: 0.2 }}
                style={{ overflow: 'hidden' }}>
                <Text
                  aria-label={ariaLabel ?? label}
                  fontSize="basic"
                  fontWeight="500"
                  lineHeight="space20"
                  style={textStyle}>
                  {label}
                </Text>
                {description && (
                  <Text
                    aria-label={description}
                    fontSize="small"
                    fontWeight="regular"
                    lineHeight="space16"
                    style={textStyle}>
                    {description}
                  </Text>
                )}
              </MotionFlex>
            )}
          </Flex>

          <Flex gap="space8">
            {countLoading && <Skeleton width="space16" height="space16" />}

            {!!count && !countLoading && (
              <Text fontSize="x-small" fontWeight="bold" lineHeight="paragraph">
                {`${count > 99 ? '+' : ''}${Math.min(count, 99)}`}
              </Text>
            )}

            {pulseIndicator && <Box>{pulseIndicator}</Box>}
          </Flex>
        </MotionFlex>
        {isOpen && label && showLabelTooltip && (
          <Tooltip ref={tooltipRef} {...tooltipProps}>
            {label}
          </Tooltip>
        )}
      </>
    );
  }
);
