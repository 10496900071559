import { Button } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

export const ApproveNowAction = () => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Button color="blue" variant="secondary">
      {t('reimbursementView.rightSection.reviewfooterAction.approve')}
    </Button>
  );
};
