import {
  COMMENT_LENGTH_MAX,
  COMMENT_LENGTH_MIN,
} from 'components/Comment/constants';
import { TaxPresentation } from 'components/Form/SplitBookingsForm/types';
import {
  BookingKey,
  IntegrationName,
  SapExpenseType,
} from 'generated-types/graphql.types';
import { get } from 'lodash';
import { ibanSchema } from 'utils/zodFormValidation';
import { swiftCodeSchema } from 'utils/zodFormValidation/Schemas/swiftCodeSchema';
import { taxCodeExportValidationSchema } from 'views/utils/taxCodeExportValidationSchema';
import { z } from 'zod';

const requiredTranslationKey = 'document.approveDocument.errors.fieldRequired';

const formSchema = z.object({
  accountsPayableNumber: z.string().nullish(),
  accountsReceivableNumber: z.string().nullish(),
  bookings: z.array(
    z.object({
      amount: z.number(),
      taxCode: z.string().nullish(),
      artistSocialInsuranceCode: z.string().nullish(),
      /**
       * We use `bookingId` instead of `id` to avoid clashing with the `id`
       * property set by React Hook Form’s `useFieldArray` hook
       */
      bookingId: z.string().nullish(),
      costCenter: z.object({
        value: z.string().nullish(),
        inputValue: z.string().optional(),
      }),
      costObject: z.object({
        value: z.string().nullish(),
        inputValue: z.string().optional(),
      }),
      dueDate: z.date().nullish(),
      extraCostInfo: z.object({
        value: z.string().nullish(),
        inputValue: z.string().optional(),
      }),
      generalLedgerAccount: z.object({
        value: z.string().nullish(),
        inputValue: z.string().optional(),
      }),
      note: z.string().nullish(),
      postingText: z.string().nullish(),
      quantity: z.union([z.number().min(1), z.undefined()]),
      vatRate: z.number().min(0).max(99.99).step(0.01).nullish(),
      taxAmount: z.number().nullish(),
      netAmount: z.number().nullish(),
      taxPresentation: z
        .enum([TaxPresentation.Gross, TaxPresentation.Net])
        .default(TaxPresentation.Gross),
      sapExpenseType: z.nativeEnum(SapExpenseType).optional(),
    })
  ),
  category: z.object({
    type: z.string().nullish(),
    direction: z.string().nullish(),
  }),
  cashDiscount: z.object({
    amount: z.number().nullish(),
    dueDate: z.date().nullish(),
    percentage: z
      .number()
      .min(0.01)
      .max(99.99)
      .step(0.01)
      .nullable()
      .optional(),
  }),
  contact: z.string().nullish(),
  contactId: z.string().nullish(),
  createTransfer: z.boolean(),
  currency: z.string(),
  grossAmount: z
    .number()
    .step(0.01)
    .and(z.number().min(0.01).or(z.number().max(-0.01))),
  hasTransactions: z.boolean(),
  iban: ibanSchema.nullish(),
  invoiceCorrection: z.boolean(),
  invoiceDate: z.date().nullish(),
  invoiceDeliveryDate: z.date().nullish(),
  invoicePostingDate: z.date().nullish(),
  // TODO: check why invoiceNumber is nullish
  invoiceNumber: z.string().nullish(),
  purchaseOrderNumber: z.string().nullish(),
  accountingArea: z.object({
    value: z.string().nullish(),
    inputValue: z.string().optional(),
  }),
  swiftCode: swiftCodeSchema.nullish(),
  vatRate: z.number().nullish(),
  roundingAmount: z.number().nullish(),
  netAmount: z.number().nullish(),
  taxAmount: z.number().nullish(),
  rejected: z.boolean(),
  comment: z.string().nullish(),
});

export type DocumentApprovalFormSchemaOptions = {
  availableTaxCodes?: Array<Pick<BookingKey, 'id' | 'taxCode'>>;
  documentDirection?: string;
  hasCostCenters?: boolean;
  hasCostObjects?: boolean;
  shouldShowAccountingAreaField?: boolean;
  integration?: IntegrationName;
  shouldRequireGeneralLedgerAccount?: boolean;
  shouldRequireTaxCode?: boolean;
};

const taxCodeSchema = ({
  integration,
  availableTaxCodes,
  documentDirection,
  shouldRequireTaxCode,
}: DocumentApprovalFormSchemaOptions) => {
  return formSchema
    .pick({ bookings: true, rejected: true })
    .superRefine(({ bookings, rejected }, ctx) => {
      if (rejected) {
        return;
      }

      bookings.forEach(({ taxCode }, index) => {
        if (shouldRequireTaxCode && typeof taxCode !== 'string') {
          ctx.addIssue({
            code: 'custom',
            path: ['bookings', index, 'taxCode'],
            params: {
              translationKey: requiredTranslationKey,
            },
          });

          return;
        }
      });

      if (integration !== 'DATEV' || !documentDirection) {
        return;
      }

      bookings.forEach(({ taxCode }, index) => {
        if (!taxCode) {
          return;
        }

        const taxCodeItem = (availableTaxCodes || []).find(
          item => item.id === taxCode
        );

        if (!taxCodeItem?.taxCode) {
          return;
        }

        if (
          get(taxCodeExportValidationSchema, [
            taxCodeItem.taxCode,
            documentDirection,
          ]) === false
        ) {
          ctx.addIssue({
            code: 'custom',
            path: ['bookings', index, 'taxCode'],
            params: {
              translationKey:
                'document.requestApproval.inputs.errors.bookingKeyInvalid',
            },
          });
        }
      });
    });
};

const costCenterAndObjectSchema = ({
  hasCostCenters,
  hasCostObjects,
  integration,
}: DocumentApprovalFormSchemaOptions) =>
  formSchema
    .pick({
      bookings: true,
      rejected: true,
    })
    .superRefine(({ bookings, rejected }, ctx) => {
      if (rejected) {
        return;
      }

      if (hasCostCenters && integration !== 'SAP') {
        bookings.forEach(({ costCenter }, index) => {
          if (typeof costCenter?.value !== 'string') {
            ctx.addIssue({
              code: 'invalid_type',
              path: ['bookings', index, 'costCenter', 'value'],
              expected: 'string',
              received: 'null',
            });

            return;
          }
        });
      }

      if (!hasCostCenters && hasCostObjects && integration !== 'SAP') {
        bookings.forEach(({ costObject }, index) => {
          if (typeof costObject?.value !== 'string') {
            ctx.addIssue({
              code: 'invalid_type',
              path: ['bookings', index, 'costObject', 'value'],
              expected: 'string',
              received: 'null',
            });

            return;
          }
        });
      }
    });

const generalLedgerAccountSchema = ({
  shouldRequireGeneralLedgerAccount,
}: DocumentApprovalFormSchemaOptions) => {
  return formSchema
    .pick({
      bookings: true,
      rejected: true,
    })
    .superRefine(({ bookings, rejected }, ctx) => {
      if (rejected) {
        return;
      }

      bookings.forEach(({ generalLedgerAccount, sapExpenseType }, index) => {
        if (sapExpenseType) {
          return;
        }

        if (
          shouldRequireGeneralLedgerAccount &&
          typeof generalLedgerAccount?.value !== 'string'
        ) {
          ctx.addIssue({
            code: 'custom',
            path: ['bookings', index, 'generalLedgerAccount', 'value'],
            params: {
              translationKey: requiredTranslationKey,
            },
          });

          return;
        }
      });
    });
};

const commentSchema = formSchema
  .pick({
    comment: true,
    rejected: true,
  })
  .superRefine(({ comment, rejected }, ctx) => {
    const commentLength = comment?.length ?? 0;
    if (
      rejected &&
      (commentLength < COMMENT_LENGTH_MIN || commentLength > COMMENT_LENGTH_MAX)
    ) {
      ctx.addIssue({
        code: 'custom',
        path: ['comment'],
        params: {
          translationKey: 'document.approveDocument.inputs.comment.errorLabel',
        },
      });

      return;
    }
  });

const accountingAreaSchema = ({
  shouldShowAccountingAreaField,
}: Pick<
  DocumentApprovalFormSchemaOptions,
  'shouldShowAccountingAreaField'
>) => {
  return formSchema
    .pick({
      accountingArea: true,
      rejected: true,
    })
    .superRefine(({ accountingArea, rejected }, ctx) => {
      if (rejected) {
        return;
      }

      if (shouldShowAccountingAreaField && !accountingArea?.value) {
        ctx.addIssue({
          code: 'custom',
          path: ['accountingArea', 'value'],
          params: {
            translationKey: requiredTranslationKey,
          },
        });

        return;
      }
    });
};

export const documentApprovalFormSchema = ({
  availableTaxCodes,
  documentDirection,
  hasCostCenters,
  hasCostObjects,
  shouldShowAccountingAreaField,
  integration,
  shouldRequireGeneralLedgerAccount,
  shouldRequireTaxCode,
}: DocumentApprovalFormSchemaOptions) => {
  return formSchema
    .and(accountingAreaSchema({ shouldShowAccountingAreaField }))
    .and(
      taxCodeSchema({
        integration,
        availableTaxCodes,
        documentDirection,
        shouldRequireTaxCode,
      })
    )
    .and(
      costCenterAndObjectSchema({
        hasCostCenters,
        hasCostObjects,
        integration,
      })
    )
    .and(
      generalLedgerAccountSchema({
        shouldRequireGeneralLedgerAccount,
      })
    )
    .and(commentSchema);
};

export type DocumentApprovalFormValues = z.infer<
  ReturnType<typeof documentApprovalFormSchema>
>;
