import { Flex, Text, Tag } from '@candisio/design-system';
import { Money } from 'generated-types/graphql.types';
import { useMoneyFormatter } from 'hooks/useMoneyFormatter';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { formatDate } from './utils';

type DetailsBoxProps = {
  boxType: 'purchaseOrder' | 'goodsReceipt';
  statusLabel?: string;
  itemNumber?: string;
  date?: string;
  amount?: Money | null;
};

export const DetailsBox = ({
  boxType,
  statusLabel,
  itemNumber,
  date,
  amount,
}: DetailsBoxProps) => {
  const { documentMoneyFormatter } = useMoneyFormatter();
  const [t] = useTranslation(LOCALE_NAME_SPACE.PURCHASE_ORDERS);

  return (
    <Flex
      gap="space12"
      padding="space14 space8 space16 space8"
      data-cy="purchase-order-details-box"
      direction="column">
      <Tag
        variant="secondary"
        color="gray"
        style={{ borderColor: 'transparent', padding: '.25rem .5rem' }}>
        <Text fontWeight="semibold" fontSize="basic" color="gray800">
          {boxType === 'purchaseOrder'
            ? t('detailsCard.title')
            : t('detailsCard.goodsReceiptTitle')}
        </Text>
      </Tag>
      <Flex justifyContent="space-between">
        <Flex gap="space4" borderRadius="medium" direction="column">
          <Text
            fontWeight="semibold"
            fontSize="basic"
            color="gray800"
            lineHeight="140%">
            {itemNumber ?? ''}
          </Text>
          <Text color="gray500" fontSize="basic" lineHeight="140%">
            {formatDate(date)}
          </Text>
        </Flex>
        <Flex direction="column" gap="space4">
          <Tag
            variant="secondary"
            color="gray"
            style={{ borderColor: 'transparent' }}>
            <Text
              textTransform="uppercase"
              color="gray600"
              fontWeight="bold"
              lineHeight="space18"
              fontSize="small">
              {statusLabel}
            </Text>
          </Tag>
          {amount && (
            <Text color="gray500" fontSize="basic" lineHeight="140%">
              {documentMoneyFormatter(amount)}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
