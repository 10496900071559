import { useAccountingAreaFieldOptions } from 'components/Form/hooks/useAccountingAreaFieldItems';
import { useCostCenterFieldOptions } from 'components/Form/hooks/useCostCenterFieldOptions';
import { useGeneralLedgerAccountFieldOptions } from 'components/Form/hooks/useGeneralLedgerAccountFieldOptions';
import { getAutoFocusField } from 'components/Form/ProcessingForm/getAutoFocusField';
import { ProcessingFormFieldOptions } from 'components/Form/ProcessingForm/ProcessingForm';
import { useHasLinkedTransaction } from 'components/Form/ProcessingForm/useHasInvoiceTransaction';
import {
  ArtistSocialInsuranceCode,
  CostCenterTypes,
  DocumentCurrency,
  useOrganizationQuery,
  useProcessingFormFieldItemsQuery,
} from 'generated-types/graphql.types';
import { useOtherIntegration } from 'orgConfig/other';
import { useSap } from 'orgConfig/sap';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { isNilOrEmpty } from 'utils/isNilOrEmpty';
import { useSapPurchaseOrders } from 'views/DocumentDetails/components/ThreeWayMatch/hooks/useSapPurchaseOrders';
import {
  useShowAccountingAreaField,
  useShowArtistSocialInsuranceCodeField,
  useShowCostCenterField,
  useShowCostObjectField,
  useShowDeliveryDateField,
  useShowExtraCostInfoField,
  useShowGeneralLedgerAccountField,
  useShowPostingDateField,
  useShowPostingTextField,
  useShowPurchaseOrderNumberField,
  useShowTaxCodeField,
} from 'views/utils/useShouldShowField';
import { DOCUMENT_TYPES } from './consts';
import { toAccountingDataFieldItem } from './toAccountingDataFieldItem';
import { sortCurrencies, toCurrencyFieldItem } from './toCurrencyFieldItem';
import { toPaymentConditionFieldItem } from './toPaymentConditionFieldItem';
import { toWorkflowFieldItem } from './toWorkflowFieldItem';
import { useContactFieldOptions } from './useContactFieldOptions';
import { ProcessingFormInitialData } from './useProcessingFormInitialData';
import { useToApproverFieldItem } from './useToApproverFieldItem';
import { useToArtistSocialInsuranceCodeFieldItem } from './useToArtistSocialInsuranceCodeFieldItem';
import { useToTypeFieldItem } from './useToTypeFieldItem';

const { RECHNUNGSEINGANG, RECHNUNGSKORREKTUR, EINGANGSGUTSCHRIFT } =
  DOCUMENT_TYPES;

const SAP_DOCUMENT_TYPES = [RECHNUNGSEINGANG, EINGANGSGUTSCHRIFT];
export const OTHER_INTEGRATION_DOCUMENT_TYPES = [
  RECHNUNGSEINGANG,
  RECHNUNGSKORREKTUR,
];

interface UseProcessingFormFieldOptionsProps {
  documentId?: string;
  accountsPayableNumber?: string;
  defaultValues?: ProcessingFormInitialData['defaultValues'];
}

/**
 * Returns options for processing form fields.
 *
 * Sets select / combo box items for the following fields:
 * - (document) type
 * - contact
 * - payment condition
 * - general ledger account number
 * - tax code
 * - cost center
 * - cost object
 * - artist social insurance number
 * - extra cost info
 * - workflow
 * - approvers
 *
 * Sets discount date, percentage and amount to read only if the organization is
 * using payment conditions from Datev Rechnungswesen (ReWe).
 *
 * Hides the posting text field if the organization does not have the Datev
 * Buchungsdatenservice (BDS) integration enabled.
 *
 * Hides the following fields if the organization doesn’t have active entities
 * of the corresponding type:
 *
 * - general ledger account number
 * - tax code
 * - cost center
 * - cost object
 * - extra cost info
 * - workflow
 *
 * Hides the following fields if the organization does not have the
 * corresponding feature flag enabled:
 *
 * - artist social insurance code
 * - extra cost info
 */

export const useProcessingFormFieldOptions = ({
  documentId,
  defaultValues,
}: UseProcessingFormFieldOptionsProps = {}) => {
  const hasLinkedTransaction = useHasLinkedTransaction(documentId);

  const showAccountingAreaField = useShowAccountingAreaField({
    isAccountingAreaOnDocument: !isNilOrEmpty(
      defaultValues?.accountingArea?.value
    ),
  });

  const showTaxCodeField = useShowTaxCodeField();
  const showPostingTextField = useShowPostingTextField();
  const showCostCenterField = useShowCostCenterField();
  const showCostObjectField = useShowCostObjectField();
  const showExtraCostInfoField = useShowExtraCostInfoField();
  const showGeneralLedgerAccountField = useShowGeneralLedgerAccountField();
  const showArtistSocialInsuranceCodeField =
    useShowArtistSocialInsuranceCodeField();

  const showDeliveryDateField = useShowDeliveryDateField();
  const showPostingDateField = useShowPostingDateField();

  const showPurchaseOrderNumberField = useShowPurchaseOrderNumberField();

  // TODO PX-231 use isConfigured once available
  const sap = useSap();

  const otherIntegration = useOtherIntegration();

  const organizationId = useOrganizationId();
  const { data: organizationData } = useOrganizationQuery({
    variables: { id: organizationId },
  });

  const { purchaseOrders } = useSapPurchaseOrders(documentId);

  const isPurchaseOrderLinked = Boolean(
    sap.shouldUseSapPurchaseOrder && purchaseOrders.length
  );

  const autoFocusField = getAutoFocusField({
    hasLinkedTransaction,
    hasLinkedPurchaseOrder: isPurchaseOrderLinked,
    isUsingSAP: sap.isActive,
  });

  const hasWorkflows =
    organizationData?.organization?.hasActiveWorkflowTemplates ?? false;

  const disableCashDiscountFields =
    !!organizationData?.organization?.exportConfiguration?.isReweEnabled ||
    sap.isActive;

  const toArtistSocialInsuranceCodeFieldItem =
    useToArtistSocialInsuranceCodeFieldItem();

  const { data: fieldItemsData, loading } = useProcessingFormFieldItemsQuery();

  const contactFieldOptions = useContactFieldOptions(
    defaultValues?.contact?.inputValue
  );

  const generalLedgerAccountFieldOptions = useGeneralLedgerAccountFieldOptions({
    skip:
      !showGeneralLedgerAccountField &&
      !defaultValues?.bookings?.[0]?.generalLedgerAccount?.value,
  });

  const accountingAreaFieldOptions = useAccountingAreaFieldOptions({
    skip: !showAccountingAreaField,
    accountingAreas: fieldItemsData?.accountingAreas ?? [],
  });

  const costCenterFieldOptions = useCostCenterFieldOptions({
    skip:
      !showCostCenterField && !defaultValues?.bookings?.[0]?.costCenter?.value,
    type: CostCenterTypes.CostCenter,
  });

  const costObjectFieldOptions = useCostCenterFieldOptions({
    skip:
      !showCostObjectField && !defaultValues?.bookings?.[0]?.costObject?.value,
    type: CostCenterTypes.CostObject,
  });

  const extraCostInfoFieldOptions = useCostCenterFieldOptions({
    skip:
      !showExtraCostInfoField &&
      !defaultValues?.bookings?.[0]?.extraCostInfo?.value,
    type: CostCenterTypes.ExtraCostInfo,
  });

  const {
    approvers = [],
    paymentConditions = [],
    taxCodes = [],
    types = [],
    workflows = [],
    getOrgMembersAbsence: orgMembersAbsence = [],
  } = fieldItemsData ?? {};

  const toTypeFieldItem = useToTypeFieldItem();
  const toApproverFieldItem = useToApproverFieldItem(
    orgMembersAbsence,
    approvers
  );

  const fieldOptions: ProcessingFormFieldOptions = {
    approvers: {
      defaultItems: approvers.map(toApproverFieldItem),
    },
    artistSocialInsuranceCode: {
      hidden:
        !showArtistSocialInsuranceCodeField &&
        !defaultValues?.bookings?.[0]?.artistSocialInsuranceCode,
      props: {
        defaultItems: Object.values(ArtistSocialInsuranceCode).map(
          toArtistSocialInsuranceCodeFieldItem
        ),
      },
    },
    contact: {
      ...contactFieldOptions,
      readOnly: isPurchaseOrderLinked || contactFieldOptions?.readOnly,
      autoFocus: autoFocusField === 'contact',
    },
    costCenter: costCenterFieldOptions,
    costObject: costObjectFieldOptions,
    extraCostInfo: extraCostInfoFieldOptions,
    currency: {
      defaultItems: sortCurrencies(Object.values(DocumentCurrency)).map(
        toCurrencyFieldItem
      ),
    },
    ...(disableCashDiscountFields && {
      // Discount date, percentage and amount are read only when the
      // organization is using payment conditions from Datev Rechnungswesen
      // (ReWe)
      discountDate: { readOnly: true },
      discountPercentage: { readOnly: true },
      discountAmount: { readOnly: true },
    }),
    generalLedgerAccount: generalLedgerAccountFieldOptions,
    hasLinkedTransaction,
    paymentCondition: {
      items: paymentConditions.map(toPaymentConditionFieldItem),
    },
    postingText: {
      hidden:
        !showPostingTextField && !defaultValues?.bookings?.[0]?.postingText,

      props: {},
    },
    taxCode: {
      hidden: !showTaxCodeField && !defaultValues?.bookings?.[0]?.taxCode,
      props: { defaultItems: taxCodes.map(toAccountingDataFieldItem) },
    },
    accountingArea: accountingAreaFieldOptions,
    type: sap.isActive
      ? {
          items: types
            .filter(type =>
              SAP_DOCUMENT_TYPES.includes(
                type.documentType as (typeof SAP_DOCUMENT_TYPES)[number]
              )
            )
            .map(toTypeFieldItem),
          autoFocus: autoFocusField === 'documentType',
        }
      : otherIntegration.showDocumentTypesForOtherIntegrations
      ? {
          items: types
            .filter(type =>
              OTHER_INTEGRATION_DOCUMENT_TYPES.includes(
                type.documentType as (typeof OTHER_INTEGRATION_DOCUMENT_TYPES)[number]
              )
            )
            .map(toTypeFieldItem),
          autoFocus: autoFocusField === 'documentType',
        }
      : {
          items: types.map(toTypeFieldItem),
          autoFocus: autoFocusField === 'documentType',
        },
    workflow: {
      hidden: !hasWorkflows && !defaultValues?.workflow,
      props: {
        defaultItems: workflows.map(workflow =>
          toWorkflowFieldItem(workflow, orgMembersAbsence)
        ),
      },
    },
    deliveryDate: { hidden: !showDeliveryDateField },
    postingDate: { hidden: !showPostingDateField },
    invoiceDate: { autoFocus: autoFocusField === 'invoiceDate' },
    purchaseOrderNumber: {
      hidden: !showPurchaseOrderNumberField,
    },
  };

  return { fieldOptions, loading };
};
