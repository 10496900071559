import {
  ACTIVITY_SOURCE,
  BaseItemProps,
} from 'components/History/items/BaseItem';
import {
  ApprovedEventDataFragment,
  ApproverExtractedEventDataFragment,
  AttachmentConvertedToDocumentEvent,
  AttachmentDeletedFromDocumentEvent,
  AttachmentDetachedFromDocumentEvent,
  ContactExtractionEventDataFragment,
  ContactSupplierSendEmailEventDataFragment,
  CostCenterExtractionEventDataFragment,
  DocumentCommentEventDataFragment,
  DocumentConvertedToAttachmentEvent,
  DocumentFileReplacedEvent,
  DocumentImportedByMigrationEvent,
  DocumentPaymentInfoUpdatedEvent,
  DocumentPaymentInfoUpdatedEventDataFragment,
  DocumentResetByCandisEventDataFragment,
  DocumentResetManualEventDataFragment,
  DocumentResetTransactionAmountUpdatedEventDataFragment,
  DocumentResetTransactionLinkedEventDataFragment,
  DocumentResetTransactionUnLinkedEventDataFragment,
  DocumentResetUserDeactivationEventDataFragment,
  DocumentResetUserRoleChangedEventDataFragment,
  DocumentStoredAsEcmDocumentEvent,
  DocumentTimeLineItem,
  DocumentTransactionCommentCreatedEvent,
  DocumentTransactionLinkedEvent,
  DocumentTransactionReconciledEvent,
  DocumentTransactionStatusUpdatedEvent,
  DocumentTransactionUnlinkedEvent,
  DocumentUpdatedAsEcmDocumentEvent,
  ExportedEventDataFragment,
  FileAttachedToDocumentEvent,
  FileUploadedByEmailEventDataFragment,
  FileUploadedEventDataFragment,
  MetaDataExtractionEventDataFragment,
  ProvisionCreatedEvent,
  ProvisionDeletedEvent,
  ProvisionExportedEventDataFragment,
  RejectedEventDataFragment,
  RequestApprovalEventDataFragment,
  ReversalCreatedEvent,
  ReversalExportedEventDataFragment,
  SepaTransferGeneratedEventDataFragment,
  SingleBookingUpdatedEvent,
  SplitBookingUpdatedEvent,
  UserApprovalDocumentWorkflowStepDataFragment,
  WorkflowStepSkippedEventDataFragment,
  WorkflowTemplateAppliedToDocumentEventDataFragment,
  SubstituteAddedEventDataFragment,
  SubstituteRemovedEventDataFragment,
  PurchaseOrderLinkedEvent,
  PurchaseOrderUnlinkedEvent,
  DocumentRelationshipCreatedEvent,
  DocumentRelationshipRemovedEvent,
  DocumentTagUpdatedEvent,
  DocumentGrossNetTaxRoundingAmountChangedEvent,
  AccountingAreaUpdatedEvent,
  DocumentAssociationsCreatedEvent,
  DocumentAssociationsRemovedEvent,
} from 'generated-types/graphql.types';
import { FunctionComponent } from 'react';

export type SupportedFragment =
  | ApprovedEventDataFragment
  | ApproverExtractedEventDataFragment
  | AttachmentConvertedToDocumentEvent
  | AttachmentDeletedFromDocumentEvent
  | AttachmentDetachedFromDocumentEvent
  | ContactExtractionEventDataFragment
  | CostCenterExtractionEventDataFragment
  | DocumentCommentEventDataFragment
  | DocumentConvertedToAttachmentEvent
  | DocumentFileReplacedEvent
  | DocumentPaymentInfoUpdatedEventDataFragment
  | DocumentResetByCandisEventDataFragment
  | DocumentResetManualEventDataFragment
  | DocumentResetTransactionAmountUpdatedEventDataFragment
  | DocumentResetTransactionLinkedEventDataFragment
  | DocumentResetTransactionUnLinkedEventDataFragment
  | DocumentResetUserDeactivationEventDataFragment
  | DocumentResetUserRoleChangedEventDataFragment
  | DocumentStoredAsEcmDocumentEvent
  | DocumentTransactionCommentCreatedEvent
  | DocumentTransactionLinkedEvent
  | DocumentTransactionReconciledEvent
  | DocumentTransactionStatusUpdatedEvent
  | DocumentTransactionUnlinkedEvent
  | DocumentUpdatedAsEcmDocumentEvent
  | ExportedEventDataFragment
  | FileAttachedToDocumentEvent
  | FileUploadedByEmailEventDataFragment
  | FileUploadedEventDataFragment
  | MetaDataExtractionEventDataFragment
  | ProvisionExportedEventDataFragment
  | ProvisionCreatedEvent
  | ProvisionDeletedEvent
  | ReversalCreatedEvent
  | ReversalExportedEventDataFragment
  | RejectedEventDataFragment
  | RequestApprovalEventDataFragment
  | SepaTransferGeneratedEventDataFragment
  | SingleBookingUpdatedEvent
  | SplitBookingUpdatedEvent
  | UserApprovalDocumentWorkflowStepDataFragment
  | WorkflowStepSkippedEventDataFragment
  | ContactSupplierSendEmailEventDataFragment
  | WorkflowTemplateAppliedToDocumentEventDataFragment
  | DocumentImportedByMigrationEvent
  | SubstituteAddedEventDataFragment
  | SubstituteRemovedEventDataFragment
  | PurchaseOrderUnlinkedEvent
  | PurchaseOrderLinkedEvent
  | DocumentRelationshipCreatedEvent
  | DocumentRelationshipRemovedEvent
  | DocumentTagUpdatedEvent
  | DocumentGrossNetTaxRoundingAmountChangedEvent
  | AccountingAreaUpdatedEvent
  | DocumentAssociationsCreatedEvent
  | DocumentAssociationsRemovedEvent;

/**
 * The events (fragments) supported by the DocumentHistory Component
 */
export type SupportedEvent = DocumentTimeLineItem & SupportedFragment;

export type SupportedEventKeys = Required<SupportedEvent>['__typename'];

/**
 * Helper to type the keys of an object and let TS infer the valuese
 */
export const mapWithSupportedEventKeys = (obj: {
  [k in SupportedEventKeys]: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    item: FunctionComponent<BaseItemProps>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    content: FunctionComponent<any>;
    collapsible?: boolean | ((event: SupportedEvent) => boolean);
    source?: ACTIVITY_SOURCE;
    /** Determines if the event should be skipped from activity timeline */
    skip?: (event: any) => boolean;
  };
}) => obj;

export const isUserEvent = (
  event: any
): event is Pick<FileUploadedEventDataFragment, 'user'> => !!event.user;

export const isFileUploadedEvent = (
  event: any
): event is FileUploadedEventDataFragment =>
  event.__typename === 'FileUploadedEvent';

export const isDocumentCommentEvent = (
  event: any
): event is DocumentCommentEventDataFragment =>
  !!event.comment && !!event.comment.id;

export const isDocumentSepaTransferGeneratedEvent = (
  event: any
): event is SepaTransferGeneratedEventDataFragment =>
  event.__typename === 'DocumentSepaTransferGeneratedEvent';

export const isDocumentPaymentInfoUpdatedEvent = (
  event: any
): event is DocumentPaymentInfoUpdatedEvent =>
  event.__typename === 'DocumentPaymentInfoUpdatedEvent';
