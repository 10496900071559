import { Card, Text, Grid, Button, Image } from '@candisio/design-system';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import receiptIcon from '../media/receipt.svg';

interface CreateReimbursementCardProps {
  isLoading: boolean;
  onCreateReimbursement: () => Promise<void>;
}

export const CreateReimbursementCard = ({
  isLoading,
  onCreateReimbursement,
}: CreateReimbursementCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  return (
    <Grid
      background="gray0"
      padding="space16"
      gap="space24"
      placeItems="center"
      placeContent="center"
      borderRadius="medium">
      <Grid gap="space12" templateColumns="repeat(3, auto)">
        <Card background="pink100" opacity="50%">
          <Image src={receiptIcon} alt="" />
        </Card>
        <Card background="pink100">
          <Image src={receiptIcon} alt="" />
        </Card>
        <Card background="pink100" opacity="50%">
          <Image src={receiptIcon} alt="" />
        </Card>
      </Grid>
      <Text textAlign="center" fontSize="basic">
        {t('dashboard.createExpense.text')}
      </Text>
      <Button onClick={onCreateReimbursement} loading={isLoading}>
        {t('dashboard.createExpense.createCTA')}
      </Button>
    </Grid>
  );
};
