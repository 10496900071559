import { ProcessingFormContactFieldItem } from 'components/Form/ProcessingForm/ProcessingFormContactField';
import { ProcessSidebar } from 'components/ProcessSidebar/ProcessSidebar';
import { GetDocumentForDraftQuery } from 'generated-types/graphql.types';
import { DocumentStatusFrontend } from 'models';
import { MonitoringActionsContainer } from './MonitoringActionsContainer';
import { MonitoringFormContainer } from './MonitoringFormContainer';

export interface MonitoringProps {
  document?: GetDocumentForDraftQuery['getDocument'];
  toggleEditMode?: () => void;
  loading?: boolean;
  purchaseOrderId?: string;
  contactItem?: ProcessingFormContactFieldItem;
  onGetContactItem?: (value?: ProcessingFormContactFieldItem) => void;
}

export const Monitoring = ({
  document,
  loading,
  toggleEditMode,
  purchaseOrderId,
  contactItem,
  onGetContactItem,
}: MonitoringProps) => {
  if (loading) {
    // TODO loading state
    return null;
  }

  if (!document) {
    // TODO Add error state
    return null;
  }

  return (
    <ProcessSidebar
      key={purchaseOrderId}
      documentId={document.id}
      globalDocumentId={document.globalDocumentId ?? undefined}
      documentStatus={DocumentStatusFrontend.AwaitingApproval}
      actions={
        <MonitoringActionsContainer
          documentId={document.id}
          onEdit={toggleEditMode}
        />
      }>
      <MonitoringFormContainer
        documentId={document.id}
        contactItem={contactItem}
        onGetContactItem={onGetContactItem}
      />
    </ProcessSidebar>
  );
};
