import {
  Box,
  CustomEmptyStateProps,
  FilterOptionsAccessor,
} from '@candisio/design-system';
import { Configuration } from 'components/Table/Configurations/ConfigurationsMenu';
import { queryParameter } from 'components/Table/consts';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { Routes } from 'models';
import { OnLoadMore } from 'providers/GraphQLProvider/Pagination/usePagination';
import { useOrganizationId } from 'providers/OrganizationProvider';
import { generatePath, useNavigate } from 'react-router-dom-v5-compat';
import { Filters, SortingRule } from 'react-table';
import { TabView } from 'views/Inbox/models';
import { ReimbursementsTable } from 'views/Reimbursement/elements/Table/ReimbursementsTable';
import { ReimbursementsTableEmptyStateArchive } from 'views/Reimbursement/elements/Table/ReimbursementsTableEmptyStateArchive';
import { REIMBURSEMENT_VIEW_ROUTES } from 'views/Reimbursement/hooks/useReimbursementNavigation';
import { REIMBURSEMENT_URL_PARAM } from 'views/Reimbursement/Reimbursement';
import {
  ReimbursementTableColumnKeys,
  ReimbursementTableRow,
} from 'views/Reimbursement/toolkit/types';
import { ArchiveViewLayout } from '../components/ArchiveViewLayout';

interface ArchiveReimbursementsProps {
  reimbursementsCount: number;
  reimbursements: ReimbursementTableRow[];
  filters: Filters<ReimbursementTableRow>;
  sortBy: SortingRule<ReimbursementTableRow>[];
  isLoadingReimbursements?: boolean;
  isLoadingConfigurations?: boolean;
  visibleColumnIds?: ReimbursementTableColumnKeys[];
  filterOptions?: FilterOptionsAccessor<ReimbursementTableRow>;
  isTableEmpty: boolean;
  isTableFiltered: boolean;
  configurations: Configuration[];
  onFilter: (filters: Filters<ReimbursementTableRow>) => void;
  onSort: (sortBy: SortingRule<ReimbursementTableRow>[]) => void;
  onDebounceSearch: (search: string) => void;
  onResetTableConfigurations: () => void;
  onUpdateConfigurations: (newConfigurations: Configuration[]) => void;
  onLoadMore: OnLoadMore;
}

export const ArchiveReimbursements = ({
  reimbursementsCount,
  reimbursements,
  filters,
  sortBy,
  isLoadingReimbursements,
  isLoadingConfigurations,
  filterOptions,
  configurations,
  visibleColumnIds,
  isTableEmpty,
  isTableFiltered,
  onDebounceSearch,
  onFilter,
  onSort,
  onLoadMore,
  onResetTableConfigurations,
  onUpdateConfigurations,
}: ArchiveReimbursementsProps) => {
  const { updateSearchParam, searchParams } = useMutateSearchParams();
  const queryStringFilter = searchParams.get(queryParameter) ?? '';
  const organizationSlug = useOrganizationId();
  const navigate = useNavigate();

  const handleSearch = (search: string) => {
    onDebounceSearch(search);
    updateSearchParam(queryParameter, search);
  };

  const customEmptyState = ({ resetFilters }: CustomEmptyStateProps) =>
    isTableEmpty ? (
      <ReimbursementsTableEmptyStateArchive
        resetFilters={resetFilters}
        isTableFiltered={isTableFiltered}
      />
    ) : null;

  const handleRowClick = (id: string, cursor?: string) => {
    if (!cursor) return;

    searchParams.set(REIMBURSEMENT_URL_PARAM.CURSOR, cursor);
    const pathname = generatePath(REIMBURSEMENT_VIEW_ROUTES[Routes.ARCHIVE], {
      organizationSlug,
      reimbursementId: id,
    });

    navigate({
      pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <ArchiveViewLayout activeTab={TabView.ARCHIVE_REIMBURSEMENTS}>
      <Box height="100%" overflow="hidden">
        <ReimbursementsTable
          data={reimbursements}
          visibleColumns={visibleColumnIds}
          initialFilters={filters}
          initialSortBy={sortBy}
          isLoading={isLoadingReimbursements}
          filterOptions={filterOptions}
          configurations={configurations}
          search={queryStringFilter}
          isLoadingConfigurations={isLoadingConfigurations}
          onEndReached={onLoadMore}
          onFilter={onFilter}
          onRowClick={handleRowClick}
          onResetTableConfigurations={onResetTableConfigurations}
          onSearch={handleSearch}
          onSort={onSort}
          onUpdateTableConfigurations={onUpdateConfigurations}
          customEmptyState={customEmptyState}
        />
      </Box>
    </ArchiveViewLayout>
  );
};
