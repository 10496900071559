import gql from 'graphql-tag';
import { tagFragment } from 'views/Settings/DocumentTags/toolkit/gql';

/**
 * Create fragment to reuse object resolving
 */

export const organizationCreditCardsSettingsDataFragment = gql`
  fragment OrganizationCreditCardsSettingsData on OrganizationCreditCardsSettings {
    tradeName
    legalForm
    foundationDate
    country
    accountType
    nationalRegisterCourt
    nationalRegisterId
    nationalRegisterType
    tradeRegisterNumber
    vatId
    billingEmail
    isCompanyNamePrintable
    deliveryAddressSameAsBillingAddress
    billingAddress {
      country
      city
      postalCode
      streetFull
      streetName
      streetNumber
      streetAdditional
    }
    deliveryAddress {
      country
      city
      postalCode
      streetFull
      streetName
      streetNumber
      streetAdditional
    }
    processingAccount {
      bankName
      iban
    }
  }
`;

export const documentDataFragment = gql`
  fragment DocumentData on Document {
    id
    isDuplicate
    isOverdue
    isWaitingForClarification
    contact {
      value {
        id
        accountsPayableNumber
        accountsReceivableNumber
        name {
          value
        }
      }
    }
    status
    invoiceNumber {
      value
    }
    purchaseOrderData {
      orderNumber
    }
    category {
      direction {
        value
      }
      documentType {
        value
      }
    }
    invoiceDate {
      value
    }
    accountingArea {
      value {
        id
        name
      }
    }
    bookings {
      dueDate {
        value
      }
      note {
        value
      }
      postingText
      artistSocialInsuranceCode
      costCenter {
        value {
          id
          name
          readableName
        }
      }
      costObject {
        value {
          id
          name
          readableName
        }
      }
      extraCostInfo {
        value {
          id
          name
          readableName
        }
      }
      generalLedgerAccount {
        value {
          id
          accountCode
          readableName
        }
      }
      vatRate {
        value
      }
      discountAmount {
        value
      }
      discountPercentage {
        value
      }
      discountPaymentDate {
        value
      }
    }
    invoiceDeliveryDate {
      value
    }
    invoicePostingDate {
      value
    }
    amount {
      value
    }
    currency {
      value
    }
    invoiceDate {
      value
    }
    requestedAt {
      value
    }
    createdAt
    requester {
      id
      name
      avatarUrl
    }
    iban {
      value
    }
    swiftCode {
      value
    }
    createTransfer {
      value
    }
    approvers {
      id
      name
      avatarUrl
    }
    approvedBy {
      id
    }
    suggestedApprovers {
      value {
        id
        name
        avatarUrl
      }
    }
    documentFile {
      id
      name
      url
      versions
      pages
    }
    originalDocumentId {
      value
    }
    extractedContact {
      id
      cachingKey
      name {
        value
      }
      iban {
        value
      }
      taxNumber {
        value
      }
      vatId {
        value
      }
    }
    hasTransactionLinked
    globalDocumentId
    tags {
      ...Tag
    }
  }
  ${tagFragment}
`;

export const inboxInvoiceDocumentDataFragment = gql`
  fragment InboxInvoiceDocumentData on InboxInvoiceDocument {
    id
    invoiceNumber
    status
    isPayable
    invoiceDeliveryDate
    createdAt
    isOverdue
    invoiceDate
    bookings {
      artistSocialInsuranceCode
      dueDate
      discountAmount
      discountPercentage
      discountPaymentDate
      paymentConditionId
      costCenter {
        id
        readableName
        cachingKey
      }
      costObject {
        id
        readableName
        cachingKey
      }
      extraCostInfo {
        id
        readableName
        cachingKey
      }
    }
    contact {
      id
      name
      cachingKey
    }
    payment {
      id
      isPaid
      method
    }
    amount {
      amount
      currency
      precision
    }
    purchaseOrderData @include(if: $withPurchaseOrderData) {
      orderNumber
      integrationType
    }
    tags {
      id
      name
    }
  }
`;

export const approvalInvoiceDocumentDataFragment = gql`
  fragment ApprovalInvoiceDocumentData on ApprovalInvoiceDocument {
    id
    invoiceNumber
    status
    isPayable
    invoiceDeliveryDate
    createdAt
    isOverdue
    invoiceDate
    bookings {
      artistSocialInsuranceCode
      dueDate
      discountAmount
      discountPercentage
      discountPaymentDate
      paymentConditionId
      costCenter {
        id
        readableName
        cachingKey
      }
      costObject {
        id
        readableName
        cachingKey
      }
      extraCostInfo {
        id
        readableName
        cachingKey
      }
    }
    contact {
      id
      name
      cachingKey
    }
    payment {
      id
      isPaid
      method
    }
    amount {
      amount
      currency
      precision
    }
    purchaseOrderData @include(if: $withPurchaseOrderData) {
      orderNumber
      integrationType
    }
    tags {
      id
      name
    }
    requester {
      id
      name
      avatarUrl
    }
    approvers {
      id
      name
      avatarUrl
    }
  }
`;

export const documentListDataFragment = gql`
  fragment DocumentListData on Document {
    id
    isOverdue
    isWaitingForClarification
    isDuplicate
    isPayable
    isEInvoice
    contact {
      value {
        id
        accountsPayableNumber
        accountsReceivableNumber
        name {
          value
        }
      }
    }
    status
    category {
      direction {
        value
      }
      documentType {
        value
      }
    }
    invoiceNumber {
      value
    }
    amount {
      value
    }
    currency {
      value
    }
    createdAt
    requestedAt {
      value
    }
    invoiceDate {
      value
    }
    invoiceDeliveryDate {
      value
    }
    extractedContact {
      id
      cachingKey
      name {
        value
      }
    }
    iban {
      value
    }
    swiftCode {
      value
    }
    accountingArea {
      value {
        id
        name
      }
    }
    bookings {
      id
      artistSocialInsuranceCode
      costCenter {
        value {
          id
          readableName
        }
      }
      costObject {
        value {
          id
          readableName
        }
      }
      extraCostInfo {
        value {
          id
          name
          readableName
        }
      }
      generalLedgerAccount {
        value {
          id
          readableName
        }
      }
      bookingKey {
        value {
          id
          readableName
        }
      }
      dueDate {
        value
      }
      note {
        value
      }
      postingText
      discountAmount {
        value
      }
      discountPercentage {
        value
      }
      discountPaymentDate {
        value
      }
    }
    requester {
      id
      name
      avatarUrl
    }
    approvers {
      id
      name
      avatarUrl
    }
    approvedBy {
      id
    }
    paymentState {
      __typename
      ... on PaidDocumentState {
        paidAt
        method
      }
    }
    purchaseOrderData @include(if: $withPurchaseOrderData) {
      orderNumber
      integrationType
    }
    purchaseOrders @include(if: $withPurchaseOrderData) {
      orderNumber
      goodsReceipts {
        _id
      }
    }
    tags {
      ...Tag
    }
  }
  ${tagFragment}
`;

export const bookingKeyArchivedErrorFragment = gql`
  fragment BookingKeyArchivedErrorData on BookingKeyArchivedError {
    bookingKey {
      id
      readableName
    }
  }
`;

export const costCenterArchivedErrorFragment = gql`
  fragment CostCenterArchivedErrorData on CostCenterArchivedError {
    costCenter {
      id
      readableName
    }
  }
`;

export const costObjectArchivedErrorFragment = gql`
  fragment CostObjectArchivedErrorData on CostObjectArchivedError {
    costObject {
      id
      readableName
    }
  }
`;

export const extraCostInfoArchivedErrorFragment = gql`
  fragment ExtraCostInfoArchivedErrorData on ExtraCostInfoArchivedError {
    extraCostInfo {
      id
      readableName
    }
  }
`;

export const documentCannotBeModifedErrorFragment = gql`
  fragment DocumentCannotBeModifedErrorData on DocumentCannotBeModifedError {
    status
  }
`;

export const documentIsAlreadyRequestedForApprovalErrorFragment = gql`
  fragment DocumentIsAlreadyRequestedForApprovalErrorData on DocumentIsAlreadyRequestedForApprovalError {
    status
  }
`;

export const generalLedgerArchivedErrorFragment = gql`
  fragment GeneralLedgerArchivedErrorData on GeneralLedgerArchivedError {
    generalLedgerAccount {
      id
      readableName
    }
  }
`;

export const contactDoesNotExistErrorFragment = gql`
  fragment ContactDoesNotExistErrorData on ContactDoesNotExistError {
    kind
    message
  }
`;

export const workflowTemplateArchivedErrorFragment = gql`
  fragment WorkflowTemplateArchivedErrorData on WorkflowTemplateArchivedError {
    workflowTemplate {
      id
      name
    }
  }
`;

export const workflowStepAlreadyApprovedErrorFragment = gql`
  fragment WorkflowStepAlreadyApprovedErrorData on WorkflowStepAlreadyApprovedError {
    approvedByUser {
      id
      name
    }
  }
`;

export const invalidInvoiceDateErrorFragment = gql`
  fragment InvalidInvoiceDateErrorData on InvalidInvoiceDateError {
    invoiceDate
    referenceDate
  }
`;

export const invalidDueDateErrorFragment = gql`
  fragment InvalidDueDateErrorData on InvalidDueDateError {
    dueDate
    referenceDate
  }
`;

export const invalidDeliveryDateErrorFragment = gql`
  fragment InvalidDeliveryDateErrorData on InvalidDeliveryDateError {
    deliveryDate
    referenceDate
  }
`;

export const bookingsErrorFragment = gql`
  fragment BookingsErrorData on BookingsError {
    index
    error {
      ...BookingKeyArchivedErrorData
      ...CostCenterArchivedErrorData
      ...CostObjectArchivedErrorData
      ...ExtraCostInfoArchivedErrorData
      ...GeneralLedgerArchivedErrorData

      ... on DocumentApprovalError {
        kind
        message
      }
    }
  }
`;

export const transactionDataMismatchErrorFragment = gql`
  fragment TransactionDataMismatchErrorData on TransactionDataMismatchError {
    kind
    message
  }
`;

export const sumOfBookingsDoesNotMatchGrossAmountErrorFragment = gql`
  fragment SumOfBookingsDoesNotMatchGrossAmountErrorData on SumOfBookingsDoesNotMatchGrossAmountError {
    kind
    message
    grossAmount
    bookingsSum
  }
`;

export const documentDoesNotExistErrorFragment = gql`
  fragment DocumentDoesNotExistErrorData on DocumentDoesNotExistError {
    kind
    message
  }
`;

export const basicDocumentConnectionFragment = gql`
  fragment BasicDocumentConnectionData on DocumentConnection {
    edges {
      cursor
      node {
        id
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
      totalCount
    }
  }
`;

export const basicInboxInvoiceDocumentConnectionFragment = gql`
  fragment BasicInboxInvoiceDocumentConnectionData on InboxInvoiceDocumentsResponse {
    edges {
      cursor
      node {
        id
      }
    }
    pageInfo {
      startCursor
      hasNextPage
      totalCount
    }
  }
`;

export const basicApprovalDocumentConnectionFragment = gql`
  fragment BasicApprovalDocumentConnectionData on ApprovalInvoiceDocumentsResponse {
    edges {
      cursor
      node {
        id
      }
    }
    pageInfo {
      startCursor
      hasNextPage
      totalCount
    }
  }
`;

export const transactionDetailsFragment = gql`
  fragment TransactionDetailsData on CardIssuerTransaction {
    id
    status
    cardName
    cardId
    cardRefNum
    cardType
    cardBrand
    category
    invoiceAssociationStatus
    merchantName
    merchantLegalName
    merchantLogoUrl
    transactionCreatedAt
    type
    note
    member {
      membershipId
      firstName
      lastName
      avatarUrl
    }
    documents {
      id
      invoiceNumber {
        value
      }
      amount {
        value
      }
      currency {
        value
      }
      contact {
        value {
          id
          name {
            value
          }
        }
      }
      extractedContact {
        cachingKey
        name {
          value
        }
      }
      status
      attachments {
        name
        id
        url
        size
        attachedBy {
          id
        }
        isDetachable
        originalDocumentId
      }
      documentFile {
        id
        name
        url
        size
      }
    }
    transactionAmount {
      value
      precision
      currency
    }
    billingAmount {
      value
      precision
      currency
    }
    localizedDeclineReason {
      title {
        en
        de
      }
      description {
        en
        de
      }
    }
    invoiceNotNeeded {
      comment
      reason
    }
    isExported
    accountingData {
      contact {
        id
        name {
          value
        }
      }
      contactId
      costCenterId
      costObjectId
      generalLedgerAccountId
      bookingKeyId
      artistSocialInsuranceCode
      extraCostInfoId
      paymentConditionId
      approvalWorkflowTemplateId
      approvers {
        id
        name
        avatarUrl
      }
      approvalWorkflowTemplateId
    }
  }
`;

export const transactionListFragment = gql`
  fragment transactionListData on CardIssuerTransaction {
    id
    status
    cardId
    cardRefNum
    invoiceAssociationStatus
    merchantLegalName
    merchantLogoUrl
    transactionCreatedAt
    localizedDeclineReason {
      description {
        en
        de
      }
    }
    category
    type
    member {
      firstName
      lastName
      avatarUrl
      membershipId
    }
    billingAmount {
      value
      precision
      currency
    }
    transactionAmount {
      value
      precision
      currency
    }
  }
`;

export const purchaseOrderListFragment = gql`
  fragment purchaseOrderListData on PurchaseOrder {
    _id
    orderNumber
    organizationId
    contactName
    accountsPayableNumber
    createdAt
    postingDate
    deliveryDate
    amount {
      __typename
      amount
      currency
      precision
    }
    status
    invoiceAssociationStatus
    purchaseOrderType
    goodsLineItems {
      itemNumber
      taxCode
      quantity
      description
      generalLedgerAccount
      unitPrice {
        __typename
        amount
        currency
        precision
      }
      lineTotal {
        __typename
        amount
        currency
        precision
      }
      discountPercentageValue
    }
    serviceLineItems {
      itemNumber
      description
      generalLedgerAccount
      taxCode
      lineTotal {
        __typename
        amount
        currency
        precision
      }
    }
    connectedInvoices {
      invoiceId
      invoiceNumber
      source
      connectionMethod
    }
    sapB1Data {
      docNumber
      docEntry
      seriesNumber
      cardCode
      cardName
    }
    purchaseOrderBookingAssociations {
      _id
      quantity
      bookingId
      lineNumber
    }
    remainingAmount {
      __typename
      amount
      currency
      precision
    }
    goodsReceipts {
      _id
    }
  }
`;

export const documentBookingAssociationFragment = gql`
  fragment DocumentBookingAssociationData on DocumentBookingAssociation {
    id
    documentId
    bookingId
    articleId
    quantity
    goodsReceiptsInfo {
      goodsReceiptId
      goodsReceiptLineNumber
      goodsReceiptCreationDate
      quantity
      articleId
      linkedPurchaseOrderId
    }
    purchaseOrdersInfo {
      purchaseOrderId
      purchaseOrderLineNumber
      purchaseOrderCreationDate
      quantity
      articleId
      unitPrice {
        __typename
        amount
        currency
        precision
      }
    }
    additionalExpenseInfo {
      expenseType
    }
    unitPrice {
      __typename
      amount
      currency
      precision
    }
  }
`;

export const pageInfoDataFragment = gql`
  fragment PageInfoData on PageInfo {
    startCursor
    hasPreviousPage
    endCursor
    hasNextPage
    totalCount
  }
`;

export const transactionCommentEventFragment = gql`
  fragment TransactionCommentEventData on TransactionCommentEvent {
    createdAt
    clientInfo {
      name
    }
    comment {
      id
      text
      author {
        avatarUrl
      }
    }
    user {
      id
      name
    }
  }
`;

export const transactionStatusUpdatedEventFragment = gql`
  fragment TransactionStatusUpdatedEventData on TransactionStatusUpdatedEvent {
    user {
      id
      name
    }
    createdAt
    properties {
      newStatus
    }
  }
`;

export const transactionReconciledEventFragment = gql`
  fragment TransactionReconciledEventData on TransactionReconciledEvent {
    user {
      id
      name
    }
    createdAt
    properties {
      merchantName
      amount {
        value
        currency
      }
    }
  }
`;

export const transactionAmountChangedEventFragment = gql`
  fragment TransactionAmountChangedEventData on TransactionAmountChangedEvent {
    user {
      id
      name
    }
    createdAt
    properties {
      newAmount {
        value
        currency
      }
    }
  }
`;

export const transactionMarkedAsInvoiceNeededEventFragment = gql`
  fragment TransactionMarkedAsInvoiceNeededEventData on TransactionMarkedAsInvoiceNeededEvent {
    user {
      id
      name
    }
    createdAt
  }
`;

export const transactionMarkedAsInvoiceNotNeededEventFragment = gql`
  fragment TransactionMarkedAsInvoiceNotNeededEventData on TransactionMarkedAsInvoiceNotNeededEvent {
    user {
      id
      name
    }
    properties {
      reason
      reasonComment
    }
    createdAt
  }
`;

export const transactionMarkedAsExportedEventFragment = gql`
  fragment TransactionMarkedAsExportedEventData on TransactionMarkedAsExportedEvent {
    createdAt
    user {
      id
      name
    }
    properties {
      exportId
    }
  }
`;
