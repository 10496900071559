import { ScrollBox } from '@candisio/design-system';
import { CollapsibleCard } from 'components/CollapsibleCard/CollapsibleCard';
import { DocumentStatus } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { ReactNode } from 'react';
import { getThreeWayMatchGeneralState } from 'utils/three-way-match';
import { useThreeWayMatchContext } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchContext';
import { ThreeWayMatchIndicator } from 'views/DocumentDetails/components/ThreeWayMatch/ThreeWayMatchIndicator';
import { AddNewPurchaseOrderButton } from '../PurchaseOrderSection/AddNewPurchaseOrderButton';
import { useGetMatchStateStyles } from './hooks/useGetMatchStateStyles';

interface ThreeWayMatchIndicatorProps {
  children: ReactNode;
}
const isChromium =
  typeof navigator !== 'undefined' &&
  navigator.userAgent.indexOf('Chrome') > -1;

export function ThreeWayMatchWrapper({
  children,
}: ThreeWayMatchIndicatorProps) {
  const {
    newData,
    documentId,
    contactName,
    documentAmount,
    accountsPayableNumber,
    documentStatus,
  } = useThreeWayMatchContext();

  const variant = getThreeWayMatchGeneralState(newData);
  const getMatchingStyles = useGetMatchStateStyles();
  const { leftSectionColor } = getMatchingStyles(variant);
  const { isOnlyApprover } = useUserRoles();

  return (
    <CollapsibleCard
      data-cy="three-way-match-wrapper"
      id="three-way-match-wrapper"
      borderRadius="medium"
      background={leftSectionColor}
      header={<ThreeWayMatchIndicator />}
      headerProps={{
        background: 'transparent',
        border: 'none',
        hoverColor: 'transparent',
      }}
      defaultOpen>
      <ScrollBox
        padding="0"
        borderRadius="medium"
        scrollDirection="y"
        scrollbarGutter={
          isChromium && variant !== 'default' ? 'stable both-edges' : 'stable'
        }
        maxHeight="400px"
        background="gray0"
        style={
          variant !== 'default'
            ? {
                margin: '0 .25rem',
              }
            : {}
        }
        boxShadow="noShadow">
        {children}
      </ScrollBox>
      {variant !== 'default' && (
        <AddNewPurchaseOrderButton
          documentId={documentId ?? ''}
          contactName={contactName}
          documentAmount={documentAmount}
          accountsPayableNumber={accountsPayableNumber}
          disabled={documentStatus !== DocumentStatus.New || isOnlyApprover}
        />
      )}
    </CollapsibleCard>
  );
}
