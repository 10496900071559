import { Grid, ScrollBox, Text } from '@candisio/design-system';
import { ReimbursementItemType } from 'generated-types/graphql.types';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { scrollToTarget } from 'views/CreditCards/utils/utils';
import { ReimbursementError } from 'views/Reimbursement/hooks/useSubmitReimbursementCaseForReview';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/Reimbursement';

const mappedTypesToTranslationKeys: Record<ReimbursementItemType, string> = {
  GENERAL_EXPENSE: 'reimbursementView.leftSection.reimbursementItems.general',
  HOSPITALITY_EXPENSE:
    'reimbursementView.leftSection.reimbursementItems.hospitality',
  MILEAGE: '',
  PER_DIEM: '',
};

interface ErrorSectionProps {
  errors: ReimbursementError[];
}

export const ErrorSection = ({ errors }: ErrorSectionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);

  const { updateSearchParam } = useMutateSearchParams();

  const handleClick = (id: string) => {
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    scrollToTarget(id);
  };

  return (
    <ScrollBox maxHeight="space128">
      <Grid gap="space4">
        {errors.map((item, index) => {
          return (
            <Text
              key={index}
              color="red500"
              fontSize="basic"
              onClick={() => handleClick(item.id)}
              style={{ cursor: 'pointer' }}>
              {t('reimbursementView.rightSection.footerActions.errors', {
                expense: item.title
                  ? item.title
                  : t(mappedTypesToTranslationKeys[item.type]),
                count: item.errors,
              })}
            </Text>
          );
        })}
      </Grid>
    </ScrollBox>
  );
};
