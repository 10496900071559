import { Button, Card, Grid, Text } from '@candisio/design-system';
import { Locale } from 'generated-types/graphql.types';
import { useUserRoles } from 'hooks/useUserRoles';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ApproveNowAction } from './ApproveNowAction';
import { FirstApproverActions } from './FirstApproverActions';
import { RequestApprovalAction } from './RequestApprovalAction';
import {
  MIN_COLUMN_WIDTH,
  MIN_COLUMN_WIDTH_OTHER,
} from './ReviewStatusFooterActionBox';

interface ApprovingStatusFooterActionBoxProps {
  createdById?: string;
  isFirstApprover?: boolean;
}

type FooterMode = 'editMode' | 'cancelEditMode';

const mainKey = 'reimbursementView.rightSection.reviewfooterAction';

export const ApprovingStatusFooterActionBox = ({
  createdById,
  isFirstApprover = false,
}: ApprovingStatusFooterActionBoxProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const [mode, setMode] = useState<FooterMode>('editMode');

  const { id: currentUserId, locale } = useCurrentUser() ?? {};
  const { isAccountant, isAdmin, isOnlyRequester, isOnlyApprover } =
    useUserRoles();

  const isReimbursementOwner = createdById === (currentUserId ?? '');
  const canFastApprove = isAdmin || isAccountant;
  const canRequestApproval = canFastApprove || isOnlyRequester;

  const { setReimbursementUIConfig } = useReimbursementFormsContext();

  const backTextKey = isReimbursementOwner
    ? `${mainKey}.backMain`
    : `${mainKey}.back`;

  const minColumnWidth =
    isReimbursementOwner || locale === Locale.En
      ? MIN_COLUMN_WIDTH
      : MIN_COLUMN_WIDTH_OTHER;

  const handleModeToggle = () => {
    const newMode = mode === 'editMode' ? 'cancelEditMode' : 'editMode';
    setMode(newMode);
    setReimbursementUIConfig(prevConfig => ({
      ...prevConfig,
      isReimbursementFormEditable: newMode === 'cancelEditMode',
    }));
  };

  return (
    <Card corners="top" boxShadow="elevatedShadow3" padding="space20">
      {mode === 'editMode' && (
        <Grid gap="space8">
          <Grid columns={2}>
            <Text
              color="gray500"
              fontWeight="semibold"
              textTransform="uppercase">
              {t('reimbursementView.rightSection.footerActions.title')}
            </Text>
            {!isOnlyApprover && (
              <Button
                justifySelf="end"
                size="small"
                variant="tertiary"
                onClick={handleModeToggle}>
                {t('reimbursementView.rightSection.approvedFooterAction.edit')}
              </Button>
            )}
          </Grid>
          {isFirstApprover && <FirstApproverActions />}
        </Grid>
      )}
      {mode === 'cancelEditMode' && (
        <Grid gap="space8">
          <Grid columns={2}>
            <Text
              color="gray500"
              fontWeight="semibold"
              textTransform="uppercase">
              {t('reimbursementView.rightSection.footerActions.title')}
            </Text>
            <Button
              variant="tertiary"
              justifySelf="end"
              size="small"
              onClick={handleModeToggle}>
              {t(
                'reimbursementView.rightSection.approvedFooterAction.cancelEdit'
              )}
            </Button>
          </Grid>
          <Grid gap="space8">
            {canRequestApproval && <RequestApprovalAction />}
            <Grid
              gap="space8"
              templateColumns={`repeat(auto-fit, minmax(${minColumnWidth}px, 1fr))`}>
              {canFastApprove && <ApproveNowAction />}
              <Button color="blue" variant="secondary">
                {t(backTextKey)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};
