import { Card, Image, Grid, Text } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/Reimbursement';
import recieptIcon from '../../media/receipt.svg';

interface ExpenseDetailsCardProps {
  totalAmount?: string;
  expenseCount: number;
}

export const ExpenseDetailsCard = ({
  totalAmount,
  expenseCount,
}: ExpenseDetailsCardProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.REIMBURSEMENT);
  const { updateSearchParam } = useMutateSearchParams();

  const count = expenseCount || undefined;

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!count) return;
    e.stopPropagation();
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
  };

  return (
    <Card
      padding="space24"
      hover={count ? { background: 'gray100' } : undefined}
      cursor={count ? 'pointer' : 'default'}
      onClick={handleClick}>
      <Grid templateColumns="auto 1fr auto" gap="space24" placeItems="center">
        <Card background="pink100">
          <Image
            src={recieptIcon}
            alt="reciept icon"
            height="space32"
            width="space32"
          />
        </Card>
        <Grid gap="space8">
          <Grid
            autoFlow="column"
            width="fit-content"
            gap="space2"
            fontSize="xlarge"
            fontWeight="semibold">
            <Text>
              {t('reimbursementView.middleSection.emptyDetails.title')}
            </Text>
            {count && <Text>({count})</Text>}
          </Grid>
          <Text fontSize="basic">
            {t('reimbursementView.middleSection.emptyDetails.description')}
          </Text>
        </Grid>
        {count && (
          <Text fontSize="basic" fontWeight="semibold">
            {totalAmount}
          </Text>
        )}
      </Grid>
    </Card>
  );
};
