import {
  Image,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Tag,
  ScrollBox,
} from '@candisio/design-system';
import {
  RouteTypeProps,
  creditCardInfoHash,
} from 'containers/credit-cards/components/CreditCardsSection/utils';
import { LOCALE_NAME_SPACE, i18n } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import tableDe from './media/table-de.png';
import tableEn from './media/table-en.png';

interface CreditCardNotAdoptedImagePlaceholderProps {
  routeType: RouteTypeProps;
}

export const CreditCardsNotAdoptedImagePlaceholder = ({
  routeType,
}: CreditCardNotAdoptedImagePlaceholderProps) => {
  const location = useLocation();
  const [t] = useTranslation(LOCALE_NAME_SPACE.DASHBOARD);
  const navigate = useNavigate();
  const locale = i18n.language;
  const hasBigScreen = useMediaQuery({ minWidth: '1600px' });

  const handleClick = () => {
    navigate({
      pathname: location.pathname,
      hash: `${creditCardInfoHash}`,
    });
  };

  const mappedImageToLocale: Record<string, string> = {
    en: tableEn,
    de: tableDe,
  };

  return (
    <ScrollBox>
      <Grid
        paddingX={routeType !== 'Archive' ? '44px' : 'space12'}
        gap="space32">
        <Flex direction="column" gap="space2">
          <Tag variant="secondary" color="purple">
            {t('common:tag.addOn')}
          </Tag>
          <Heading as="h2" maxWidth="768px">
            {t('ccInformationalCardFFOff.title')}
          </Heading>
        </Flex>
        <Box maxWidth={hasBigScreen ? '1300px' : 'none'}>
          <Image
            alt={t('ccInformationalCardFFOff.altPlaceholder')}
            src={mappedImageToLocale[locale]}
            width="100%"
          />
          <Flex
            position="absolute"
            top="80%"
            width="100%"
            justifyContent="center">
            <Button
              color="purple"
              icon="creditCardOutline"
              onClick={handleClick}>
              {t('ccInformationalCardFFOff.ctaButton')}
            </Button>
          </Flex>
        </Box>
      </Grid>
    </ScrollBox>
  );
};
