import { isNil } from 'lodash';
import { useSap } from 'orgConfig/sap';
import { useEffect } from 'react';
import { DefaultValues, FieldPath, UseFormReturn, get } from 'react-hook-form';
import { useBookingsFormContext } from 'views/DocumentDetails/BookingsFormContext';
import { ProcessingFormValues } from '../processingFormSchema';

type Props = {
  defaultValues?: DefaultValues<ProcessingFormValues>;
  /** Processing form instance */
  form: UseFormReturn<ProcessingFormValues>;
  isReadOnly?: boolean;
};
/**
 *
 * Keeps processing form default values sync with purchase order(PO) linking
 * PO linking dynamically updates the bookings initial data, but react-hook-form does not update the form fields,
 * ... or if the second linking happens, initial data changes as bookings array length increases, but react-hook-form does not populate the new bookings to the split form
 * so we need to manually reset the form to force the form cache clear
 * see rules: https://react-hook-form.com/docs/useform#defaultValues
 *
 */

export const useSyncFormDefaultValuesWithPurchaseOrder = ({
  defaultValues,
  form,
}: Props) => {
  const { shouldUseSapPurchaseOrder } = useSap();

  const { shouldResetForm, setShouldResetForm } = useBookingsFormContext();
  useEffect(() => {
    let shouldReset = false;

    defaultValues?.bookings?.forEach((booking, index) => {
      const fieldName =
        `bookings.${index}.quantity` as FieldPath<ProcessingFormValues>;

      const unitPriceFieldName =
        `bookings.${index}.unitPrice` as FieldPath<ProcessingFormValues>;

      const hasValidBookingData =
        shouldUseSapPurchaseOrder &&
        booking?.quantity &&
        booking.bookingId &&
        get(defaultValues, fieldName);

      if (!hasValidBookingData) return;

      const formDefaultBooking =
        form.formState.defaultValues?.bookings?.find(
          _booking => _booking?.bookingId === booking.bookingId
        ) || form.formState.defaultValues?.bookings?.[index];

      if (!formDefaultBooking) {
        // Form does not populate dynamically added new bookings(bookings array length is increased), so we need to manual reset to force form cache clear
        shouldReset = true;

        return;
      }

      const formDefaultBookingQuantity = formDefaultBooking?.quantity;
      const formDefaultBookingUnitPrice = formDefaultBooking?.unitPrice;

      const defaultBookingQuantity = get(defaultValues, fieldName);
      const defaultBookingUnitPrice = get(defaultValues, unitPriceFieldName);
      const shouldRegisterQuantityField =
        isNil(formDefaultBookingQuantity) ||
        formDefaultBookingQuantity !== booking.quantity;

      const shouldRegisterUnitPriceField =
        isNil(formDefaultBookingUnitPrice) ||
        formDefaultBookingUnitPrice !== booking.unitPrice;

      if (shouldRegisterQuantityField) {
        form.register(fieldName);
        form.resetField(fieldName, { defaultValue: defaultBookingQuantity });
      }

      if (shouldRegisterUnitPriceField) {
        form.register(unitPriceFieldName);
        form.resetField(unitPriceFieldName, {
          defaultValue: defaultBookingUnitPrice,
        });
      }
    });

    // mutation onCompleted sets shouldResetForm to true, so we double check the new added booking is not there is only due to PO linking
    if (shouldReset && shouldResetForm) {
      form.reset(defaultValues, {
        keepDirtyValues: true,
        keepErrors: true,
      });
      setShouldResetForm?.(false); // reset the flag
    }
  }, [
    defaultValues,
    form,
    setShouldResetForm,
    shouldResetForm,
    shouldUseSapPurchaseOrder,
  ]);
};
