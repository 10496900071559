import { Grid } from '@candisio/design-system';
import { UserRoleAccessWrapper } from 'components/AccessWrapper/UserRoleAccessWrapper';
import { CreateCreditCardModal } from 'containers/credit-cards/CreateCreditCard/CreateCreditCardModal';
import { UserRole } from 'generated-types/graphql.types';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { Routes } from 'models';
import { CreditCardsProvider } from 'providers/CreditCardsProvider/CreditCardsProvider';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useOrganizationId } from 'providers/OrganizationProvider';
// eslint-disable-next-line no-restricted-imports
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { CreditCardsHeader } from '../CreditCardsHeader';
import { useCreditCardLimits } from '../CreditCardsInsights/FinancialOverview/hooks/useCreditCardLimits';
import { OrganizationSettingsDrawer } from '../CreditCardsInsights/OrganizationSettingsDrawer/OrganizationSettingsDrawer';
import {
  CreditCardsTab,
  CreditCardsTabs,
} from '../CreditCardsTabs/CreditCardsTabs';
import { PendingSettlements } from './PendingSettlements/PendingSettlements';
import { StatementDetailsDrawer } from './StatementDetailsDrawer/StatementDetailsDrawer';
import { StatementsListContainer } from './StatementsList/StatementsListContainer';
import { UpcomingSettlement } from './UpcomingSettlement/UpcomingSettlement';

interface CreditCardsStatementsParams {
  statementId: string;
}

export const CreditCardsStatements = () => {
  const { statementId } = useParams<CreditCardsStatementsParams>();
  const navigate = useNavigate();
  const organizationSlug = useOrganizationId();
  const { data } = useCreditCardLimits();
  const isPrefundOrg = data?.total === 0;

  const creditCardsStatementsFF = useCandisFeatureFlags(
    FEATURE_FLAGS.creditCardsStatements
  );

  const handleClose = () => {
    navigate({
      pathname: `/${organizationSlug}${Routes.CREDIT_CARDS_DASHBOARD}${Routes.STATEMENTS}`,
    });
  };

  return (
    <Grid
      overflowY="hidden"
      templateRows="auto 1fr"
      padding="space32"
      gap="space24"
      paddingTop="space40"
      height="100%">
      <Grid gap="space16">
        <CreditCardsHeader selectedTab={CreditCardsTab.Statements} />
        {creditCardsStatementsFF ? (
          <CreditCardsTabs defaultSelectedKey={CreditCardsTab.Statements} />
        ) : (
          <UserRoleAccessWrapper
            allowedRoles={[UserRole.CardManager]}
            mode="hidden">
            <CreditCardsTabs defaultSelectedKey={CreditCardsTab.Statements} />
          </UserRoleAccessWrapper>
        )}
      </Grid>
      <Grid templateColumns="3fr 1fr" gap="space16">
        <Grid>
          <StatementsListContainer />
        </Grid>
        {!isPrefundOrg && (
          <Grid gap="space16" templateRows="auto 1fr">
            <UpcomingSettlement />
            <PendingSettlements />
          </Grid>
        )}
      </Grid>
      <StatementDetailsDrawer onClose={handleClose} />
      <OrganizationSettingsDrawer />
      {statementId === 'request' ? (
        <CreditCardsProvider>
          <CreateCreditCardModal
            isOpen={statementId === 'request'}
            onClose={handleClose}
          />
        </CreditCardsProvider>
      ) : undefined}
    </Grid>
  );
};
