import { Box } from '@candisio/design-system';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import DocumentPreviewUpsell from './DocumentPreviewUpsell';

interface DocumentPreviewUpsellDrawerProps {
  closePreviewDrawer: () => void;
  documentId: string | null;
}

export default function DocumentPreviewUpsellDrawer({
  documentId,
  closePreviewDrawer,
}: DocumentPreviewUpsellDrawerProps) {
  const [animationFinished, setAnimationFinished] = useState(false);

  useEffect(() => {
    return () => {
      setAnimationFinished(false);
    };
  }, []);

  if (!documentId) {
    return null;
  }

  return (
    <MotionBox
      onAnimationStart={() => {
        setAnimationFinished(false);
      }}
      onAnimationComplete={() => {
        setAnimationFinished(true);
      }}
      key="documentPreviewUpsellWrapper"
      id="documentPreviewUpsell"
      initial="closed"
      animate="open"
      exit="closed"
      variants={{
        open: { width: `${464}px` },
        closed: { width: 0 },
      }}
      transition={{ ease: 'easeOut' }}
      height="100%"
      minHeight="0">
      <DocumentPreviewUpsell
        onClose={closePreviewDrawer}
        showContent={animationFinished}
      />
    </MotionBox>
  );
}

const MotionBox = motion(Box);
