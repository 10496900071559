import { Box, Grid, ScrollBox } from '@candisio/design-system';
import { useStickyCardRefContext } from 'providers/StickyCardRefProvider';
import { ReactNode } from 'react';
import {
  ProcessSidebarHeader,
  ProcessSidebarHeaderProps,
} from './Header/ProcessSidebarHeader';

interface ProcessSidebarProps {
  actions?: ReactNode;
  children: ReactNode;
  documentId?: string;
  globalDocumentId?: string;
  id?: string;
  switcher?: ReactNode;
  /**
   * Sets a custom header for the sidebar.
   *
   * Please make sure that using `documentStatus` may not be better approach.
   * */
  customHeader?: ProcessSidebarHeaderProps['customHeader'];
  /** Sets the title based on the document status */
  documentStatus?: ProcessSidebarHeaderProps['documentStatus'];
}

export const ProcessSidebar = ({
  actions,
  children,
  documentId,
  globalDocumentId,
  id,
  switcher,
  customHeader,
  documentStatus,
}: ProcessSidebarProps) => {
  const cardRef = useStickyCardRefContext();

  return (
    <ScrollBox
      scrollDirection="y"
      scrollbarGutter="stable"
      height="100%"
      id={id}
      paddingTop="space32"
      paddingLeft="space8"
      paddingRight="space3"
      position="static">
      <Grid
        gap="space8"
        templateRows="auto 1fr"
        height="100%"
        position="static">
        <Grid gap={!!switcher ? 'space8' : 'space16'} paddingX="space8">
          <ProcessSidebarHeader
            documentId={documentId}
            customHeader={customHeader}
            documentStatus={documentStatus}
            globalDocumentId={globalDocumentId}
          />
          {switcher && <Box>{switcher}</Box>}
        </Grid>

        <Box position="static">{children}</Box>

        {actions && (
          <Box
            alignSelf="end"
            bottom="0"
            position="sticky"
            zIndex="2"
            ref={cardRef}>
            {actions}
          </Box>
        )}
      </Grid>
    </ScrollBox>
  );
};
