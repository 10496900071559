import {
  Box,
  Button,
  Grid,
  Popover,
  Text,
  usePopover,
} from '@candisio/design-system';
import { SplitDS } from 'components/Form/SplitBookingsForm/types';
import {
  PurchaseOrderType,
  SapExpenseType,
} from 'generated-types/graphql.types';
import { LOCALE_NAME_SPACE } from 'providers/LocaleProvider';
import { useTranslation } from 'react-i18next';

type PackageFreightNote = 'Freight' | 'Packaging';

export type SapFreightPackageBookingFields = {
  note: PackageFreightNote;
  sapExpenseType: SapExpenseType;
};
const expenseTypeButtonOptions = [
  {
    key: 'freight',
    note: 'Freight' as PackageFreightNote,
    sapExpenseType: SapExpenseType.Freight,
  },
  {
    key: 'packaging',
    note: 'Packaging' as PackageFreightNote,
    sapExpenseType: SapExpenseType.Packaging,
  },
];

interface AddNewSplitActionProps {
  onClick: (
    sapFreightPackage: SapFreightPackageBookingFields | undefined
  ) => void;
  purchaseOrderType: PurchaseOrderType;
  currentBookings?: SplitDS[];
}

export const NewSplitForPurchaseOrders = ({
  onClick,
  purchaseOrderType,
  currentBookings,
}: AddNewSplitActionProps) => {
  const [t] = useTranslation(LOCALE_NAME_SPACE.SPLIT_BOOKINGS);

  const { isOpen, popoverProps, popoverRef, triggerProps, triggerRef, close } =
    usePopover({
      placement: 'top',
    });

  if (purchaseOrderType === PurchaseOrderType.Service) return null;

  return (
    <Box>
      <Button
        icon="plus"
        width="100%"
        variant="secondary"
        {...triggerProps}
        ref={triggerRef}>
        {t('actions.addNewSplitButton')}
      </Button>
      {isOpen && (
        <Popover
          {...popoverProps}
          ref={popoverRef}
          width="275px"
          padding="space16"
          paddingTop="space12"
          title={t('popoverTitle')}>
          <Grid gap="space16">
            <Grid templateColumns="1fr auto">
              <Text>{t('popoverTitle')}</Text>
              <Button
                onClick={close}
                icon="close"
                size="xsmall"
                variant="tertiary"
              />
            </Grid>
            <Grid gap="space8">
              {expenseTypeButtonOptions.map(({ key, note, sapExpenseType }) => (
                <Button
                  key={key}
                  variant="secondary"
                  onClick={() => {
                    onClick({ note, sapExpenseType });
                    close();
                  }}
                  disabled={currentBookings?.some(
                    booking => booking.sapExpenseType === sapExpenseType
                  )}>
                  {t(key)}
                </Button>
              ))}
            </Grid>
          </Grid>
        </Popover>
      )}
    </Box>
  );
};
