import { mergeRefs, usePopover } from '@candisio/design-system';
import { useLocalStorage } from 'hooks/LocalStorage/useLocalStorage';
import { useCandisFeatureFlags } from 'hooks/useCandisFeatureFlags';
import { useIsElementVisible } from 'hooks/useIsElementVisible';
import { FEATURE_FLAGS } from 'providers/FeatureFlagProvider';
import { useEffect, useState } from 'react';
import { prefix } from 'utils/localStorage';

const ACTIVATION_LOCAL_STORAGE_POSTFIX = 'manage-relationships-activation-seen';

export const markDocumentRelationsActivationSeen = () => {
  localStorage.setItem(`${prefix}-${ACTIVATION_LOCAL_STORAGE_POSTFIX}`, 'true');
};

export const useManageRelationshipsActivationPopover = () => {
  const [manageDocumentRelationsFF] = useCandisFeatureFlags([
    FEATURE_FLAGS.manageDocumentRelations,
  ]);

  const [activationSeen, setActivationSeen] = useLocalStorage(
    ACTIVATION_LOCAL_STORAGE_POSTFIX,
    false
  );

  const [delayPassed, setDelayPassed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayPassed(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const { ref, isIntersecting: isTriggerVisible } = useIsElementVisible();
  const shouldOpenActivationPopover =
    manageDocumentRelationsFF &&
    !activationSeen &&
    isTriggerVisible &&
    delayPassed;

  const {
    isOpen,
    popoverProps,
    popoverRef,
    triggerProps: _triggerProps,
    triggerRef,
    close,
  } = usePopover({
    placement: 'right',
    updatePlacementOnTriggerMovement: true,
    isOpen: shouldOpenActivationPopover,
  });

  // onPointerDown and onClick from trigger interfere with current props
  const { onPointerDown, onClick, ...triggerProps } = _triggerProps;

  const headerProps = manageDocumentRelationsFF ? triggerProps : {};

  const headerRef = manageDocumentRelationsFF
    ? mergeRefs([triggerRef, ref])
    : undefined;

  const handleClose = () => {
    close();
    setActivationSeen(true);
    markDocumentRelationsActivationSeen();
  };

  return {
    headerProps,
    headerRef,
    isOpen,
    popoverProps,
    popoverRef,
    close: handleClose,
  };
};
