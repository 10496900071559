import { Entitlements } from 'hooks/useCandisFeatureFlags';
import {
  FEATURE,
  FEATURE_STATE,
  FeatureToggle,
} from 'providers/FeatureToggleProvider/types';

export const entitlementsToFeatureMap: Record<Entitlements, FeatureToggle> = {
  [Entitlements.LEGACY]: {
    [FEATURE.ABSENCE_MANAGEMENT]: FEATURE_STATE.VISIBLE,
    [FEATURE.BDS]: FEATURE_STATE.VISIBLE,
    [FEATURE.CONTRACTS]: FEATURE_STATE.VISIBLE,
    [FEATURE.DOCUMENT_RELATIONS]: FEATURE_STATE.PROMO,
    [FEATURE.FULL_TEXT_SEARCH]: FEATURE_STATE.VISIBLE,
    [FEATURE.INTEGRATION_SAP]: FEATURE_STATE.VISIBLE,
    [FEATURE.POSTING_TEXT]: FEATURE_STATE.VISIBLE,
    [FEATURE.PROVISIONS]: FEATURE_STATE.VISIBLE,
    [FEATURE.PURCHASE_ORDERS]: FEATURE_STATE.VISIBLE,
    [FEATURE.SEPA_XML]: FEATURE_STATE.VISIBLE,
    [FEATURE.ECM]: FEATURE_STATE.PROMO,
    [FEATURE.REIMBURSEMENT]: FEATURE_STATE.HIDDEN,
    [FEATURE.DOCUMENT_PREVIEW]: FEATURE_STATE.PROMO,
  },
  [Entitlements.BASE]: {
    [FEATURE.ABSENCE_MANAGEMENT]: FEATURE_STATE.PROMO,
    [FEATURE.BDS]: FEATURE_STATE.PROMO,
    [FEATURE.CONTRACTS]: FEATURE_STATE.PROMO,
    [FEATURE.DOCUMENT_RELATIONS]: FEATURE_STATE.PROMO,
    [FEATURE.FULL_TEXT_SEARCH]: FEATURE_STATE.PROMO,
    [FEATURE.INTEGRATION_SAP]: FEATURE_STATE.PROMO,
    [FEATURE.POSTING_TEXT]: FEATURE_STATE.PROMO,
    [FEATURE.PROVISIONS]: FEATURE_STATE.PROMO,
    [FEATURE.PURCHASE_ORDERS]: FEATURE_STATE.PROMO,
    [FEATURE.SEPA_XML]: FEATURE_STATE.PROMO,
    [FEATURE.ECM]: FEATURE_STATE.VISIBLE,
    [FEATURE.REIMBURSEMENT]: FEATURE_STATE.VISIBLE,
    [FEATURE.DOCUMENT_PREVIEW]: FEATURE_STATE.VISIBLE,
  },
  [Entitlements.PLUS]: {
    [FEATURE.ABSENCE_MANAGEMENT]: FEATURE_STATE.VISIBLE,
    [FEATURE.BDS]: FEATURE_STATE.VISIBLE,
    [FEATURE.CONTRACTS]: FEATURE_STATE.VISIBLE,
    [FEATURE.DOCUMENT_RELATIONS]: FEATURE_STATE.VISIBLE,
    [FEATURE.FULL_TEXT_SEARCH]: FEATURE_STATE.VISIBLE,
    [FEATURE.INTEGRATION_SAP]: FEATURE_STATE.PROMO,
    [FEATURE.POSTING_TEXT]: FEATURE_STATE.VISIBLE,
    [FEATURE.PROVISIONS]: FEATURE_STATE.VISIBLE,
    [FEATURE.PURCHASE_ORDERS]: FEATURE_STATE.PROMO,
    [FEATURE.SEPA_XML]: FEATURE_STATE.VISIBLE,
    [FEATURE.ECM]: FEATURE_STATE.VISIBLE,
    [FEATURE.REIMBURSEMENT]: FEATURE_STATE.VISIBLE,
    [FEATURE.DOCUMENT_PREVIEW]: FEATURE_STATE.VISIBLE,
  },
  [Entitlements.MAX]: {
    [FEATURE.ABSENCE_MANAGEMENT]: FEATURE_STATE.VISIBLE,
    [FEATURE.BDS]: FEATURE_STATE.VISIBLE,
    [FEATURE.CONTRACTS]: FEATURE_STATE.VISIBLE,
    [FEATURE.DOCUMENT_RELATIONS]: FEATURE_STATE.VISIBLE,
    [FEATURE.FULL_TEXT_SEARCH]: FEATURE_STATE.VISIBLE,
    [FEATURE.INTEGRATION_SAP]: FEATURE_STATE.VISIBLE,
    [FEATURE.POSTING_TEXT]: FEATURE_STATE.VISIBLE,
    [FEATURE.PROVISIONS]: FEATURE_STATE.VISIBLE,
    [FEATURE.PURCHASE_ORDERS]: FEATURE_STATE.VISIBLE,
    [FEATURE.SEPA_XML]: FEATURE_STATE.VISIBLE,
    [FEATURE.ECM]: FEATURE_STATE.VISIBLE,
    [FEATURE.REIMBURSEMENT]: FEATURE_STATE.VISIBLE,
    [FEATURE.DOCUMENT_PREVIEW]: FEATURE_STATE.VISIBLE,
  },
};
