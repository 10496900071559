import { Button } from '@candisio/design-system';
import { DocumentAccessDrawer } from 'components/DocumentAccess/DocumentAccessDrawer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DOCUMENT_ACCESS_BUTTON_TEST_ID = 'document-access-button';

export interface DocumentAccessButtonProps {
  globalDocumentId: string;
}
export const DocumentAccessButton = ({
  globalDocumentId,
}: DocumentAccessButtonProps) => {
  const [isDocumentAccessDrawerOpen, setIsDocumentAccessDrawerOpen] =
    useState(false);

  const [t] = useTranslation();

  return (
    <>
      <Button
        icon="lock"
        variant="tertiary"
        size="small"
        height="space36"
        width="space36"
        label={t('common:documentAccess.button.label')}
        data-testid={DOCUMENT_ACCESS_BUTTON_TEST_ID}
        onClick={() => setIsDocumentAccessDrawerOpen(true)}
      />
      <DocumentAccessDrawer
        isOpen={isDocumentAccessDrawerOpen}
        onCloseDrawer={() => setIsDocumentAccessDrawerOpen(false)}
        globalDocumentId={globalDocumentId}
      />
    </>
  );
};
