import { Box, Card, Grid, ScrollBox, Separator } from '@candisio/design-system';
import { useMutateSearchParams } from 'hooks/useMutateSearchParams';
import { scrollToTarget } from 'views/CreditCards/utils/utils';
import { useReimbursementFormsContext } from 'views/Reimbursement/context/ReimbursementFormsContext';
import { ReimbursementItemListLeftSection } from 'views/Reimbursement/hooks/useReimbursementExpenseItemsList';
import {
  REIMBURSEMENT_URL_PARAM,
  VIEW_PARAM_VALUE,
} from 'views/Reimbursement/Reimbursement';
import { AddReimbursementItemActionMenuButton } from './AddReimbursementItemActionMenuButton';
import { ReimbursementItem } from './ReimbursementItem';

interface ReimbursementItemsCardProps {
  creatingReimbursementItem: boolean;
  items: ReimbursementItemListLeftSection[];
  onHospitalityExpenseClick: () => void;
  onGeneralExpenseClick: () => void;
}

export const ReimbursementItemsCard = ({
  items,
  creatingReimbursementItem,
  onGeneralExpenseClick,
  onHospitalityExpenseClick,
}: ReimbursementItemsCardProps) => {
  const { updateSearchParam } = useMutateSearchParams();
  const { reimbursementUIConfig } = useReimbursementFormsContext();
  const { canManageReimbursementItems } = reimbursementUIConfig;

  const handleClick = (id: string) => () => {
    updateSearchParam(REIMBURSEMENT_URL_PARAM.VIEW, VIEW_PARAM_VALUE);
    scrollToTarget(id);
  };

  return (
    <Grid overflowY="auto" borderRadius="medium">
      <ScrollBox scrollDirection="y" scrollbarGutter="stable" maxHeight="250px">
        <Box as="ul" listStyle="none" padding={0}>
          {items.map((item, index) => {
            const isLastItem = index === items.length - 1;
            const isFirstItem = index === 0;

            return (
              <Box as="li" listStyle="none" key={item.id}>
                <ReimbursementItem
                  reimbursementItem={item}
                  onClick={handleClick(item.id)}
                  isRoundedBorder={isFirstItem}
                />
                {!isLastItem && <Separator background="gray250" />}
              </Box>
            );
          })}
        </Box>
      </ScrollBox>
      {canManageReimbursementItems && (
        <Card
          background="gray50"
          padding={0}
          borderTopRadius="none"
          style={{ marginRight: '8px' }}>
          <Separator color="gray250" />
          <AddReimbursementItemActionMenuButton
            creatingReimbursementItem={creatingReimbursementItem}
            onGeneralExpenseClick={onGeneralExpenseClick}
            onHospitalityExpenseClick={onHospitalityExpenseClick}
          />
        </Card>
      )}
    </Grid>
  );
};
